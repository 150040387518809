import {Action, handleActions, ReduxCompatibleReducer} from 'redux-actions';
import {GlobalniKontaktyActionType, PracovniciDistribuceActionType, SkupinoveKontaktyActionType, SpravakontaktuDistributoraActionType} from '../actions';
import {KontaktDistributoraSkupinaFilterModel, PracovniciDistribuceFilterModel, SearchKontaktuDistributoraFilterModel} from '../model';
import {SpravaKontaktuDistributoraStore} from '../store';

export const PREFIX = 'SPRAVA_KONTAKTU_DISTRIBUTORA_';

/**
 * Výchozí stav reduceru
 */
export const initialData: SpravaKontaktuDistributoraStore = {
    activeTab: 0,
    globalni: {
        filter: {
            typKontaktu: 'vse',
            typSkupiny: 'vse',
        },
    },
    skupinove: {
        filter: {
            pracovnik: null,
            skupina: null,
            typ: 'vse',
        },
    },
    pracovniciDistribuce: {
        filter: {
            fulltext: '',
        },
    },
};

/**
 * Globální reducer pro Správu kontaktů distributora
 */
export const SpravaKontaktuDistributoraReducer: ReduxCompatibleReducer<SpravaKontaktuDistributoraStore, any> = handleActions<
    SpravaKontaktuDistributoraStore,
    any
>(
    {
        [SpravakontaktuDistributoraActionType.SET_TAB]: (
            store: SpravaKontaktuDistributoraStore,
            {payload}: Action<number>,
        ): SpravaKontaktuDistributoraStore => ({
            ...store,
            activeTab: payload,
        }),
        // reducers pro globalni kontakty
        [GlobalniKontaktyActionType.SET_FILTER]: (
            store: SpravaKontaktuDistributoraStore,
            {payload}: Action<SearchKontaktuDistributoraFilterModel>,
        ): SpravaKontaktuDistributoraStore => ({
            ...store,
            globalni: {filter: payload},
        }),
        [GlobalniKontaktyActionType.CLEAR_FILTER]: (store: SpravaKontaktuDistributoraStore): SpravaKontaktuDistributoraStore => ({
            ...store,
            globalni: {filter: initialData.globalni.filter},
        }),

        // reducers pro skupinove kontakty
        [SkupinoveKontaktyActionType.SET_FILTER]: (
            store: SpravaKontaktuDistributoraStore,
            {payload}: Action<KontaktDistributoraSkupinaFilterModel>,
        ): SpravaKontaktuDistributoraStore => ({
            ...store,
            skupinove: {filter: payload},
        }),
        [SkupinoveKontaktyActionType.CLEAR_FILTER]: (store: SpravaKontaktuDistributoraStore): SpravaKontaktuDistributoraStore => ({
            ...store,
            skupinove: {filter: initialData.skupinove.filter},
        }),

        // reducers pro pracovníky distribuce
        [PracovniciDistribuceActionType.SET_FILTER]: (
            store: SpravaKontaktuDistributoraStore,
            {payload}: Action<PracovniciDistribuceFilterModel>,
        ): SpravaKontaktuDistributoraStore => ({
            ...store,
            pracovniciDistribuce: {filter: payload},
        }),
        [PracovniciDistribuceActionType.CLEAR_FILTER]: (store: SpravaKontaktuDistributoraStore): SpravaKontaktuDistributoraStore => ({
            ...store,
            pracovniciDistribuce: {filter: initialData.pracovniciDistribuce.filter},
        }),
    },
    initialData,
);
