import {Action, handleActions, ReduxCompatibleReducer} from 'redux-actions';
import {RoleOpravneniActionType} from '../actions';
import {RoleOpravneniFilterModel} from '../model';
import {RoleOpravneniStore} from '../store';

/**
 * Výchozí stav reduceru
 */
export const initialData: RoleOpravneniStore = {
    activeTab: 0,
    filter: {
        nazevRoleFulltext: undefined,
    },
    aktivni: true,
};

/**
 * Globální reducer pro role a oprávnění
 */
export const RoleOpravneniReducer: ReduxCompatibleReducer<RoleOpravneniStore, any> = handleActions<RoleOpravneniStore, any>(
    {
        [RoleOpravneniActionType.SET_TAB]: (store: RoleOpravneniStore, {payload}: Action<number>): RoleOpravneniStore => ({
            ...store,
            activeTab: payload,
        }),
        [RoleOpravneniActionType.SET_FILTER]: (
            store: RoleOpravneniStore,
            {payload}: {payload: {filter: RoleOpravneniFilterModel; aktivni: boolean}},
        ): RoleOpravneniStore => ({
            ...store,
            filter: payload.filter,
            aktivni: payload.aktivni,
        }),
        [RoleOpravneniActionType.CLEAR_FILTER]: (store: RoleOpravneniStore, {payload}: Action<boolean>): RoleOpravneniStore => ({
            ...store,
            filter: initialData.filter,
            aktivni: payload,
        }),
    },
    initialData,
);
