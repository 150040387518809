// This file is generated from scripts/default-lang.ts, don't modify, run npm run generate:lang
export const Lang = {
    USER: 'user',
    PORTAL: 'portal',
    KONTROLUJI: 'kontroluji',
    UCET_SKUPINA_TYP_OBCHODNIK_DESCRIPTION: 'ucet.skupina.typ.OBCHODNIK.description',
    UCET_SKUPINA_TYP_ZAKAZNIK_SE_SMLOUVOU_NAPRIMO_DESCRIPTION: 'ucet.skupina.typ.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO.description',
    UCET_SKUPINA_TYP_MUNICIPALITA_DESCRIPTION: 'ucet.skupina.typ.MUNICIPALITA.description',
    UCET_SKUPINA_TYP_ZADATEL_DESCRIPTION: 'ucet.skupina.typ.ZADATEL.description',
    UCET_SKUPINA_TYP_KONCOVY_ZAKAZNIK_DESCRIPTION: 'ucet.skupina.typ.KONCOVY_ZAKAZNIK.description',
    UCET_SKUPINA_TYP_PRODUCENT_DESCRIPTION: 'ucet.skupina.typ.PRODUCENT.description',
    UCET_SKUPINA_TYP_DODAVATEL_DESCRIPTION: 'ucet.skupina.typ.DODAVATEL.description',
    UCET_SKUPINA_TYP_VSE: 'ucet.skupina.typ.VSE',
    UCET_SKUPINA_TYP_OBCHODNIK_ZA_ZAKAZNIKA: 'ucet.skupina.typ.OBCHODNIK_ZA_ZAKAZNIKA',
    UCET_SKUPINA_TYP_INDIVIDUALNI_SMLOUVA: 'ucet.skupina.typ.INDIVIDUALNI_SMLOUVA',
    UCET_SKUPINA_TYP_ZAKAZNIK: 'ucet.skupina.typ.ZAKAZNIK',
    UCET_SKUPINA_TYP_OBCHODNIK_DALKOVE_ODECTY: 'ucet.skupina.typ.OBCHODNIK_DALKOVE_ODECTY',
    PROFILE_TYPE_DODAVKA: 'profile.type.DODAVKA',
    PROFILE_TYPE_SPOTREBA: 'profile.type.SPOTREBA',
    ERROR_REQUIRED: 'error.required',
    ERROR_INVALID_EMAIL: 'error.invalid.email',
    ERROR_MIN_LENGTH: 'error.min.length',
    ERROR_PASSWORDS_DONT_MATCH: 'error.passwords.dont.match',
    ERROR_EMAIL_ALREADY_USED: 'error.email.already.used',
    ERROR_PASSWORD_FORMAT: 'error.password.format',
    TYP_SUBJEKTU_FYZICKA_OSOBA: 'typ.subjektu.FYZICKA_OSOBA',
    TYP_SUBJEKTU_PRAVNICKA_OSOBA: 'typ.subjektu.PRAVNICKA_OSOBA',
    KONTAKTDRUHKOMUNIKACE_INT: 'kontaktDruhKomunikace.INT',
    KONTAKTDRUHKOMUNIKACE_MOB: 'kontaktDruhKomunikace.MOB',
    KONTAKTDRUHKOMUNIKACE_DAT: 'kontaktDruhKomunikace.DAT',
    KONTAKTDRUHKOMUNIKACE_LTR: 'kontaktDruhKomunikace.LTR',
    KONTAKT_CHIP_EAN: 'kontakt.chip.ean',
    KONTAKT_CHIP_EIC: 'kontakt.chip.eic',
    EAN_ICO: 'ean.ico',
    ACCOUNTGROUP_LIST_CISLO: 'accountGroup.list.cislo',
    ACCOUNTGROUP_LIST_EAN_OBCHODNIKA: 'accountGroup.list.ean.obchodnika',
    ACCOUNTGROUP_LIST_EIC_OBCHODNIKA: 'accountGroup.list.eic.obchodnika',
    ACCOUNTGROUP_LIST_KOMODITA: 'accountGroup.list.komodita',
    ACCOUNTGROUP_LIST_CREATED_1: 'accountGroup.list.created.1',
    ACCOUNTGROUP_LIST_CREATED_2: 'accountGroup.list.created.2',
    ACCOUNTGROUP_LIST_UPDATED_1: 'accountGroup.list.updated.1',
    ACCOUNTGROUP_LIST_UPDATED_2: 'accountGroup.list.updated.2',
    ACCOUNTGROUP_LIST_DEACTIVATED: 'accountGroup.list.deactivated',
    ACCOUNTGROUP_LIST_PRISTUPY_DEACTIVATED: 'accountGroup.list.pristupy.deactivated',
    ACCOUNTGROUP_LIST_DEACTIVATE_CONFIRM: 'accountGroup.list.deactivate.confirm',
    ACCOUNTGROUP_BUTTON_PRIDATUCET: 'accountGroup.button.pridatUcet',
    ACCOUNTGROUP_BUTTON_PRIDATPRISTUP: 'accountGroup.button.pridatPristup',
    ACCOUNTGROUP_BUTTON_AKTUALIZOVATDATA_DWH: 'accountGroup.button.aktualizovatData.dwh',
    ACCOUNTGROUP_BUTTON_AKTUALIZOVATDATA_SAP: 'accountGroup.button.aktualizovatData.sap',
    ACCOUNTGROUP_DETAIL_REFRESHED_DWH: 'accountGroup.detail.refreshed.dwh',
    ACCOUNTGROUP_DETAIL_REFRESHED_SAP: 'accountGroup.detail.refreshed.sap',
    ACCOUNTGROUP_DETAIL_DEACTIVATED: 'accountGroup.detail.deactivated',
    ACCOUNTGROUP_DETAIL_REACTIVATED: 'accountGroup.detail.reactivated',
    ACCOUNTGROUP_DETAIL_SAVED: 'accountGroup.detail.saved',
    ACCOUNTGROUP_DETAIL_ACCOUNT_CREATED: 'accountGroup.detail.account.created',
    ACCOUNTGROUP_DETAIL_TAB_PROFILE: 'accountGroup.detail.tab.profile',
    ACCOUNTGROUP_DETAIL_TAB_ACCOUNTS: 'accountGroup.detail.tab.accounts',
    ACCOUNTGROUP_DETAIL_TAB_CONTACTS: 'accountGroup.detail.tab.contacts',
    ACCOUNTGROUP_DETAIL_TAB_KONTAKTY_DISTRIBUTORA: 'accountGroup.detail.tab.kontakty.distributora',
    ACCOUNTGROUP_DETAIL_TAB_PRISTUPY_ENERGETICI: 'accountGroup.detail.tab.pristupy.energetici',
    ACCOUNTGROUP_DETAIL_TAB_NOTIFIKACE: 'accountGroup.detail.tab.notifikace',
    ACCOUNTGROUP_DETAIL_DEACTIVATE_CONFIRM: 'accountGroup.detail.deactivate.confirm',
    ACCOUNTGROUP_DETAIL_DEACTIVATE_CONFIRM_PRISTUPY: 'accountGroup.detail.deactivate.confirm.pristupy',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_TITLE: 'accountGroup.detail.accounts.title',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_ONLY_ADMINS: 'accountGroup.detail.accounts.only.admins',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_EMAIL: 'accountGroup.detail.accounts.email',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_JMENO_PRIJMENI: 'accountGroup.detail.accounts.jmeno.prijmeni',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_TELEFON: 'accountGroup.detail.accounts.telefon',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_AKTIVNI: 'accountGroup.detail.accounts.aktivni',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_ADMIN: 'accountGroup.detail.accounts.admin',
    ACCOUNTGROUP_DETAIL_ACCOUNTS_DETAIL: 'accountGroup.detail.accounts.detail',
    ACCOUNTGROUP_DETAIL_PROFILE_DETAIL: 'accountGroup.detail.profile.detail',
    ACCOUNTGROUP_DETAIL_PROFILE_NAZEV: 'accountGroup.detail.profile.nazev',
    ACCOUNTGROUP_DETAIL_PROFILE_PARTNER: 'accountGroup.detail.profile.partner',
    ACCOUNTGROUP_DETAIL_PROFILE_TYP: 'accountGroup.detail.profile.typ',
    ACCOUNTGROUP_DETAIL_PROFILE_IC: 'accountGroup.detail.profile.ic',
    ACCOUNTGROUP_DETAIL_PROFILE_DIC: 'accountGroup.detail.profile.dic',
    ACCOUNTGROUP_DETAIL_PROFILE_AKTIVNI: 'accountGroup.detail.profile.aktivni',
    ACCOUNTGROUP_DETAIL_PROFILE_PRIHLASENICERTIFIKATEM: 'accountGroup.detail.profile.prihlaseniCertifikatem',
    ACCOUNTGROUP_DETAIL_PROFILE_DATOVA_SCHRANKA: 'accountGroup.detail.profile.datova.schranka',
    ACCOUNTGROUP_DETAIL_PROFILE_ELEKTRINA: 'accountGroup.detail.profile.elektrina',
    ACCOUNTGROUP_DETAIL_PROFILE_SMLOUVAELEKTRINA: 'accountGroup.detail.profile.smlouvaElektrina',
    ACCOUNTGROUP_DETAIL_PROFILE_EAN: 'accountGroup.detail.profile.ean',
    ACCOUNTGROUP_DETAIL_PROFILE_POSKYTOVATELELEKTRINA: 'accountGroup.detail.profile.poskytovatelElektrina',
    ACCOUNTGROUP_DETAIL_PROFILE_PLYN: 'accountGroup.detail.profile.plyn',
    ACCOUNTGROUP_DETAIL_PROFILE_SMLOUVAPLYN: 'accountGroup.detail.profile.smlouvaPlyn',
    ACCOUNTGROUP_DETAIL_PROFILE_EIC: 'accountGroup.detail.profile.eic',
    ACCOUNTGROUP_DETAIL_PROFILE_POSKYTOVATELPLYN: 'accountGroup.detail.profile.poskytovatelPlyn',
    ACCOUNTGROUP_DETAIL_PROFILE_LIDE: 'accountGroup.detail.profile.lide',
    ACCOUNTGROUP_DETAIL_PROFILE_VYTVORIL: 'accountGroup.detail.profile.vytvoril',
    ACCOUNTGROUP_DETAIL_PROFILE_ZMENIL: 'accountGroup.detail.profile.zmenil',
    ACCOUNTGROUP_DETAIL_PROFILE_DATUMY: 'accountGroup.detail.profile.datumy',
    ACCOUNTGROUP_DETAIL_PROFILE_VYTVORENO: 'accountGroup.detail.profile.vytvoreno',
    ACCOUNTGROUP_DETAIL_PROFILE_ZMENENO: 'accountGroup.detail.profile.zmeneno',
    ACCOUNTGROUP_DETAIL_IMPORTING_SAP: 'accountGroup.detail.importing.sap',
    ACCOUNTGROUP_EDIT_TITLE: 'accountGroup.edit.title',
    ACCOUNTGROUP_EDIT_NAZEV: 'accountGroup.edit.nazev',
    ACCOUNTGROUP_EDIT_PRIHLASENICERTIFIKATEM: 'accountGroup.edit.prihlaseniCertifikatem',
    ACCOUNTGROUP_FILTER_NAZEV_SKUPINY: 'accountGroup.filter.nazev.skupiny',
    ACCOUNTGROUP_FILTER_ICO: 'accountGroup.filter.ico',
    ACCOUNTGROUP_FILTER_CISLO_OBCHODNIHO_PARTNERA: 'accountGroup.filter.cislo.obchodniho.partnera',
    ACCOUNTGROUP_FILTER_EAN_EIC: 'accountGroup.filter.ean.eic',
    ACCOUNTGROUP_FILTER_OM_EAN_EIC: 'accountGroup.filter.om.ean.eic',
    ACCOUNTGROUP_FILTER_OM_PREV: 'accountGroup.filter.om.prev',
    ACCOUNTGROUP_FILTER_TYP: 'accountGroup.filter.typ',
    ACCOUNTGROUP_FILTER_TYP_VSE: 'accountGroup.filter.typ.vse',
    ACCOUNTGROUP_NEW_TITLE: 'accountGroup.new.title',
    ACCOUNTGROUP_NEW_OBCHODNIK_OBCHODNIK_LABEL: 'accountGroup.new.obchodnik.obchodnik.label',
    ACCOUNTGROUP_NEW_OBCHODNIK_OBCHODNIK_PLACEHOLDER: 'accountGroup.new.obchodnik.obchodnik.placeholder',
    ACCOUNTGROUP_NEW_OBCHODNIK_DETAIL: 'accountGroup.new.obchodnik.detail',
    ACCOUNTGROUP_NEW_OBCHODNIK_NAZEV: 'accountGroup.new.obchodnik.nazev',
    ACCOUNTGROUP_NEW_OBCHODNIK_PARTNER: 'accountGroup.new.obchodnik.partner',
    ACCOUNTGROUP_NEW_OBCHODNIK_IC: 'accountGroup.new.obchodnik.ic',
    ACCOUNTGROUP_NEW_OBCHODNIK_DIC: 'accountGroup.new.obchodnik.dic',
    ACCOUNTGROUP_NEW_OBCHODNIK_ELEKTRINA_TITLE: 'accountGroup.new.obchodnik.elektrina.title',
    ACCOUNTGROUP_NEW_OBCHODNIK_EAN_OBCHODNIKA: 'accountGroup.new.obchodnik.ean.obchodnika',
    ACCOUNTGROUP_NEW_OBCHODNIK_POSKYTOVATEL_ELEKTRINA: 'accountGroup.new.obchodnik.poskytovatel.elektrina',
    ACCOUNTGROUP_NEW_OBCHODNIK_ELEKTRINA: 'accountGroup.new.obchodnik.elektrina',
    ACCOUNTGROUP_NEW_OBCHODNIK_PLYN_TITLE: 'accountGroup.new.obchodnik.plyn.title',
    ACCOUNTGROUP_NEW_OBCHODNIK_EIC_OBCHODNIKA: 'accountGroup.new.obchodnik.eic.obchodnika',
    ACCOUNTGROUP_NEW_OBCHODNIK_POSKYTOVATEL_PLYN: 'accountGroup.new.obchodnik.poskytovatel.plyn',
    ACCOUNTGROUP_NEW_OBCHODNIK_PLYN: 'accountGroup.new.obchodnik.plyn',
    ACCOUNTGROUP_NEW_OBCHODNIK_VALIDATION_COMMODITY: 'accountGroup.new.obchodnik.validation.commodity',
    ACCOUNTGROUP_NEW_MUNICIPALITA_PLACEHOLDER: 'accountGroup.new.municipalita.placeholder',
    ACCOUNTGROUP_NEW_MUNICIPALITA_DETAIL: 'accountGroup.new.municipalita.detail',
    ACCOUNTGROUP_NEW_MUNICIPALITA_NAZEV: 'accountGroup.new.municipalita.nazev',
    ACCOUNTGROUP_NEW_MUNICIPALITA_PARTNER: 'accountGroup.new.municipalita.partner',
    ACCOUNTGROUP_NEW_MUNICIPALITA_IC: 'accountGroup.new.municipalita.ic',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_OBCHODNI_PARTNER_LABEL: 'accountGroup.new.obchodni.partner.obchodni.partner.label',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_OBCHODNI_PARTNER_PLACEHOLDER: 'accountGroup.new.obchodni.partner.obchodni.partner.placeholder',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_DETAIL: 'accountGroup.new.obchodni.partner.detail',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_PARTNER: 'accountGroup.new.obchodni.partner.partner',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_JMENO_PRIJMENI: 'accountGroup.new.obchodni.partner.jmeno.prijmeni',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_DATUM_NAROZENI: 'accountGroup.new.obchodni.partner.datum.narozeni',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_NAZEV_SPOLECNOSTI: 'accountGroup.new.obchodni.partner.nazev.spolecnosti',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_IC: 'accountGroup.new.obchodni.partner.ic',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_ADRESA: 'accountGroup.new.obchodni.partner.adresa',
    ACCOUNTGROUP_NEW_OBCHODNI_PARTNER_NASTAVENI: 'accountGroup.new.obchodni.partner.nastaveni',
    ACCOUNTGROUP_NEW_PRIHLASENI_CERTIFIKATEM: 'accountGroup.new.prihlaseni.certifikatem',
    ACCOUNTGROUP_KONTAKTY_VYTVOREN: 'accountGroup.kontakty.vytvoren',
    ACCOUNTGROUP_KONTAKTY_ERROR: 'accountGroup.kontakty.error',
    ACCOUNTGROUP_KONTAKTY_ZADNY_TITULEK: 'accountGroup.kontakty.zadny.titulek',
    ACCOUNTGROUP_KONTAKTY_ZADNY_TEXT: 'accountGroup.kontakty.zadny.text',
    ACCOUNTGROUP_KONTAKTY_SMAZAT_SOUHLAS: 'accountGroup.kontakty.smazat.souhlas',
    ACCOUNTGROUP_KONTAKTY_SMAZAN: 'accountGroup.kontakty.smazan',
    ACCOUNTGROUP_KONTAKTY_NEW_TITULEK: 'accountGroup.kontakty.new.titulek',
    ACCOUNTGROUP_KONTAKTY_NEW_ELEKTRINA: 'accountGroup.kontakty.new.elektrina',
    ACCOUNTGROUP_KONTAKTY_NEW_PLYN: 'accountGroup.kontakty.new.plyn',
    ACCOUNTGROUP_KONTAKTY_NEW_DRUH_KOMUNIKACE: 'accountGroup.kontakty.new.druh.komunikace',
    ACCOUNTGROUP_KONTAKTY_NEW_EAN: 'accountGroup.kontakty.new.ean',
    ACCOUNTGROUP_KONTAKTY_NEW_EIC: 'accountGroup.kontakty.new.eic',
    ACCOUNTGROUP_KONTAKTY_NEW_EAN_EIC: 'accountGroup.kontakty.new.ean.eic',
    ACCOUNTGROUP_KONTAKTY_NEW_LTR_VAROVANI: 'accountGroup.kontakty.new.ltr.varovani',
    ACCOUNTGROUP_KONTAKTY_NEW_OBCHODNI_PARTNER: 'accountGroup.kontakty.new.obchodni.partner',
    ACCOUNTGROUP_KONTAKTY_NEW_EAN_ADRESA: 'accountGroup.kontakty.new.ean.adresa',
    ACCOUNTGROUP_KONTAKTY_NEW_EIC_ADRESA: 'accountGroup.kontakty.new.eic.adresa',
    ACCOUNTGROUP_KONTAKTY_NEW_EAN_EIC_ADRESA: 'accountGroup.kontakty.new.ean.eic.adresa',
    ACCOUNTGROUP_KONTAKTY_NEW_EMAIL: 'accountGroup.kontakty.new.email',
    ACCOUNTGROUP_KONTAKTY_NEW_EMAIL_PLACEHOLDER: 'accountGroup.kontakty.new.email.placeholder',
    ACCOUNTGROUP_KONTAKTY_NEW_TELEFON: 'accountGroup.kontakty.new.telefon',
    ACCOUNTGROUP_KONTAKTY_NEW_TELEFON_PLACEHOLDER: 'accountGroup.kontakty.new.telefon.placeholder',
    ACCOUNTGROUP_KONTAKTY_NEW_DATOVA_SCHRANKA: 'accountGroup.kontakty.new.datova.schranka',
    ACCOUNTGROUP_KONTAKTY_ZVALIDOVAT: 'accountGroup.kontakty.zvalidovat',
    ACCOUNTGROUP_KONTAKTY_ZVALIDOVAT_TITLE: 'accountGroup.kontakty.zvalidovat.title',
    ACCOUNTGROUP_KONTAKTY_K_ZVALIDOVANI: 'accountGroup.kontakty.k.zvalidovani',
    ACCOUNTGROUP_KONTAKTY_WITHOUT_EANEIC: 'accountGroup.kontakty.without.eanEic',
    ACCOUNTGROUP_KONTAKTY_WITH_EANEIC: 'accountGroup.kontakty.with.eanEic',
    ADMIN_FILTER_FULLTEXT: 'admin.filter.fulltext',
    ACCOUNTGROUP_EDIT_JMENO: 'accountGroup.edit.jmeno',
    ACCOUNTGROUP_EDIT_PRIJMENI: 'accountGroup.edit.prijmeni',
    ACCOUNTGROUP_EDIT_NAZEV_FIRMY: 'accountGroup.edit.nazev.firmy',
    ACCOUNTGROUP_EDIT_PROFILE_IC: 'accountGroup.edit.profile.ic',
    ACCOUNTGROUP_SELF_CREATED: 'accountGroup.self.created',
    ACCOUNTGROUP_NOTIFIKACE_TITLE: 'accountGroup.notifikace.title',
    ACCOUNTGROUP_NOTIFIKACE_IDENTIFIKATOR: 'accountGroup.notifikace.identifikator',
    ACCOUNTGROUP_NOTIFIKACE_TYP_KONTAKTU: 'accountGroup.notifikace.typ.kontaktu',
    ACCOUNTGROUP_NOTIFIKACE_KONTAKT: 'accountGroup.notifikace.kontakt',
    ACCOUNTGROUP_NOTIFIKACE_PARAMETRY: 'accountGroup.notifikace.parametry',
    ACCOUNTGROUP_NOTIFIKACE_VYTVORENO: 'accountGroup.notifikace.vytvoreno',
    ACCOUNTGROUP_NOTIFIKACE_ODESLANA: 'accountGroup.notifikace.odeslana',
    ACCOUNTGROUP_MUNICIPALITA_OVERENI_ERROR: 'accountGroup.municipalita.overeni.error',
    ACCOUNTGROUP_MUNICIPALITA_OVERENI_ERROR_ZDE: 'accountGroup.municipalita.overeni.error.zde',
    PRILOHY_VELIKOST: 'prilohy.velikost',
    PRILOHY_NAZEV: 'prilohy.nazev',
    PRILOHY_MIME: 'prilohy.mime',
    ACCOUNT_FILTER_FULLTEXT: 'account.filter.fulltext',
    ACCOUNT_FILTER_FULLTEXT_EAN: 'account.filter.fulltext.ean',
    ACCOUNT_FILTER_FULLTEXT_EIC: 'account.filter.fulltext.eic',
    ACCOUNT_FILTER_FULLTEXT_ADRESA: 'account.filter.fulltext.adresa',
    ACCOUNT_FILTER_GROUP: 'account.filter.group',
    ACCOUNT_FILTER_GROUP_TYPE: 'account.filter.group.type',
    ACCOUNT_PRISTUPY: 'account.pristupy',
    ACCOUNT_OBCHODNICI: 'account.obchodnici',
    ACCOUNT_PRISTUPY_NEW: 'account.pristupy.new',
    ACCOUNT_PRISTUPY_UPDATE: 'account.pristupy.update',
    ACCOUNT_PRISTUPY_SKUPINY: 'account.pristupy.skupiny',
    ACCOUNT_PRISTUPY_SKUPINY_NAZEV: 'account.pristupy.skupiny.nazev',
    ACCOUNT_DETAIL_SPRAVCE_ZMOCNENEC: 'account.detail.spravce.zmocnenec',
    ACCOUNT_NEW_GROUP_LABEL: 'account.new.group.label',
    ACCOUNT_NEW_GROUP_PLACEHOLDER: 'account.new.group.placeholder',
    ACCOUNT_NEW_DETAIL: 'account.new.detail',
    ACCOUNT_NEW_TITLE: 'account.new.title',
    ACCOUNT_SMAZAT_UCET: 'account.smazat.ucet',
    ACCOUNT_NEW_TAB_GROUP: 'account.new.tab.group',
    ACCOUNT_NEW_TAB_PROFILE: 'account.new.tab.profile',
    ACCOUNT_NEW_TAB_CERTIFICATES: 'account.new.tab.certificates',
    ACCOUNT_NEW_GROUP_DETAIL: 'account.new.group.detail',
    ACCOUNT_EDIT_JMENO: 'account.edit.jmeno',
    ACCOUNT_EDIT_PRIJMENI: 'account.edit.prijmeni',
    ACCOUNT_EDIT_NAZEV_FIRMY: 'account.edit.nazev.firmy',
    ACCOUNT_EDIT_EMAIL: 'account.edit.email',
    ACCOUNT_EDIT_TELEFON: 'account.edit.telefon',
    ACCOUNT_EDIT_AKTIVNI: 'account.edit.aktivni',
    ACCOUNT_EDIT_ADMIN: 'account.edit.admin',
    ACCOUNT_EDIT_PODPIS_DODATKU: 'account.edit.podpis.dodatku',
    ACCOUNT_EDIT_PODPIS_SOP_ELEKTRINA: 'account.edit.podpis.sop.elektrina',
    ACCOUNT_EDIT_PODPIS_SOP_PLYN: 'account.edit.podpis.sop.plyn',
    ACCOUNT_EDIT_FUNKCE_PODPIS_SOP_ELEKTRINA: 'account.edit.funkce.podpis.sop.elektrina',
    ACCOUNT_EDIT_FUNKCE_PODPIS_SOP_PLYN: 'account.edit.funkce.podpis.sop.plyn',
    ACCOUNT_PRIHLASIT_ZA_UZIVATELE: 'account.prihlasit.za.uzivatele',
    ACCOUNT_PRIHLASIT_ZA_UZIVATELE_SPP: 'account.prihlasit.za.uzivatele.spp',
    ACCOUNT_JMENO_PRIJMENI: 'account.jmeno.prijmeni',
    ACCOUNT_JMENO: 'account.jmeno',
    ACCOUNT_OM: 'account.om',
    ACCOUNT_OM_POVINNE: 'account.om.povinne',
    ACCOUNT_OM_VYBER: 'account.om.vyber',
    ACCOUNT_OM_FILTER_TITLE: 'account.om.filter.title',
    ACCOUNT_OM_ELEKTRINA: 'account.om.elektrina',
    ACCOUNT_OM_PLYN: 'account.om.plyn',
    ACCOUNT_OM_EAN_VYROBNI: 'account.om.ean.vyrobni',
    ACCOUNT_OM_SPOTREBNI: 'account.om.spotrebni',
    ACCOUNT_OM_EIC: 'account.om.eic',
    ACCOUNT_OM_ELEKTRINA_VSE: 'account.om.elektrina.vse',
    ACCOUNT_OM_PLYN_VSE: 'account.om.plyn.vse',
    ACCOUNT_PRIJMENI: 'account.prijmeni',
    ACCOUNT_SKUPINA: 'account.skupina',
    ACCOUNT_TELEFON: 'account.telefon',
    ACCOUNT_EMAIL_EXISTS: 'account.email.exists',
    ACCOUNT_EMAIL: 'account.email',
    ACCOUNT_REGISTRACE_DOKONCENA: 'account.registrace.dokoncena',
    ACCOUNT_AKTIVNI: 'account.aktivni',
    ACCOUNT_AKTIVNI_UCET: 'account.aktivni.ucet',
    ACCOUNT_AKTIVNI_PRISTUP: 'account.aktivni.pristup',
    ACCOUNT_ADMIN: 'account.admin',
    ACCOUNT_VYTVORIL: 'account.vytvoril',
    ACCOUNT_ZMENIL: 'account.zmenil',
    ACCOUNT_VYTVORENO: 'account.vytvoreno',
    ACCOUNT_ZMENENO: 'account.zmeneno',
    ACCOUNT_MULTIFAKTOR: 'account.multifaktor',
    ACCOUNT_MULTIFAKTOR_EMAIL: 'account.multifaktor.email',
    ACCOUNT_MULTIFAKTOR_SMS: 'account.multifaktor.sms',
    ACCOUNT_MULTIFAKTOR_VYPNUTA: 'account.multifaktor.vypnuta',
    ACCOUNT_MULTIFAKTOR_VYPNOUT: 'account.multifaktor.vypnout',
    ACCOUNT_MULTIFAKTOR_VYPNUTA_NOTIFIKACE: 'account.multifaktor.vypnuta.notifikace',
    ACCOUNT_PODPIS_DODATKU: 'account.podpis.dodatku',
    ACCOUNT_PODPIS_SOP_ELEKTRINA: 'account.podpis.sop.elektrina',
    ACCOUNT_PODPIS_SOP_PLYN: 'account.podpis.sop.plyn',
    ACCOUNT_FUNKCE_PODPIS_SOP_ELEKTRINA: 'account.funkce.podpis.sop.elektrina',
    ACCOUNT_FUNKCE_PODPIS_SOP_PLYN: 'account.funkce.podpis.sop.plyn',
    ACCOUNT_LIST_DEACTIVATE_CONFIRM: 'account.list.deactivate.confirm',
    ACCOUNT_LIST_DEACTIVATE_CONFIRM_PRISTUP: 'account.list.deactivate.confirm.pristup',
    ACCOUNT_LIST_DELETE_CONFIRM_PRISTUP: 'account.list.delete.confirm.pristup',
    ACCOUNT_LIST_RESET_PASSWORD_CONFIRM: 'account.list.reset.password.confirm',
    ACCOUNT_LIST_RESET_PASSWORD_REQUEST_SENT: 'account.list.reset.password.request.sent',
    ACCOUNT_LIST_DEACTIVATED: 'account.list.deactivated',
    ACCOUNT_SPRAVCE_ZMOCNENEC_DEACTIVATED: 'account.spravce.zmocnenec.deactivated',
    ACCOUNT_SPRAVCE_ZMOCNENEC_UPDATE: 'account.spravce.zmocnenec.update',
    ACCOUNT_PRISTUP_DEACTIVATED: 'account.pristup.deactivated',
    ACCOUNT_PRISTUP_ACTIVATED: 'account.pristup.activated',
    ACCOUNT_PRISTUP_DELETED: 'account.pristup.deleted',
    ACCOUNT_PRISTUP_CREATED: 'account.pristup.created',
    ACCOUNT_PRISTUP_UPDATED: 'account.pristup.updated',
    ACCOUNT_LIST_CREATED_1: 'account.list.created.1',
    ACCOUNT_LIST_CREATED_2: 'account.list.created.2',
    ACCOUNT_LIST_UPDATED_1: 'account.list.updated.1',
    ACCOUNT_LIST_UPDATED_2: 'account.list.updated.2',
    ACCOUNT_EDIT_TITLE: 'account.edit.title',
    ACCOUNT_DETAIL_DETAIL: 'account.detail.detail',
    ACCOUNT_DETAIL_JMENO_PRIJMENI: 'account.detail.jmeno.prijmeni',
    ACCOUNT_DETAIL_NASTAVENI: 'account.detail.nastaveni',
    ACCOUNT_DETAIL_LIDE: 'account.detail.lide',
    ACCOUNT_DETAIL_DATUMY: 'account.detail.datumy',
    ACCOUNT_DETAIL_UPRAVEN: 'account.detail.upraven',
    ACCOUNT_DETAIL_UPRAVIT: 'account.detail.upravit',
    ACCOUNT_DETAIL_DEAKTIVOVAN: 'account.detail.deaktivovan',
    ACCOUNT_DETAIL_AKTIVOVAN: 'account.detail.aktivovan',
    ACCOUNT_DETAIL_TAB_PROFIL: 'account.detail.tab.profil',
    ACCOUNT_DETAIL_TAB_CERTIFIKATY: 'account.detail.tab.certifikaty',
    ACCOUNT_DETAIL_TAB_NOTIFIKACE: 'account.detail.tab.notifikace',
    ACCOUNT_DETAIL_DEAKTIVOVAT_POTVRZENI: 'account.detail.deaktivovat.potvrzeni',
    ACCOUNT_DETAIL_DEAKTIVOVAT_POTVRZENI_LAST_ADMIN: 'account.detail.deaktivovat.potvrzeni.last.admin',
    ACCOUNT_CERTIFICATES_TITLE: 'account.certificates.title',
    ACCOUNT_CERTIFICATES_DETAIL: 'account.certificates.detail',
    ACCOUNT_CERTIFICATES_ONLYACTIVE: 'account.certificates.onlyActive',
    ACCOUNT_CERTIFICATES_SUBJEKT: 'account.certificates.subjekt',
    ACCOUNT_CERTIFICATES_VYDAVATEL: 'account.certificates.vydavatel',
    ACCOUNT_CERTIFICATES_PLATNOSTOD: 'account.certificates.platnostOd',
    ACCOUNT_CERTIFICATES_PLATNOSTDO: 'account.certificates.platnostDo',
    ACCOUNT_CERTIFICATES_STAV: 'account.certificates.stav',
    ACCOUNT_CERTIFICATES_NAZEVSOUBORU: 'account.certificates.nazevSouboru',
    ACCOUNT_CERTIFICATES_SERIOVECISLO: 'account.certificates.serioveCislo',
    ACCOUNT_CERTIFICATES_TYP: 'account.certificates.typ',
    ACCOUNT_CERTIFICATES_VERZE: 'account.certificates.verze',
    ACCOUNT_CERTIFICATES_VYTVORENO: 'account.certificates.vytvoreno',
    ACCOUNT_CERTIFICATES_VYTVORIL: 'account.certificates.vytvoril',
    ACCOUNT_CERTIFICATES_DEAKTIVOVANO: 'account.certificates.deaktivovano',
    ACCOUNT_CERTIFICATES_DEAKTIVOVAL: 'account.certificates.deaktivoval',
    ACCOUNT_CERTIFICATES_GROUP_INFORMACE: 'account.certificates.group.informace',
    ACCOUNT_CERTIFICATES_GROUP_LIDE: 'account.certificates.group.lide',
    ACCOUNT_CERTIFICATES_ZADNEAKTIVNI: 'account.certificates.zadneAktivni',
    ACCOUNT_CERTIFICATES_ZADNE: 'account.certificates.zadne',
    ACCOUNT_CERTIFICATES_MENU_DETAIL: 'account.certificates.menu.detail',
    ACCOUNT_CERTIFICATES_MENU_DEACTIVATE: 'account.certificates.menu.deactivate',
    ACCOUNT_CERTIFICATES_MENU_DOWNLOAD: 'account.certificates.menu.download',
    ACCOUNT_CERTIFICATES_DEACTIVATE_CONFIRM: 'account.certificates.deactivate.confirm',
    ACCOUNT_CERTIFICATES_DEACTIVATED: 'account.certificates.deactivated',
    ACCOUNT_CERTIFICATES_BUTTON_ADD: 'account.certificates.button.add',
    ACCOUNT_CERTIFICATES_ADD_TITLE: 'account.certificates.add.title',
    ACCOUNT_CERTIFICATES_ADD_FILEINPUT: 'account.certificates.add.fileInput',
    ACCOUNT_CERTIFICATES_ADD_READERROR: 'account.certificates.add.readError',
    ACCOUNT_CERTIFICATES_ADD_TOOLONG: 'account.certificates.add.tooLong',
    ACCOUNT_CERTIFICATES_ADD_ADDED: 'account.certificates.add.added',
    ACCOUNT_CERTIFICATES_ADD_ADD_ONE: 'account.certificates.add.add.one',
    ACCOUNT_CERTIFICATES_ADD_TOO_BIG: 'account.certificates.add.too.big',
    ACCOUNT_CERTIFICATES_ADD_ERROR: 'account.certificates.add.error',
    ACCOUNT_CERTIFICATES_ADD_CHECKING: 'account.certificates.add.checking',
    ACCOUNT_CERTIFICATES_ADD_CREATING: 'account.certificates.add.creating',
    ACCOUNT_CERTIFICATES_ADD_CHECKTITLE: 'account.certificates.add.checkTitle',
    ACCOUNT_CERTIFICATES_WARNING: 'account.certificates.warning',
    ACCOUNT_CERTIFICATES_STATE_ACTIVE: 'account.certificates.state.active',
    ACCOUNT_CERTIFICATES_STATE_CANCELED: 'account.certificates.state.canceled',
    ACCOUNT_CERTIFICATES_STATE_EXPIRED: 'account.certificates.state.expired',
    ACCOUNT_CERTIFICATES_CLEAR: 'account.certificates.clear',
    ACCOUNT_PRISTUPY_TITLE: 'account.pristupy.title',
    ACCOUNT_PRISTUPY_ADMIN: 'account.pristupy.admin',
    ACCOUNT_PRISTUPY_PRIHLASENO: 'account.pristupy.prihlaseno',
    ACCOUNT_PRISTUPY_ZADNE_AKCE: 'account.pristupy.zadne.akce',
    ACCOUNT_PRISTUPY_PRISTUP_TITLE: 'account.pristupy.pristup.title',
    ACCOUNT_PRISTUPY_PRISTUP_TAB_PREHLED: 'account.pristupy.pristup.tab.prehled',
    ACCOUNT_PRISTUPY_PRISTUP_TAB_DETAIL: 'account.pristupy.pristup.tab.detail',
    ACCOUNT_PRISTUPY_AKCE_PROVEDENO: 'account.pristupy.akce.provedeno',
    ACCOUNT_PRISTUPY_AKCE_CHYBA: 'account.pristupy.akce.chyba',
    ACCOUNT_PRISTUPY_AKCE_TYP_OBJEKTU: 'account.pristupy.akce.typ.objektu',
    ACCOUNT_PRISTUPY_AKCE_TYP_AKCE: 'account.pristupy.akce.typ.akce',
    ACCOUNT_PRISTUPY_AKCE_VSTUPNI_DATA: 'account.pristupy.akce.vstupni.data',
    ACCOUNT_PRISTUPY_AKCE_OBJEKT_PRED: 'account.pristupy.akce.objekt.pred',
    ACCOUNT_PRISTUPY_AKCE_OBJEKT_PO: 'account.pristupy.akce.objekt.po',
    ACCOUNT_PRISTUPY_AKCE_DETAIL: 'account.pristupy.akce.detail',
    ACCOUNT_PRISTUPY_FILTR_PRIHLASENO_OD: 'account.pristupy.filtr.prihlaseno.od',
    ACCOUNT_PRISTUPY_FILTR_PRIHLASENO_DO: 'account.pristupy.filtr.prihlaseno.do',
    ACCOUNT_NOTIFIKACE_TITLE: 'account.notifikace.title',
    ACCOUNT_NOTIFIKACE_TITLE_SPRAVCE_ZMOCNENEC: 'account.notifikace.title.spravce.zmocnenec',
    ACCOUNT_NOTIFIKACE_IDENTIFIKATOR: 'account.notifikace.identifikator',
    ACCOUNT_NOTIFIKACE_TYP_KONTAKTU: 'account.notifikace.typ.kontaktu',
    ACCOUNT_NOTIFIKACE_KONTAKT: 'account.notifikace.kontakt',
    ACCOUNT_NOTIFIKACE_PARAMETRY: 'account.notifikace.parametry',
    ACCOUNT_NOTIFIKACE_VYTVORENO: 'account.notifikace.vytvoreno',
    ACCOUNT_NOTIFIKACE_ODESLANA: 'account.notifikace.odeslana',
    ACCOUNT_POLE_POVINNE: 'account.pole.povinne',
    ACCOUNT_VAZBA: 'account.vazba',
    ACCOUNT_VAZBA_ADD: 'account.vazba.add',
    ACCOUNT_ADD_ZADATEL_INFO: 'account.add.zadatel.info',
    ACCOUNT_UCTU_TYP_PRISTUP_TOOLTIP_SPRAVCE: 'account.uctu.typ.pristup.tooltip.spravce',
    ACCOUNT_UCTU_TYP_PRISTUP_TOOLTIP_UZIVATEL: 'account.uctu.typ.pristup.tooltip.uzivatel',
    ACCOUNT_UCTU_TYP_ZMOCNENEC: 'account.uctu.typ.zmocnenec',
    ACCOUNT_UCTU_TYP_SPRAVCE: 'account.uctu.typ.spravce',
    ACCOUNT_UCTU_TYP_ZADATEL: 'account.uctu.typ.zadatel',
    ACCOUNT_UCTU_TYP_OBCHODNIK: 'account.uctu.typ.obchodnik',
    ACCOUNT_UCTU_TYP_KONCOVY_ZAKAZNIK: 'account.uctu.typ.koncovy_zakaznik',
    ACCOUNT_UCTU_TYP_MUNICIPALITA: 'account.uctu.typ.municipalita',
    ACCOUNT_UCTU_TYP_PRISTUP: 'account.uctu.typ.pristup',
    ACCOUNT_UCTU_TYP_UCTU: 'account.uctu.typ.uctu',
    UCETLOGAKCETYP_VYTVORIT: 'UcetLogAkceTyp.VYTVORIT',
    UCETLOGAKCETYP_UPRAVIT: 'UcetLogAkceTyp.UPRAVIT',
    UCETLOGAKCETYP_SMAZAT: 'UcetLogAkceTyp.SMAZAT',
    UCETLOGAKCETYP_DEAKTIVOVAT: 'UcetLogAkceTyp.DEAKTIVOVAT',
    UCETLOGAKCETYP_ODESLAT: 'UcetLogAkceTyp.ODESLAT',
    UCETLOGAKCETYP_STORNOVAT: 'UcetLogAkceTyp.STORNOVAT',
    ODSTAVKYUCTY_FILTER_FULLTEXT_LABEL: 'odstavkyUcty.filter.fulltext.label',
    ODSTAVKYUCTY_DETAIL_DETAIL: 'odstavkyUcty.detail.detail',
    ODSTAVKYUCTY_DETAIL_LIDE: 'odstavkyUcty.detail.lide',
    ODSTAVKYUCTY_DETAIL_DATUMY: 'odstavkyUcty.detail.datumy',
    ODSTAVKYUCTY_JMENO: 'odstavkyUcty.jmeno',
    ODSTAVKYUCTY_PRIJMENI: 'odstavkyUcty.prijmeni',
    ODSTAVKYUCTY_PRIJMENI_JMENO: 'odstavkyUcty.prijmeni.jmeno',
    ODSTAVKYUCTY_EMAIL: 'odstavkyUcty.email',
    ODSTAVKYUCTY_AKTIVNI: 'odstavkyUcty.aktivni',
    ODSTAVKYUCTY_VYTVORIL: 'odstavkyUcty.vytvoril',
    ODSTAVKYUCTY_ZMENIL: 'odstavkyUcty.zmenil',
    ODSTAVKYUCTY_VYTVORENO: 'odstavkyUcty.vytvoreno',
    ODSTAVKYUCTY_ZMENENO: 'odstavkyUcty.zmeneno',
    ODSTAVKYUCTY_HESLO: 'odstavkyUcty.heslo',
    ODSTAVKYUCTY_HESLO_ZNOVU: 'odstavkyUcty.heslo.znovu',
    ODSTAVKYUCTY_POZNAMKA: 'odstavkyUcty.poznamka',
    ODSTAVKYUCTY_UPRAVEN: 'odstavkyUcty.upraven',
    ODSTAVKYUCTY_DEAKTIVOVAN: 'odstavkyUcty.deaktivovan',
    ODSTAVKYUCTY_AKTIVOVAN: 'odstavkyUcty.aktivovan',
    ODSTAVKYUCTY_REAKTIVUJI: 'odstavkyUcty.reaktivuji',
    ODSTAVKYUCTY_EDIT_TITLE: 'odstavkyUcty.edit.title',
    ODSTAVKYUCTY_DEAKTIVOVANY: 'odstavkyUcty.deaktivovany',
    ODSTAVKYUCTY_VYTVOREN_BEFORE: 'odstavkyUcty.vytvoren.before',
    ODSTAVKYUCTY_UPRAVEN_BEFORE: 'odstavkyUcty.upraven.before',
    ODSTAVKYUCTY_VYTVOREN_AFTER: 'odstavkyUcty.vytvoren.after',
    ODSTAVKYUCTY_UPRAVEN_AFTER: 'odstavkyUcty.upraven.after',
    ODSTAVKYUCTY_DETAIL: 'odstavkyUcty.detail',
    ODSTAVKYUCTY_UPRAVIT: 'odstavkyUcty.upravit',
    ODSTAVKYUCTY_DEAKTIVOVAT_UCET_CONFIRM: 'odstavkyUcty.deaktivovat.ucet.confirm',
    ODSTAVKYUCTY_DEAKTIVOVAT_UCTY_CONFIRM: 'odstavkyUcty.deaktivovat.ucty.confirm',
    ODSTAVKYUCTY_NOVY: 'odstavkyUcty.novy',
    ADMIN_PRIJMENI_JMENO: 'admin.prijmeni.jmeno',
    ADMIN_JMENO: 'admin.jmeno',
    ADMIN_ROLE_AKTIVNI: 'admin.role.aktivni',
    ADMIN_ROLE_NEAKTIVNI: 'admin.role.neaktivni',
    ADMIN_ROLE_DETAIL: 'admin.role.detail',
    ADMIN_ROLE_PRIDELIT: 'admin.role.pridelit',
    ADMIN_ROLE_ODEBRAT: 'admin.role.odebrat',
    ADMIN_PRIJMENI: 'admin.prijmeni',
    ADMIN_EMAIL: 'admin.email',
    ADMIN_KID: 'admin.kid',
    ADMIN_TELEFON: 'admin.telefon',
    ADMIN_AKTIVNI: 'admin.aktivni',
    ADMIN_VYTVORIL: 'admin.vytvoril',
    ADMIN_ZMENIL: 'admin.zmenil',
    ADMIN_VYTVORENO: 'admin.vytvoreno',
    ADMIN_ZMENENO: 'admin.zmeneno',
    ADMIN_DETAIL_DETAIL: 'admin.detail.detail',
    ADMIN_DETAIL_ROLE: 'admin.detail.role',
    ADMIN_DETAIL_ROLE_NAZEV: 'admin.detail.role.nazev',
    ADMIN_DETAIL_ROLE_STAV: 'admin.detail.role.stav',
    ADMIN_DETAIL_LIDE: 'admin.detail.lide',
    ADMIN_DETAIL_DATUMY: 'admin.detail.datumy',
    ADMIN_NOTIFICATION_SAVED: 'admin.notification.saved',
    ADMIN_NOTIFICATION_DEACTIVATED: 'admin.notification.deactivated',
    ADMIN_NOTIFICATION_MORE_DEACTIVATED: 'admin.notification.more.deactivated',
    ADMIN_NOTIFICATION_REACTIVATED: 'admin.notification.reactivated',
    ADMIN_DEACTIVATE_MORE_CONFIRM: 'admin.deactivate.more.confirm',
    ADMIN_DEACTIVATE_CONFIRM: 'admin.deactivate.confirm',
    ADMIN_NEW_TITLE: 'admin.new.title',
    ADMIN_EDIT_TITLE: 'admin.edit.title',
    ADMIN_CREATED_1: 'admin.created.1',
    ADMIN_CREATED_2: 'admin.created.2',
    ADMIN_UPDATED_1: 'admin.updated.1',
    ADMIN_UPDATED_2: 'admin.updated.2',
    ADMIN_EDIT_HESLO: 'admin.edit.heslo',
    ADMIN_EDIT_HESLO_ZNOVU: 'admin.edit.heslo.znovu',
    ADMIN_PRIHLASENI_LOADING_DESCRIPTION: 'admin.prihlaseni.loading.description',
    ADMIN_PRIHLASENI_KONTROLA_UDAJE: 'admin.prihlaseni.kontrola.udaje',
    ADMIN_PRIHLASENI_KONTROLA_UDAJE_SPATNE: 'admin.prihlaseni.kontrola.udaje.spatne',
    ADMIN_NOTIFIKACE_TITLE: 'admin.notifikace.title',
    AUTOCOMPLETE_OBCHODNIK_PLACEHOLDER: 'autocomplete.obchodnik.placeholder',
    AUTOCOMPLETE_OBCHODNI_PARTNER_PLACEHOLDER: 'autocomplete.obchodni.partner.placeholder',
    AUTOCOMPLETE_MUNICIPALITA_PLACEHOLDER: 'autocomplete.municipalita.placeholder',
    REQUEST_NEW_TITLE: 'request.new.title',
    REQUEST_NEW_TYP: 'request.new.typ',
    REQUEST_NEW_ACCOUNT_FIXED: 'request.new.account.fixed',
    REQUEST_NEW_ACCOUNT_AUTOCOMPLETE: 'request.new.account.autocomplete',
    REQUEST_NEW_NAZEV: 'request.new.nazev',
    REQUEST_NEW_POPIS: 'request.new.popis',
    REQUEST_DETAIL_TITLE: 'request.detail.title',
    REQUEST_POZADAVEK_UPRAVEN: 'request.pozadavek.upraven',
    REQUEST_POZADAVEK_SCHVALIT: 'request.pozadavek.schvalit',
    REQUEST_POZADAVEK_SCHVALEN: 'request.pozadavek.schvalen',
    REQUEST_POZADAVEK_STORNOVAT: 'request.pozadavek.stornovat',
    REQUEST_POZADAVEK_STORNOVAN: 'request.pozadavek.stornovan',
    REQUEST_POZADAVEK_ZAMITNOUT: 'request.pozadavek.zamitnout',
    REQUEST_POZADAVEK_ZAMITNUT: 'request.pozadavek.zamitnut',
    REQUEST_POZADAVEK_DOPLNENI: 'request.pozadavek.doplneni',
    REQUEST_LEFT_MENU_UZIVATELE_V_PROCESU: 'request.left.menu.UZIVATELE_V_PROCESU',
    REQUEST_LEFT_MENU_POZADAVKY_CEKA_NA_DOPLNENI: 'request.left.menu.POZADAVKY_CEKA_NA_DOPLNENI',
    REQUEST_LEFT_MENU_POZADAVKY_V_RESENI: 'request.left.menu.POZADAVKY_V_RESENI',
    REQUEST_LEFT_MENU_POZADAVKY_SCHVALENE: 'request.left.menu.POZADAVKY_SCHVALENE',
    REQUEST_LEFT_MENU_POZADAVKY_ZAMITNUTE: 'request.left.menu.POZADAVKY_ZAMITNUTE',
    REQUEST_LEFT_MENU_POZADAVKY_STORNOVANE: 'request.left.menu.POZADAVKY_STORNOVANE',
    REQUEST_LEFT_MENU_POZADAVKY_VSE: 'request.left.menu.POZADAVKY_VSE',
    REQUEST_ACCOUNTDETAIL_REGISTRATION_JMENO: 'request.accountDetail.registration.jmeno',
    REQUEST_ACCOUNTDETAIL_REGISTRATION_PRIJMENI: 'request.accountDetail.registration.prijmeni',
    REQUEST_ACCOUNTDETAIL_REGISTRATION_EMAIL: 'request.accountDetail.registration.email',
    REQUEST_ACCOUNTDETAIL_REGISTRATION_TELEFON: 'request.accountDetail.registration.telefon',
    REQUEST_ACCOUNTDETAIL_ERROR_NOT_EMPTY: 'request.accountDetail.error.not.empty',
    REQUEST_ACCOUNTDETAIL_ERROR_VALUE_MISMATCH: 'request.accountDetail.error.value.mismatch',
    REQUEST_ACCOUNTDETAIL_ERROR_NO_ADMINS: 'request.accountDetail.error.no.admins',
    REQUEST_ACCOUNTDETAIL_ERROR_ACCOUNT_EXISTS: 'request.accountDetail.error.account.exists',
    REQUEST_ACCOUNTDETAIL_ERROR_GROUP_EXISTS: 'request.accountDetail.error.group.exists',
    REQUEST_ACCOUNTDETAIL_ERROR_BACKEND: 'request.accountDetail.error.backend',
    REQUEST_ACCOUNTDETAIL_ERROR_INCOMPLETE: 'request.accountDetail.error.incomplete',
    REQUEST_ACCOUNTDETAIL_REQUESTS: 'request.accountDetail.requests',
    REQUEST_ACCOUNTDETAIL_PRESUNOUT: 'request.accountDetail.presunout',
    REQUEST_ACCOUNTDETAIL_PRESUNOUT_POTVRZENI: 'request.accountDetail.presunout.potvrzeni',
    VSECHNA_ODBERNA_MISTA_CHECKBOX: 'vsechna.odberna.mista.checkbox',
    ADRESA_ULICE: 'adresa.ulice',
    ADRESA_CISLO_POPISNE: 'adresa.cislo.popisne',
    ADRESA_CISLO_ORIENTACNI: 'adresa.cislo.orientacni',
    ADRESA_MESTO: 'adresa.mesto',
    ADRESA_MISTNI_CAST: 'adresa.mistni.cast',
    ADRESA_PSC: 'adresa.psc',
    CREATED_BY_SYSTEM: 'created.by.system',
    REKLAMACE_TEXTACE_UPRAVA: 'reklamace.textace.uprava',
    REKLAMACE_TEXTACE_KLIC: 'reklamace.textace.klic',
    REKLAMACE_COLUMN_HEADER: 'reklamace.column.header',
    REKLAMACE_TEXTACE_ULOZENA: 'reklamace.textace.ulozena',
    REKLAMACE_TEXTACE_TEXT: 'reklamace.textace.text',
    REGISTR_ADRES_IMPORT: 'registr.adres.import',
    REGISTR_ADRES_IMPORT_SPUSTIT: 'registr.adres.import.spustit',
    REGISTR_ADRES_IMPORT_DATUM: 'registr.adres.import.datum',
    REGISTR_ADRES_IMPORT_VERZE: 'registr.adres.import.verze',
    REGISTR_ADRES_IMPORT_STAV: 'registr.adres.import.stav',
    REGISTR_ADRES_IMPORT_CHYBA: 'registr.adres.import.chyba',
    REGISTR_ADRES_IMPORT_SPUSTEN: 'registr.adres.import.spusten',
    REGISTR_ADRES_IMPORT_ERROR: 'registr.adres.import.error',
    REGISTR_ADRES_IMPORT_FILTER_DATUM_OD: 'registr.adres.import.filter.datum.od',
    REGISTR_ADRES_IMPORT_FILTER_DATUM_DO: 'registr.adres.import.filter.datum.do',
    REGISTR_ADRES_IMPORT_CHYBNE: 'registr.adres.import.chybne',
    REGISTR_ADRES_IMPORT_LEFT_MENU: 'registr.adres.import.left.menu',
    KONFIGURACE_CONFIG_SET_ODBERNA_MISTA: 'konfigurace.config.set.odberna.mista',
    KONFIGURACE_CONFIG_SET_RELEVANTNI: 'konfigurace.config.set.relevantni',
    KONFIGURACE_CONFIG_SET_PRAVNI_SUBJEKT: 'konfigurace.config.set.pravni.subjekt',
    KONFIGURACE_CONFIG_SET_PRAVNI_SUBJEKT_PODNIKATEL_PRAVNICKA_OSOBA: 'konfigurace.config.set.pravni.subjekt.PODNIKATEL_PRAVNICKA_OSOBA',
    KONFIGURACE_CONFIG_SET_PRAVNI_SUBJEKT_FYZICKA_OSOBA_FO: 'konfigurace.config.set.pravni.subjekt.FYZICKA_OSOBA_FO',
    KONFIGURACE_CONFIG_SET_PRAVNI_SUBJEKT_OBOJE: 'konfigurace.config.set.pravni.subjekt.OBOJE',
    KONFIGURACE_CONFIG_SET_TECH_UZIVATEL_GEO_PORTAL: 'konfigurace.config.set.tech.uzivatel.geo.portal',
    KONFIGURACE_CONFIG_SET_TECH_UZIVATEL_JMENO: 'konfigurace.config.set.tech.uzivatel.jmeno',
    KONFIGURACE_CONFIG_SET_TECH_UZIVATEL_HESLO: 'konfigurace.config.set.tech.uzivatel.heslo',
    KONFIGURACE_CONFIG_SET_DUVODY_ODECTU_HISTORIE: 'konfigurace.config.set.duvody.odectu.historie',
    KONFIGURACE_CONFIG_SET_DISTRIBUCNI_SAZBY_ELEKTRINA: 'konfigurace.config.set.distribucni.sazby.elektrina',
    KONFIGURACE_CONFIG_SET_JEDNOTKY_MERENI: 'konfigurace.config.set.jednotky.mereni',
    KONFIGURACE_CONFIG_SET_MATERIALY_PRISTROJU_SMARAGD_ELEKTRINA: 'konfigurace.config.set.materialy.pristroju.smaragd.elektrina',
    KONFIGURACE_CONFIG_SET_TYPY_DIAGRAMU_SMARAGD_ELEKTRINA: 'konfigurace.config.set.typy.diagramu.smaragd.elektrina',
    KONFIGURACE_CONFIG_SET_ROLE_PROFILU_ELEKTRINA_DODAVKA: 'konfigurace.config.set.role.profilu.elektrina.dodavka',
    KONFIGURACE_CONFIG_SET_ROLE_PROFILU_ELEKTRINA_SPOTREBA: 'konfigurace.config.set.role.profilu.elektrina.spotreba',
    KONFIGURACE_CONFIG_SET_ROLE_PROFILU_PLYN_SPOTREBA: 'konfigurace.config.set.role.profilu.plyn.spotreba',
    KONFIGURACE_CONFIG_SET_TYPY_DIAGRAMU_HISTORIE_SPOTREB_ELEKTRINA: 'konfigurace.config.set.typy.diagramu.historie.spotreb.elektrina',
    KONFIGURACE_CONFIG_SET_CISELNIK_STATUSU_ELEKTRINA: 'konfigurace.config.set.ciselnik.statusu.elektrina',
    KONFIGURACE_CONFIG_SET_CISELNIK_STATUSU_ELEKTRINA_TYP: 'konfigurace.config.set.ciselnik.statusu.elektrina.typ',
    KONFIGURACE_CONFIG_SET_CISELNIK_STATUSU_ELEKTRINA_TYP_FINALNI: 'konfigurace.config.set.ciselnik.statusu.elektrina.typ.FINALNI',
    KONFIGURACE_CONFIG_SET_CISELNIK_STATUSU_ELEKTRINA_TYP_NAHRADNI: 'konfigurace.config.set.ciselnik.statusu.elektrina.typ.NAHRADNI',
    KONFIGURACE_CONFIG_SET_CISELNIK_STATUSU_ELEKTRINA_TYP_CHYBI: 'konfigurace.config.set.ciselnik.statusu.elektrina.typ.CHYBI',
    KONFIGURACE_CONFIG_SET_TYPY_DIAGRAMU_HISTORIE_SPOTREB_PLYN: 'konfigurace.config.set.typy.diagramu.historie.spotreb.plyn',
    KONFIGURACE_CONFIG_SET_TYPY_TARIFU_ELEKTRINA: 'konfigurace.config.set.typy.tarifu.elektrina',
    KONFIGURACE_CONFIG_SET_ODECTY: 'konfigurace.config.set.odecty',
    KONFIGURACE_CONFIG_SET_REGISTRACE: 'konfigurace.config.set.registrace',
    KONFIGURACE_CONFIG_SET_DUVODY_ODECTU_SAMOODECET_ELEKTRINA: 'konfigurace.config.set.duvody.odectu.samoodecet.elektrina',
    KONFIGURACE_CONFIG_SET_DUVODY_ODECTU_SAMOODECET_PLYN: 'konfigurace.config.set.duvody.odectu.samoodecet.plyn',
    KONFIGURACE_CONFIG_SET_REKLAMACE: 'konfigurace.config.set.reklamace',
    KONFIGURACE_CONFIG_SET_REKLAMACE_DUVODY_ELEKTRINA: 'konfigurace.config.set.reklamace.duvody.elektrina',
    KONFIGURACE_CONFIG_SET_REKLAMACE_DUVODY_PLYN: 'konfigurace.config.set.reklamace.duvody.plyn',
    KONFIGURACE_CONFIG_SET_REKLAMACE_TEXTACE: 'konfigurace.config.set.reklamace.textace',
    KONFIGURACE_CONFIG_SET_KONTAKTY: 'konfigurace.config.set.kontakty',
    KONFIGURACE_CONFIG_SET_KONTAKTY_SKUPINY_NOTIFIKACI: 'konfigurace.config.set.kontakty.skupiny.notifikaci',
    KONFIGURACE_CONFIG_SET_KONTAKTY_KATEGORIE_NOTIFIKACI: 'konfigurace.config.set.kontakty.kategorie.notifikaci',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE: 'konfigurace.config.set.administrace',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_VYCHOZI_NASTAVENI_UCTU: 'konfigurace.config.set.administrace.vychozi.nastaveni.uctu',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_DANOVE_DOKLADY: 'konfigurace.config.set.administrace.danove.doklady',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_ODPLOMBOVANI_DUVODY: 'konfigurace.config.set.administrace.odplombovani.duvody',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_NAPOVEDA_NASTAVENI_OM: 'konfigurace.config.set.administrace.napoveda.nastaveni.om',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_NAPOVEDA_NASTAVENI_MERENI: 'konfigurace.config.set.administrace.napoveda.nastaveni.mereni',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_POVOLENE_TYPY_PRILOH: 'konfigurace.config.set.administrace.povolene.typy.priloh',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_CERVENE_TLACITKO: 'konfigurace.config.set.administrace.cervene.tlacitko',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI_NOVY_ODBER_ELEKTRINA: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni.novy.odber.elektrina',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI_UPRAVA_NOVY_ODBER_ELEKTRINA: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni.uprava.novy.odber.elektrina',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI_NOVY_ODBER_PLYN: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni.novy.odber.plyn',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI_UPRAVA_NOVY_ODBER_PLYN: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni.uprava.novy.odber.plyn',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI_ZMENA_ODBERU: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni.zmena.odberu',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_NASTAVENI_UPRAVA_ZMENA_ODBERU: 'konfigurace.config.set.administrace.sopweb.formulare.nastaveni.uprava.zmena.odberu',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_POVOLEN: 'konfigurace.config.set.administrace.sopweb.formulare.povolen',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_ODSTAVIT: 'konfigurace.config.set.administrace.sopweb.formulare.odstavit',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_ZAKAZAN: 'konfigurace.config.set.administrace.sopweb.formulare.zakazan',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_ZAKAZAN_DESCRIPTION: 'konfigurace.config.set.administrace.sopweb.formulare.zakazan.description',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_PPP_FORMULARE_ZAKAZAN_DESCRIPTION: 'konfigurace.config.set.administrace.ppp.formulare.zakazan.description',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SOPWEB_FORMULARE_ZAKAZAN_HTML: 'konfigurace.config.set.administrace.sopweb.formulare.zakazan.html',
    KONFIGURACE_CONFIG_SET_ADMINISTRACE_SELFCARE_NASTENKA: 'konfigurace.config.set.administrace.selfcare.nastenka',
    KONFIGURACE_CONFIG_SET_UPRAVA_NASTENKA_OBCHODNIK: 'konfigurace.config.set.uprava.nastenka.obchodnik',
    KONFIGURACE_CONFIG_SET_UPRAVA_NASTENKA_ZAKAZNIK: 'konfigurace.config.set.uprava.nastenka.zakaznik',
    KONFIGURACE_CONFIG_SET_UPRAVA_NASTENKA_ZADATEL: 'konfigurace.config.set.uprava.nastenka.zadatel',
    KONFIGURACE_CONFIG_SET_UPRAVA_NASTENKA_MUNICIPALITA: 'konfigurace.config.set.uprava.nastenka.municipalita',
    KONFIGURACE_CONFIG_SET_UPRAVA_NASTENKA_INVESTICNI_STAVBY: 'konfigurace.config.set.uprava.nastenka.investicni.stavby',
    KONFIGURACE_CONFIG_SET_UPRAVA_NASTENKA_SPP: 'konfigurace.config.set.uprava.nastenka.spp',
    KONFIGURACE_CONFIG_SET_UPRAVA_INVESTICNI_STAVBY_UZIVATEL: 'konfigurace.config.set.uprava.investicni.stavby.uzivatel',
    KONFIGURACE_CONFIG_SET_UPRAVA_REGISTRACE_MUNICIPALITA: 'konfigurace.config.set.uprava.registrace.municipalita',
    KONFIGURACE_CONFIG_SET_UPRAVA_REGISTRACE_ZAKAZNIK: 'konfigurace.config.set.uprava.registrace.zakaznik',
    KONFIGURACE_CONFIG_SET_UPRAVA_REGISTRACE_OBCHODNIK: 'konfigurace.config.set.uprava.registrace.obchodnik',
    KONFIGURACE_CONFIG_SET_UPRAVA_REGISTRACE_ZADATEL: 'konfigurace.config.set.uprava.registrace.zadatel',
    KONFIGURACE_CONFIG_SET_UPRAVA_REGISTRACE_ZAKLADNI: 'konfigurace.config.set.uprava.registrace.zakladni',
    KONFIGURACE_CONFIG_SET_ZADOSTI: 'konfigurace.config.set.zadosti',
    KONFIGURACE_CONFIG_SET_ZADOSTI_TEXTACE: 'konfigurace.config.set.zadosti.textace',
    KONFIGURACE_CONFIG_SET_NAPOVEDA_ULOZENA: 'konfigurace.config.set.napoveda.ulozena',
    KONFIGURACE_CONFIG_SET_FULLTEXT: 'konfigurace.config.set.fulltext',
    KONFIGURACE_CONFIG_SET_FILTROVAT: 'konfigurace.config.set.filtrovat',
    KONFIGURACE_CONFIG_SET_UPRAVA_NAPOVEDA: 'konfigurace.config.set.uprava.napoveda',
    KONFIGURACE_CONFIG_SET_PORADI: 'konfigurace.config.set.poradi',
    KONFIGURACE_CONFIG_SET_KLIC: 'konfigurace.config.set.klic',
    KONFIGURACE_CONFIG_SET_KOD: 'konfigurace.config.set.kod',
    KONFIGURACE_CONFIG_SET_HODNOTA: 'konfigurace.config.set.hodnota',
    KONFIGURACE_CONFIG_SET_TOOLTIP: 'konfigurace.config.set.tooltip',
    KONFIGURACE_CONFIG_SET_DEFAULT: 'konfigurace.config.set.default',
    KONFIGURACE_CONFIG_SET_NAZEV: 'konfigurace.config.set.nazev',
    KONFIGURACE_CONFIG_SET_POPIS: 'konfigurace.config.set.popis',
    KONFIGURACE_CONFIG_SET_MERNA_JEDNOTKA: 'konfigurace.config.set.merna.jednotka',
    KONFIGURACE_CONFIG_SET_ZDROJ_DAT: 'konfigurace.config.set.zdroj.dat',
    KONFIGURACE_CONFIG_SET_ZOBRAZIT_PRO: 'konfigurace.config.set.zobrazit.pro',
    KONFIGURACE_CONFIG_SET_ZOBRAZIT_PRO_PRISTROJ_A_B: 'konfigurace.config.set.zobrazit.pro.PRISTROJ_A_B',
    KONFIGURACE_CONFIG_SET_ZOBRAZIT_PRO_PRISTROJ_C1_C2_C3: 'konfigurace.config.set.zobrazit.pro.PRISTROJ_C1_C2_C3',
    KONFIGURACE_CONFIG_SET_ZOBRAZIT_PRO_SOUCTOVY_PROFIL: 'konfigurace.config.set.zobrazit.pro.SOUCTOVY_PROFIL',
    KONFIGURACE_CONFIG_SET_VYCHOZI_HODNOTA: 'konfigurace.config.set.vychozi.hodnota',
    KONFIGURACE_CONFIG_SET_TYP_TARIFU: 'konfigurace.config.set.typ.tarifu',
    KONFIGURACE_CONFIG_SET_SPOTREBICE_REVIZNI_ZPRAVA: 'konfigurace.config.set.spotrebice.revizni.zprava',
    KONFIGURACE_CONFIG_SET_TYPY_PRILOH_REVIZNI_ZPRAVA: 'konfigurace.config.set.typy.priloh.revizni.zprava',
    KONFIGURACE_CONFIG_SET_MAD: 'konfigurace.config.set.mad',
    KONFIGURACE_CONFIG_SET_IDM: 'konfigurace.config.set.idm',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE: 'konfigurace.config.set.idm.aplikace',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_DETAIL: 'konfigurace.config.set.idm.aplikace.detail',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_VYHLEDAVANI: 'konfigurace.config.set.idm.aplikace.vyhledavani',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_NAZEV: 'konfigurace.config.set.idm.aplikace.nazev',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_GRANT_TYPE: 'konfigurace.config.set.idm.aplikace.grant.type',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_GRANT_TYPE_CLIENT_CREDENTIALS: 'konfigurace.config.set.idm.aplikace.grant.type.CLIENT_CREDENTIALS',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_GRANT_TYPE_AUTHORIZATION_CODE: 'konfigurace.config.set.idm.aplikace.grant.type.AUTHORIZATION_CODE',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_CLIENT_ID: 'konfigurace.config.set.idm.aplikace.client.id',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_CLIENT_SECRET: 'konfigurace.config.set.idm.aplikace.client.secret',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE: 'konfigurace.config.set.idm.aplikace.scope',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_VSE: 'konfigurace.config.set.idm.aplikace.scope.vse',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_POVINNY: 'konfigurace.config.set.idm.aplikace.scope.povinny',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_SELFCARE: 'konfigurace.config.set.idm.aplikace.scope.SELFCARE',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_SPP: 'konfigurace.config.set.idm.aplikace.scope.SPP',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_SPP_OPENAPI: 'konfigurace.config.set.idm.aplikace.scope.SPP_OPENAPI',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_VYJADROVACKY: 'konfigurace.config.set.idm.aplikace.scope.VYJADROVACKY',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_VYJADROVACKY_WEB: 'konfigurace.config.set.idm.aplikace.scope.VYJADROVACKY_WEB',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_SCOPE_NAMERENA_DATA_OPENAPI: 'konfigurace.config.set.idm.aplikace.scope.NAMERENA_DATA_OPENAPI',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_INACTIVITYHRS: 'konfigurace.config.set.idm.aplikace.inactivityHrs',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_INACTIVITYHRS_CHECK: 'konfigurace.config.set.idm.aplikace.inactivityHrs.check',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_INACTIVITYHRS_ERROR: 'konfigurace.config.set.idm.aplikace.inactivityHrs.error',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_REFRESHTOKEN_POVOLIT: 'konfigurace.config.set.idm.aplikace.refreshToken.povolit',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_REFRESHTOKEN_PLATNOST: 'konfigurace.config.set.idm.aplikace.refreshToken.platnost',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_REFRESHTOKEN_ERROR: 'konfigurace.config.set.idm.aplikace.refreshToken.error',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_REDIRECT_URIS: 'konfigurace.config.set.idm.aplikace.redirect.uris',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_POUZE_AKTIVNI: 'konfigurace.config.set.idm.aplikace.pouze.aktivni',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_AKTIVNI: 'konfigurace.config.set.idm.aplikace.aktivni',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_VYTVORENO: 'konfigurace.config.set.idm.aplikace.vytvoreno',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_ZMENENO: 'konfigurace.config.set.idm.aplikace.zmeneno',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_VYTVORIL: 'konfigurace.config.set.idm.aplikace.vytvoril',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_ZMENIL: 'konfigurace.config.set.idm.aplikace.zmenil',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_URI_JEDEN: 'konfigurace.config.set.idm.aplikace.uri.jeden',
    KONFIGURACE_CONFIG_SET_IDM_APLIKACE_URI_POVINNE: 'konfigurace.config.set.idm.aplikace.uri.povinne',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_VYHLEDAVANI: 'konfigurace.config.set.idm.token.vyhledavani',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN: 'konfigurace.config.set.idm.token',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_TYP: 'konfigurace.config.set.idm.token.typ',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_TYP_ACCESS: 'konfigurace.config.set.idm.token.typ.access',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_TYP_REFRESH: 'konfigurace.config.set.idm.token.typ.refresh',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_APLIKACE: 'konfigurace.config.set.idm.token.aplikace',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_ODVOLAT: 'konfigurace.config.set.idm.token.odvolat',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_UCET: 'konfigurace.config.set.idm.token.ucet',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_UCET_FULLTEXT: 'konfigurace.config.set.idm.token.ucet.fulltext',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_ODVOLANY: 'konfigurace.config.set.idm.token.odvolany',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_SKUPINA: 'konfigurace.config.set.idm.token.skupina',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_UCET_JMENO_PRIJMENI: 'konfigurace.config.set.idm.token.ucet.jmeno.prijmeni',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_UCET_EMAIL: 'konfigurace.config.set.idm.token.ucet.email',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_VYTVORENO: 'konfigurace.config.set.idm.token.vytvoreno',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_ZMENENO: 'konfigurace.config.set.idm.token.zmeneno',
    KONFIGURACE_CONFIG_SET_IDM_TOKEN_ZMENIL: 'konfigurace.config.set.idm.token.zmenil',
    KONFIGURACE_CONFIG_SET_IDM_TOKENY: 'konfigurace.config.set.idm.tokeny',
    KONFIGURACE_CONFIG_NEEXISTUJE_NAPOVEDA: 'konfigurace.config.neexistuje.napoveda',
    KONFIGURACE_CONFIG_NASTAVENI_ULOZENO: 'konfigurace.config.nastaveni.ulozeno',
    KONFIGURACE_CONFIG_VYCHOZI_STRANKOVANI: 'konfigurace.config.vychozi.strankovani',
    KONFIGURACE_CONFIG_VYCHOZI_TEMA: 'konfigurace.config.vychozi.tema',
    KONFIGURACE_CONFIG_NASTAVENI_SVETLE: 'konfigurace.config.nastaveni.svetle',
    KONFIGURACE_CONFIG_NASTAVENI_TMAVE: 'konfigurace.config.nastaveni.tmave',
    KONFIGURACE_CONFIG_NASTAVENI_ULOZIT: 'konfigurace.config.nastaveni.ulozit',
    KONFIGURACE_CONFIG_VYCHOZI_NASTAVENI_UCTU: 'konfigurace.config.vychozi.nastaveni.uctu',
    KONFIGURACE_CONFIG_SET_ZAHRNOUT_NEAKTIVNI: 'konfigurace.config.set.zahrnout.neaktivni',
    KONFIGURACE_CONFIG_SET_UPRAVIT: 'konfigurace.config.set.upravit',
    KONFIGURACE_CONFIG_SET_SKRYT: 'konfigurace.config.set.skryt',
    KONFIGURACE_CONFIG_SET_ZOBRAZIT_SKUPINE: 'konfigurace.config.set.zobrazit.skupine',
    KONFIGURACE_CONFIG_SET_ZOBRAZIT_APLIKACI: 'konfigurace.config.set.zobrazit.aplikaci',
    KONFIGURACE_CONFIG_SET_APLIKACE_WEB: 'konfigurace.config.set.aplikace.web',
    KONFIGURACE_CONFIG_SET_APLIKACE_SELFCARE: 'konfigurace.config.set.aplikace.selfcare',
    KONFIGURACE_CONFIG_SET_PROFILY_SPOTREBY: 'konfigurace.config.set.profily.spotreby',
    KONFIGURACE_CONFIG_SET_PROFILY_DODAVEK: 'konfigurace.config.set.profily.dodavek',
    KONFIGURACE_CONFIG_SET_OBNOVIT: 'konfigurace.config.set.obnovit',
    KONFIGURACE_CONFIG_SET_NOVA_HODNOTA: 'konfigurace.config.set.nova.hodnota',
    KONFIGURACE_CONFIG_SET_DATUM_ZAPLOMBOVANI: 'konfigurace.config.set.datum.zaplombovani',
    KONFIGURACE_CONFIG_SET_UPRAVA_HODNOTY: 'konfigurace.config.set.uprava.hodnoty',
    KONFIGURACE_CONFIG_SET_REVIZNI_ZPRAVA: 'konfigurace.config.set.revizni.zprava',
    KONFIGURACE_CONFIG_SET_RELEVANTNI_SOP_TRVALY: 'konfigurace.config.set.relevantni.sop.trvaly',
    KONFIGURACE_CONFIG_SET_RELEVANTNI_SOP_KRATKODOBY: 'konfigurace.config.set.relevantni.sop.kratkodoby',
    KONFIGURACE_CONFIG_SET_REVIZNI_ZPRAVY: 'konfigurace.config.set.revizni.zpravy',
    KONFIGURACE_CONFIG_SET_REVIZNI_ZPRAVY_TEXTACE: 'konfigurace.config.set.revizni.zpravy.textace',
    KONFIGURACE_CONFIG_VYCHOZI_POLOZKA: 'konfigurace.config.vychozi.polozka',
    KONFIGURACE_CONFIG_VYCHOZI_HODNOTA: 'konfigurace.config.vychozi.hodnota',
    KONFIGURACE_CONFIG_DUVODY_REKLAMACE: 'konfigurace.config.duvody.reklamace',
    KONFIGURACE_CONFIG_DRUH_PARTNERA: 'konfigurace.config.druh.partnera',
    KONFIGURACE_CONFIG_NOTIFIKACE_KATEGORIE: 'konfigurace.config.notifikace.kategorie',
    KONFIGURACE_CONFIG_NOTIFIKACE_VYBER_DRUH_KOMUNIKACE: 'konfigurace.config.notifikace.vyber.druh.komunikace',
    KONFIGURACE_CONFIG_NOTIFIKACE_ADRESA_EAN: 'konfigurace.config.notifikace.adresa.ean',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLENE_DRUHY_KOMUNIKACE: 'konfigurace.config.notifikace.povolene.druhy.komunikace',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_EAN: 'konfigurace.config.notifikace.povolit.vyber.ean',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_EIC: 'konfigurace.config.notifikace.povolit.vyber.eic',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_KOMODITY: 'konfigurace.config.notifikace.povolit.vyber.komodity',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_SKUPINY: 'konfigurace.config.notifikace.povolit.vyber.skupiny',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_STATUS: 'konfigurace.config.notifikace.povolit.vyber.status',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_APLIKACE: 'konfigurace.config.notifikace.povolit.vyber.aplikace',
    KONFIGURACE_CONFIG_NOTIFIKACE_ZOBRAZIT_SKUPINE: 'konfigurace.config.notifikace.zobrazit.skupine',
    KONFIGURACE_CONFIG_NOTIFIKACE_ZOBRAZIT_APLIKACI: 'konfigurace.config.notifikace.zobrazit.aplikaci',
    KONFIGURACE_CONFIG_NOTIFIKACE_SKUPINY_NOTIFIKACI: 'konfigurace.config.notifikace.skupiny.notifikaci',
    KONFIGURACE_CONFIG_NOTIFIKACE_DUVODY_ODECTU_ELEKTRINA: 'konfigurace.config.notifikace.duvody.odectu.elektrina',
    KONFIGURACE_CONFIG_NOTIFIKACE_DUVODY_ODECTU_PLYN: 'konfigurace.config.notifikace.duvody.odectu.plyn',
    KONFIGURACE_CONFIG_NOTIFIKACE_POVOLIT_VYBER_PROFIL: 'konfigurace.config.notifikace.povolit.vyber.profil',
    KONFIGURACE_CONFIG_NOTIFIKACE_PROFIL_SPOTREBY: 'konfigurace.config.notifikace.profil.spotreby',
    KONFIGURACE_CONFIG_NOTIFIKACE_JEDNOTKY_MERENI: 'konfigurace.config.notifikace.jednotky.mereni',
    KONFIGURACE_CONFIG_NOTIFIKACE_DUVODY_ODECTU_HISTORIE: 'konfigurace.config.notifikace.duvody.odectu.historie',
    KONFIGURACE_CONFIG_NOTIFIKACE_ROLE_PROFIL_ELEKTRINA: 'konfigurace.config.notifikace.role.profil.elektrina',
    KONFIGURACE_CONFIG_NOTIFIKACE_ROLE_PROFIL_ELEKTRINA_SPOTREBA: 'konfigurace.config.notifikace.role.profil.elektrina.spotreba',
    KONFIGURACE_CONFIG_NOTIFIKACE_ROLE_PROFIL_PLYN_SPOTREBA: 'konfigurace.config.notifikace.role.profil.plyn.spotreba',
    KONFIGURACE_CONFIG_NOTIFIKACE_VYBER_TYP_REKLAMACE: 'konfigurace.config.notifikace.vyber.typ.reklamace',
    KONFIGURACE_CONFIG_NOTIFIKACE_VYBER_TYP_DRUH_PARTNERA: 'konfigurace.config.notifikace.vyber.typ.druh.partnera',
    KONFIGURACE_CONFIG_NOTIFIKACE_DUVODY_REKLAMACE_ELEKTRINA: 'konfigurace.config.notifikace.duvody.reklamace.elektrina',
    KONFIGURACE_CONFIG_NOTIFIKACE_DUVODY_REKLAMACE_PLYN: 'konfigurace.config.notifikace.duvody.reklamace.plyn',
    KONFIGURACE_CONFIG_SKUPINA: 'konfigurace.config.skupina',
    KONFIGURACE_PRILOHY_TYP_OBJEKTU: 'konfigurace.prilohy.typ.objektu',
    KONFIGURACE_PRILOHY_POVOLENE_TYPY_PRILOH: 'konfigurace.prilohy.povolene.typy.priloh',
    KONFIGURACE_PRILOHY_ULOZENO: 'konfigurace.prilohy.ulozeno',
    KONFIGURACE_PRILOHY_EDIT_TITLE: 'konfigurace.prilohy.edit.title',
    KONTROLA_VERZE_APLIKACE_TITLE: 'kontrola.verze.aplikace.title',
    KONTROLA_VERZE_APLIKACE_DIALOG_TITLE: 'kontrola.verze.aplikace.dialog.title',
    KONTROLA_VERZE_APLIKACE_DIALOG_OBSOLETE_TEXTACE: 'kontrola.verze.aplikace.dialog.obsolete.textace',
    KONTROLA_VERZE_APLIKACE_DIALOG_OBSOLETE_VERZE: 'kontrola.verze.aplikace.dialog.obsolete.verze',
    KONTROLA_VERZE_APLIKACE_DIALOG_OBSOLETE_TITLE: 'kontrola.verze.aplikace.dialog.obsolete.title',
    KONTROLA_VERZE_APLIKACE_DIALOG_DEPRECATED_TEXTACE: 'kontrola.verze.aplikace.dialog.deprecated.textace',
    KONTROLA_VERZE_APLIKACE_DIALOG_DEPRECATED_VERZE: 'kontrola.verze.aplikace.dialog.deprecated.verze',
    KONTROLA_VERZE_APLIKACE_DIALOG_DEPRECATED_TITLE: 'kontrola.verze.aplikace.dialog.deprecated.title',
    KONTROLA_VERZE_APLIKACE_TABLE_DEVICE: 'kontrola.verze.aplikace.table.device',
    KONTROLA_VERZE_APLIKACE_TABLE_ERROR_TYPE: 'kontrola.verze.aplikace.table.error.type',
    KONTROLA_VERZE_APLIKACE_TABLE_VERSION: 'kontrola.verze.aplikace.table.version',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_SOP_ZMENA_JISTICE: 'priloha.typ.objektu.ZADOST_ELEKTRINA_SOP_ZMENA_JISTICE',
    PRILOHA_TYP_OBJEKTU_ZADOST_SOP_WEB: 'priloha.typ.objektu.ZADOST_SOP_WEB',
    PRILOHA_TYP_OBJEKTU_ZADOST_SOP_WEB_TS_ODBER: 'priloha.typ.objektu.ZADOST_SOP_WEB_TS_ODBER',
    PRILOHA_TYP_OBJEKTU_ZADOST_SOP_WEB_PREDCHOZI_ODBER: 'priloha.typ.objektu.ZADOST_SOP_WEB_PREDCHOZI_ODBER',
    PRILOHA_TYP_OBJEKTU_ZADOST_SOP_WEB_TS_VYROBNA: 'priloha.typ.objektu.ZADOST_SOP_WEB_TS_VYROBNA',
    PRILOHA_TYP_OBJEKTU_ZADOST_PLYN_REZERVACE_DISTRIBUCNI_KAPACITY: 'priloha.typ.objektu.ZADOST_PLYN_REZERVACE_DISTRIBUCNI_KAPACITY',
    PRILOHA_TYP_OBJEKTU_ZSOUHLAS: 'priloha.typ.objektu.ZSOUHLAS',
    PRILOHA_TYP_OBJEKTU_ZPD: 'priloha.typ.objektu.ZPD',
    PRILOHA_TYP_OBJEKTU_Z1PSCHEMA: 'priloha.typ.objektu.Z1PSCHEMA',
    PRILOHA_TYP_ZSOUHLAS: 'priloha.typ.ZSOUHLAS',
    PRILOHA_TYP_ZCA_PROHL: 'priloha.typ.ZCA_PROHL',
    PRILOHA_TYP_ZCA_CP: 'priloha.typ.ZCA_CP',
    PRILOHA_TYP_ZPD: 'priloha.typ.ZPD',
    PRILOHA_TYP_Z1PSCHEMA: 'priloha.typ.Z1PSCHEMA',
    PRILOHA_TYP_SOUHLAS_VLASTNIKA_NEMOVITOSTI: 'priloha.typ.SOUHLAS_VLASTNIKA_NEMOVITOSTI',
    PRILOHA_TYP_PROJEKTOVA_DOKUMENTACE: 'priloha.typ.PROJEKTOVA_DOKUMENTACE',
    PRILOHA_TYP_JEDNOPOLOVE_SCHEMA: 'priloha.typ.JEDNOPOLOVE_SCHEMA',
    KONFIGURACE_CONFIG_SET_EMPTY: 'konfigurace.config.set.empty',
    KONFIGURACE_CONFIG_SET_SKUPINY: 'konfigurace.config.set.skupiny',
    KONFIGURACE_CONFIG_SET_SKUPINA: 'konfigurace.config.set.skupina',
    KONFIGURACE_CONFIG_SET_TYP_REKLAMACE: 'konfigurace.config.set.typ.reklamace',
    KONFIGURACE_CONFIG_SET_DRUHY_KOMUNIKACE: 'konfigurace.config.set.druhy.komunikace',
    KONFIGURACE_CONFIG_SET_SOP_WEB: 'konfigurace.config.set.sop.web',
    KONFIGURACE_CONFIG_SET_SOP_WEB_DRUH_VYROBNY_ELEKTRINY: 'konfigurace.config.set.sop.web.druh.vyrobny.elektriny',
    KONFIGURACE_CONFIG_SET_SOP_WEB_DRUH_ODBERU_ELEKTRINA: 'konfigurace.config.set.sop.web.druh.odberu.elektrina',
    KONFIGURACE_CONFIG_SET_SOP_WEB_DRUH_ODBERU_ELEKTRINA_KRATKODOBY_ONLY: 'konfigurace.config.set.sop.web.druh.odberu.elektrina.kratkodoby.only',
    KONFIGURACE_CONFIG_SET_SOP_WEB_ELEKTRINA_SPOTREBIC_TYP: 'konfigurace.config.set.sop.web.elektrina.spotrebic.typ',
    KONFIGURACE_CONFIG_SET_SOP_WEB_ELEKTRINA_SPOTREBIC_TYP_KRATKODOBY: 'konfigurace.config.set.sop.web.elektrina.spotrebic.typ.kratkodoby',
    KONFIGURACE_CONFIG_SET_SOP_WEB_ELEKTRINA_SPOTREBIC_TYP_TRVALY: 'konfigurace.config.set.sop.web.elektrina.spotrebic.typ.trvaly',
    KONFIGURACE_CONFIG_SET_SOP_WEB_ELEKTRINA_SPOTREBIC_TYP_ODBERU: 'konfigurace.config.set.sop.web.elektrina.spotrebic.typ.odberu',
    KONFIGURACE_CONFIG_SET_SOP_WEB_ELEKTRINA_SPOTREBIC_TYP_VYBER: 'konfigurace.config.set.sop.web.elektrina.spotrebic.typ.vyber',
    KONFIGURACE_CONFIG_SET_SOP_WEB_PLYN_SPOTREBIC_TYP: 'konfigurace.config.set.sop.web.plyn.spotrebic.typ',
    KONFIGURACE_CONFIG_SET_SOP_WEB_ELEKTRINA_HODNOTA_JISTICE: 'konfigurace.config.set.sop.web.elektrina.hodnota.jistice',
    KONFIGURACE_CONFIG_SET_SOP_WEB_CHARAKTER_ODBERU_VARENI: 'konfigurace.config.set.sop.web.charakter.odberu.vareni',
    KONFIGURACE_CONFIG_SET_SOP_WEB_CHARAKTER_ODBERU_TUV: 'konfigurace.config.set.sop.web.charakter.odberu.tuv',
    KONFIGURACE_CONFIG_SET_SOP_WEB_CHARAKTER_ODBERU_VYTAPENI: 'konfigurace.config.set.sop.web.charakter.odberu.vytapeni',
    KONFIGURACE_CONFIG_SET_SOP_WEB_CHARAKTER_ODBERU_TECHNOLOGIE: 'konfigurace.config.set.sop.web.charakter.odberu.technologie',
    KONFIGURACE_CONFIG_SET_SOP_WEB_VYUZITI_ODBERNEHO_MISTA_PLYN: 'konfigurace.config.set.sop.web.vyuziti.odberneho.mista.plyn',
    KONFIGURACE_CONFIG_SET_SOP_WEB_KOMBINACE_FYZICKA_OSOBA: 'konfigurace.config.set.sop.web.kombinace.fyzicka.osoba',
    KONFIGURACE_CONFIG_SET_SOP_WEB_KOMBINACE_PRAVNICKA_OSOBA: 'konfigurace.config.set.sop.web.kombinace.pravnicka.osoba',
    KONFIGURACE_CONFIG_SET_SOP_WEB_POCET_FAZI_JEDNOFAZE: 'konfigurace.config.set.sop.web.pocet.fazi.jednofaze',
    KONFIGURACE_CONFIG_SET_SOP_WEB_POCET_FAZI_TRIFAZE: 'konfigurace.config.set.sop.web.pocet.fazi.trifaze',
    KONFIGURACE_CONFIG_SET_SOP_WEB_POCET_FAZI_POVINNE_POLE: 'konfigurace.config.set.sop.web.pocet.fazi.povinne.pole',
    KONFIGURACE_CONFIG_VYBER_JEDEN_PROFIL: 'konfigurace.config.vyber.jeden.profil',
    KONFIGURACE_CONFIG_LOGIN_STAV: 'konfigurace.config.login.stav',
    KONFIGURACE_CONFIG_LOGIN_STAV_POVOLEN: 'konfigurace.config.login.stav.povolen',
    KONFIGURACE_CONFIG_LOGIN_STAV_ZAKAZAN: 'konfigurace.config.login.stav.zakazan',
    KONFIGURACE_CONFIG_LOGIN_ZAKAZAN: 'konfigurace.config.login.zakazan',
    KONFIGURACE_CONFIG_LOGIN_ZAKAZAN_HTML: 'konfigurace.config.login.zakazan.html',
    KONFIGURACE_CONFIG_LOGIN_ZAKAZAN_DESCRIPTION: 'konfigurace.config.login.zakazan.description',
    KONFIGURACE_CONFIG_LOGIN_ZAKAZAN_VAROVANI: 'konfigurace.config.login.zakazan.varovani',
    KONFIGURACE_CONFIG_ODHLASIT_PRIHLASENE_VAROVANI: 'konfigurace.config.odhlasit.prihlasene.varovani',
    KONFIGURACE_CONFIG_ODHLASIT_PRIHLASENE: 'konfigurace.config.odhlasit.prihlasene',
    KONFIGURACE_CONFIG_LOGIN_ZAKAZAN_INFO: 'konfigurace.config.login.zakazan.info',
    KONFIGURACE_CONFIG_NASTENKA_NADPIS: 'konfigurace.config.nastenka.nadpis',
    KONFIGURACE_CONFIG_NASTENKA_HTML: 'konfigurace.config.nastenka.html',
    ROLE_OPRAVNENI_NAZEV_ROLE: 'role.opravneni.nazev.role',
    ROLE_OPRAVNENI_AKTIVNI: 'role.opravneni.aktivni',
    ROLE_OPRAVNENI_NEAKTIVNI: 'role.opravneni.neaktivni',
    ROLE_OPRAVNENI_STAV: 'role.opravneni.stav',
    ROLE_OPRAVNENI_NAZEV: 'role.opravneni.nazev',
    ROLE_OPRAVNENI_PRIJMENI_JMENO: 'role.opravneni.prijmeni.jmeno',
    ROLE_OPRAVNENI_EMAIL: 'role.opravneni.email',
    ROLE_OPRAVNENI_OPRAVNENI: 'role.opravneni.opravneni',
    ROLE_OPRAVNENI_OPRAVNENI_PRIDELENA: 'role.opravneni.opravneni.pridelena',
    ROLE_OPRAVNENI_ADMINISTRATORI: 'role.opravneni.administratori',
    ROLE_OPRAVNENI_ADMINISTRATORI_FORM: 'role.opravneni.administratori.form',
    ROLE_OPRAVNENI_VYTVORENO: 'role.opravneni.vytvoreno',
    ROLE_OPRAVNENI: 'role.opravneni',
    ROLE_OPRAVNENI_FORM_ADMINISTRATORI_POVINNE: 'role.opravneni.form.administratori.povinne',
    ROLE_OPRAVNENI_FORM_ROLE_POVINNE: 'role.opravneni.form.role.povinne',
    ROLE: 'role',
    ROLE_OPRAVNENI_FORM_OPRAVNENI_ERROR: 'role.opravneni.form.opravneni.error',
    ROLE_OPRAVNENI_JEDEN: 'role.opravneni.jeden',
    ROLE_OPRAVNENI_ODEBRAT_ROLI_CONFIRM_MESSAGE: 'role.opravneni.odebrat.roli.confirm.message',
    ROLE_OPRAVNENI_DEAKTIVOVAT_ROLI_CONFIRM_MESSAGE: 'role.opravneni.deaktivovat.roli.confirm.message',
    ROLE_OPRAVNENI_SMAZAT_ROLI_CONFIRM_MESSAGE: 'role.opravneni.smazat.roli.confirm.message',
    ROLE_OPRAVNENI_SEZNAM_ADMINISTRATORI: 'role.opravneni.seznam.administratori',
    ROLE_OPRAVNENI_AKCE_PRIDAT: 'role.opravneni.akce.pridat',
    ROLE_OPRAVNENI_AKCE_UPRAVIT: 'role.opravneni.akce.upravit',
    ROLE_OPRAVNENI_DETAIL: 'role.opravneni.detail',
    ROLE_OPRAVNENI_AKCE_DEAKTIVOVAT: 'role.opravneni.akce.deaktivovat',
    ROLE_OPRAVNENI_AKCE_AKTIVOVAT: 'role.opravneni.akce.aktivovat',
    ROLE_OPRAVNENI_AKCE_SMAZAT: 'role.opravneni.akce.smazat',
    ROLE_OPRAVNENI_ZALOZIT: 'role.opravneni.zalozit',
    ROLE_OPRAVNENI_PRIDELIT: 'role.opravneni.pridelit',
    ROLE_OPRAVNENI_ODEBRAT: 'role.opravneni.odebrat',
    ROLE_OPRAVNENI_ULOZIT: 'role.opravneni.ulozit',
    ROLE_OPRAVNENI_KOD: 'role.opravneni.kod',
    ROLE_OPRAVNENI_POPIS: 'role.opravneni.popis',
    ROLE_OPRAVNENI_CONFIRM_UPDATE_DIALOG: 'role.opravneni.confirm.update.dialog',
    ROLE_OPRAVNENI_CONFIRM_CREATE_DIALOG: 'role.opravneni.confirm.create.dialog',
    ROLE_OPRAVNENI_CREATE_DIALOG: 'role.opravneni.create.dialog',
    ROLE_OPRAVNENI_UPDATE_DIALOG: 'role.opravneni.update.dialog',
    ROLE_OPRAVNENI_OPRAVNENI_LIST: 'role.opravneni.opravneni.list',
    ROLE_OPRAVNENI_OPRAVNENI_LIST_WARNING: 'role.opravneni.opravneni.list.warning',
    LAYOUT_NASTENKA: 'layout.nastenka',
    LAYOUT_SKUPINY_UCTY: 'layout.skupiny.ucty',
    LAYOUT_UCTY: 'layout.ucty',
    LAYOUT_ADMINISTRATORI: 'layout.administratori',
    LAYOUT_POZADAVKY: 'layout.pozadavky',
    LAYOUT_ROLE_A_OPRAVNENI: 'layout.role.a.opravneni',
    LAYOUT_REPORTY: 'layout.reporty',
    LAYOUT_NEOCEKAVANA_CHYBA: 'layout.neocekavana.chyba',
    LAYOUT_UCTY_ODSTAVKY: 'layout.ucty.odstavky',
    LAYOUT_KONFIGURACE: 'layout.konfigurace',
    LAYOUT_ODHLASIT: 'layout.odhlasit',
    LAYOUT_PROFIL: 'layout.profil',
    LAYOUT_TITLE_KONFIGURACE: 'layout.title.konfigurace',
    LAYOUT_TITLE_NASTENKA: 'layout.title.nastenka',
    LAYOUT_TITLE_NOVINKY: 'layout.title.novinky',
    LAYOUT_TITLE_DETAIL_SKUPINY: 'layout.title.detail.skupiny',
    LAYOUT_TITLE_SKUPINY: 'layout.title.skupiny',
    LAYOUT_TITLE_DETAIL_UCTU: 'layout.title.detail.uctu',
    LAYOUT_TITLE_UCTY: 'layout.title.ucty',
    LAYOUT_TITLE_DETAIL_ADMINISTRATORA: 'layout.title.detail.administratora',
    LAYOUT_TITLE_ADMINISTRATORI: 'layout.title.administratori',
    LAYOUT_TITLE_DETAIL_ODSTAVKY_UCTY: 'layout.title.detail.odstavky.ucty',
    LAYOUT_TITLE_ODSTAVKY_UCTY: 'layout.title.odstavky.ucty',
    LAYOUT_TITLE_REPORTY: 'layout.title.reporty',
    LAYOUT_TITLE_DETAIL_UCTU_POZADAVKY: 'layout.title.detail.uctu.pozadavky',
    LAYOUT_TITLE_DETAIL_POZADAVKU: 'layout.title.detail.pozadavku',
    LAYOUT_TITLE_POZADAVKY: 'layout.title.pozadavky',
    LAYOUT_TITLE_HDO_IMPORT: 'layout.title.hdo.import',
    LAYOUT_TITLE_STAHOVANI_ODSTAVEK: 'layout.title.stahovani.odstavek',
    LAYOUT_TITLE_SPRAVA_KONTAKTU_DISTRIBUTORA: 'layout.title.sprava.kontaktu.distributora',
    LAYOUT_TITLE_REGISTR_ADRES_IMPORT: 'layout.title.registr.adres.import',
    LAYOUT_TITLE_ROLE: 'layout.title.role',
    REQUEST_ZADNI_ADMINISTRATORI: 'request.zadni.administratori',
    REQUEST_DATUM_NAROZENI: 'request.datum.narozeni',
    REQUEST_IC: 'request.ic',
    REQUEST_EAN: 'request.ean',
    REQUEST_CISLO_ELEKTROMERU: 'request.cislo.elektromeru',
    REQUEST_EIC: 'request.eic',
    REQUEST_CISLO_PLYNOMERU: 'request.cislo.plynomeru',
    REQUEST_OBEC: 'request.obec',
    REQUEST_NAZEV_FIRMY: 'request.nazev.firmy',
    REQUEST_DIC: 'request.dic',
    REQUEST_RUT: 'request.rut',
    REQUEST_KOMODITY: 'request.komodity',
    REQUEST_EAN_OBCHODNIKA: 'request.ean.obchodnika',
    REQUEST_EIC_OBCHODNIKA: 'request.eic.obchodnika',
    REQUEST_TYP_ZADATELE: 'request.typ.zadatele',
    REQUEST_JMENO_PRIJMENI: 'request.jmeno.prijmeni',
    REQUEST_KOMODITA: 'request.komodita',
    REQUEST_VARIABILNI_SYMBOL: 'request.variabilni.symbol',
    REQUEST_JMENO: 'request.jmeno',
    REQUEST_PRIJMENI: 'request.prijmeni',
    REQUEST_EMAIL: 'request.email',
    REQUEST_DATA: 'request.data',
    REQUEST_PHONE: 'request.phone',
    REQUEST_TYP: 'request.typ',
    REQUEST_TYP_ZAKAZNIKA: 'request.typ.zakaznika',
    REQUEST_UPRAVA_DAT_UZIVATELE: 'request.uprava.dat.uzivatele',
    REQUEST_UPRAVA_REGISTRACNICH_DAT: 'request.uprava.registracnich.dat',
    REQUEST_NEVYPLNENO: 'request.nevyplneno',
    REQUEST_TYP_SUBJEKTU: 'request.typ.subjektu',
    REQUEST_ELEKTRINA: 'request.elektrina',
    REQUEST_PLYN: 'request.plyn',
    REQUEST_DALSI_ADMINISTRATORI: 'request.dalsi.administratori',
    REQUEST_FO: 'request.fo',
    REQUEST_PO: 'request.po',
    REQUEST_ZAREGISTROVAN: 'request.zaregistrovan',
    REQUEST_NAZEV: 'request.nazev',
    REQUEST_STAV: 'request.stav',
    REQUEST_VYTVORENO: 'request.vytvoreno',
    REQUEST_AKTUALIZOVANO: 'request.aktualizovano',
    REQUEST_CISLO: 'request.cislo',
    REQUEST_NEVYHOVUJE_ZADNY_SUBJEKT: 'request.nevyhovuje.zadny.subjekt',
    REQUEST_UZIVATEL: 'request.uzivatel',
    REQUEST_DATUM_VYTVORENI: 'request.datum.vytvoreni',
    REQUEST_POZADAVEK_TELEFON: 'request.pozadavek.telefon',
    REQUEST_DETAIL: 'request.detail',
    REQUEST_VYTVORIT_REGISTRACNI_POZADAVEK: 'request.vytvorit.registracni.pozadavek',
    REQUEST_NEW_ACCOUNT_AUTOCOMPLETE_PLACEHOLDER: 'request.new.account.autocomplete.placeholder',
    REQUEST_KOMENTARE: 'request.komentare',
    REQUEST_PRILOHY: 'request.prilohy',
    REQUEST_REGISTRACNI_DATA_ULOZENA: 'request.registracni.data.ulozena',
    REQUEST_REGISTRACNI_DATA: 'request.registracni.data',
    REQUEST_POPIS: 'request.popis',
    REQUEST_LIDE: 'request.lide',
    REQUEST_PRIRAZENY_UZIVATEL: 'request.prirazeny.uzivatel',
    REQUEST_PRIRAZENY_ADMINISTRATOR: 'request.prirazeny.administrator',
    REQUEST_VYTVORIL: 'request.vytvoril',
    REQUEST_DATUMY: 'request.datumy',
    REQUEST_ZMENENO: 'request.zmeneno',
    REQUEST_UPRAVIT_POZADAVEK: 'request.upravit.pozadavek',
    REQUEST_STAVY: 'request.stavy',
    REQUEST_FULLTEXT: 'request.fulltext',
    REQUEST_PLACEHOLDER_NAZEV: 'request.placeholder.nazev',
    REQUEST_VSE: 'request.vse',
    REQUEST_ZADOST_REGISTRACE: 'request.zadost.registrace',
    REQUEST_OSTATNI: 'request.ostatni',
    REQUEST_CHYBA_SYSTEMU: 'request.chyba.systemu',
    REQUEST_NAVRH_VYLEPSENI: 'request.navrh.vylepseni',
    REQUEST_DATUM_AKTUALIZACE: 'request.datum.aktualizace',
    REQUEST_POZADAVEK_ULOZEN: 'request.pozadavek.ulozen',
    REQUEST_KOMENTAR: 'request.komentar',
    REQUEST_NOVY_KOMENTAR: 'request.novy.komentar',
    REQUEST_ODESLAT: 'request.odeslat',
    REQUEST_SUBJEKT_SAP_SMLOUVA_CHYBA: 'request.subjekt.sap.smlouva.chyba',
    REQUEST_KONTROLA_CHYBA: 'request.kontrola.chyba',
    REQUEST_DATA_ULOZENA: 'request.data.ulozena',
    REQUEST_POZADAVEK_VYTVOREN: 'request.pozadavek.vytvoren',
    REQUEST_SKUPINA_VYTVORENA: 'request.skupina.vytvorena',
    REQUEST_UPRAVIT_UZIVATELE: 'request.upravit.uzivatele',
    REQUEST_UPRAVIT_REGISTRACE: 'request.upravit.registrace',
    REQUEST_NALEZENY_SUBJEKT: 'request.nalezeny.subjekt',
    REQUEST_DATA_UZIVATEL: 'request.data.uzivatel',
    REQUEST_DATA_OBCHODNIK: 'request.data.obchodnik',
    REQUEST_DATA_ZAKAZNIK_NAPRIMO: 'request.data.zakaznik.naprimo',
    REQUEST_DATA_KONCOVY_ZAKAZNIK: 'request.data.koncovy.zakaznik',
    REQUEST_DATA_MUNICIPALITA: 'request.data.municipalita',
    REQUEST_DATA_ZADATEL: 'request.data.zadatel',
    REQUEST_VYTVARIM_SKUPINU: 'request.vytvarim.skupinu',
    REQUEST_PRACUJI: 'request.pracuji',
    REQUEST_SCHVALIT: 'request.schvalit',
    REQUEST_STORNO: 'request.storno',
    REQUEST_UPRAVIT: 'request.upravit',
    REQUEST_ZAMITNOUT: 'request.zamitnout',
    REQUEST_DATA_DOPLNIT: 'request.data.doplnit',
    REQUEST_TITLE: 'request.title',
    REQUEST_SKUPINA_UCTU: 'request.skupina.uctu',
    REQUEST_DETAIL_SKUPINA_UCTU: 'request.detail.skupina.uctu',
    REQUEST_SMAZAT_UCET: 'request.smazat.ucet',
    REQUEST_SMAZAT_UCET_INFO: 'request.smazat.ucet.info',
    REQUEST_SMAZAT_UCET_DONE: 'request.smazat.ucet.done',
    ODSTAVKY_DOWNLOAD_LEFT_MENU: 'odstavky.download.left.menu',
    ODSTAVKY_DOWNLOAD_ACTION_START: 'odstavky.download.action.start',
    ODSTAVKY_DOWNLOAD_ACTION_STOP: 'odstavky.download.action.stop',
    ODSTAVKY_DOWNLOAD_NOTIFICATION_STARTED: 'odstavky.download.notification.started',
    ODSTAVKY_DOWNLOAD_NOTIFICATION_STOPPED: 'odstavky.download.notification.stopped',
    ODSTAVKY_DOWNLOAD_NOTIFICATION_CANNOT_STOP: 'odstavky.download.notification.cannot.stop',
    ODSTAVKY_DOWNLOAD_NOTIFICATION_CANNOT_START: 'odstavky.download.notification.cannot.start',
    ODSTAVKY_DOWNLOAD_LABEL_STAV: 'odstavky.download.label.stav',
    ODSTAVKY_DOWNLOAD_LABEL_ZAHAJENO: 'odstavky.download.label.zahajeno',
    ODSTAVKY_DOWNLOAD_LABEL_AKTUALNI_FAZE: 'odstavky.download.label.aktualni.faze',
    ODSTAVKY_DOWNLOAD_LABEL_KONCOVA_FAZE: 'odstavky.download.label.koncova.faze',
    ODSTAVKY_DOWNLOAD_LABEL_DELKA: 'odstavky.download.label.delka',
    ODSTAVKY_DOWNLOAD_LABEL_CHYBA: 'odstavky.download.label.chyba',
    ODSTAVKY_DOWNLOAD_LABEL_POCET: 'odstavky.download.label.pocet',
    ODSTAVKY_DOWNLOAD_LABEL_DATUMOD: 'odstavky.download.label.datumOd',
    ODSTAVKY_DOWNLOAD_LABEL_DATUMDO: 'odstavky.download.label.datumDo',
    ODSTAVKY_DOWNLOAD_LABEL_ERRORONLY: 'odstavky.download.label.errorOnly',
    ODSTAVKY_DOWNLOAD_STAV_IDLE: 'odstavky.download.stav.idle',
    ODSTAVKY_DOWNLOAD_STAV_STOPPED: 'odstavky.download.stav.stopped',
    ODSTAVKY_DOWNLOAD_STAV_RUNNING: 'odstavky.download.stav.running',
    ODSTAVKY_DOWNLOAD_STAV_STOPPING: 'odstavky.download.stav.stopping',
    ODSTAVKY_DOWNLOAD_STAV_ERROR: 'odstavky.download.stav.error',
    ODSTAVKY_DOWNLOAD_STAV_DONE: 'odstavky.download.stav.done',
    ODSTAVKY_DOWNLOAD_BEFORE: 'odstavky.download.before',
    ODSTAVKY_DOWNLOAD_STATE_TITLE: 'odstavky.download.state.title',
    ODSTAVKY_DOWNLOAD_LOG_TITLE: 'odstavky.download.log.title',
    OUTAGEPROCESSPHASE_DOWNLOADING: 'OutageProcessPhase.DOWNLOADING',
    OUTAGEPROCESSPHASE_SAVING: 'OutageProcessPhase.SAVING',
    OUTAGEPROCESSPHASE_IMPORTING: 'OutageProcessPhase.IMPORTING',
    OUTAGEPROCESSPHASE_CLEANING: 'OutageProcessPhase.CLEANING',
    OUTAGEPROCESSPHASE_COMPLETED: 'OutageProcessPhase.COMPLETED',
    OUTAGEPROCESSPHASE_STOPPED: 'OutageProcessPhase.STOPPED',
    UCETCERTIFIKATTYP_OVERENI_KLIENTA: 'UcetCertifikatTyp.OVERENI_KLIENTA',
    UCETCERTIFIKATTYP_DIGITALNI_PODPIS: 'UcetCertifikatTyp.DIGITALNI_PODPIS',
    UCETCERTIFIKATTYP_TWIN: 'UcetCertifikatTyp.TWIN',
    OBCHODNI_PARTNER_FO: 'obchodni.partner.fo',
    OBCHODNI_PARTNER_PO: 'obchodni.partner.po',
    OBCHODNI_PARTNER_IC: 'obchodni.partner.ic',
    OBCHODNI_PARTNER_NAROZEN: 'obchodni.partner.narozen',
    OBCHODNI_PARTNER_ADRESA_CHYBI: 'obchodni.partner.adresa.chybi',
    OBCHODNI_PARTNER_JMENO_PRIJMENI_CHYBI: 'obchodni.partner.jmeno.prijmeni.chybi',
    LOGIN_EMAIL: 'login.email',
    LOGIN_PASSWORD: 'login.password',
    LOGIN_LOGIN: 'login.login',
    LOGIN_LOADING: 'login.loading',
    LOGIN_ERROR: 'login.error',
    LOGIN_ERROR_OTHER: 'login.error.other',
    LOGIN_ERROR_DEACTIVATED: 'login.error.deactivated',
    LOGIN_ERROR_LIMIT: 'login.error.limit',
    LOGIN_ERROR_INVALID_TOKEN: 'login.error.invalid.token',
    LOGIN_ERROR_NOT_EXISTING_ACCOUNT: 'login.error.not.existing.account',
    BOARD_WELCOME: 'board.welcome',
    MENU_DETAIL: 'menu.detail',
    MENU_EDIT: 'menu.edit',
    MENU_DEACTIVATE: 'menu.deactivate',
    MENU_ACTIVATE: 'menu.activate',
    MENU_DELETE: 'menu.delete',
    MENU_RESET: 'menu.reset',
    MENU_RESET_PASSWORD: 'menu.reset.password',
    MENU_PRIHLASIT_ZA_UZIVATELE: 'menu.prihlasit.za.uzivatele',
    MENU_PRIHLASIT_ZA_UZIVATELE_SPP: 'menu.prihlasit.za.uzivatele.spp',
    PROFIL_ADMINISTRATORA: 'profil.administratora',
    POZADAVKY_NEW_ACCOUNT_ACTION_EDIT_USER: 'pozadavky.new.account.action.edit.user',
    POZADAVKY_NEW_ACCOUNT_ACTION_EDIT_REGISTRATION: 'pozadavky.new.account.action.edit.registration',
    POZADAVKY_NEW_ACCOUNT_ACTION_FINISH_REGISTRATION: 'pozadavky.new.account.action.finish.registration',
    POZADAVKY_NEW_ACCOUNT_DATA_SAVED: 'pozadavky.new.account.data.saved',
    POZADAVKY_NEW_ACCOUNT_REQUEST_CREATED: 'pozadavky.new.account.request.created',
    POZADAVKY_NEW_ACCOUNT_GROUP_CREATED: 'pozadavky.new.account.group.created',
    POZADAVKY_NEW_ACCOUNT_MOVED: 'pozadavky.new.account.moved',
    POZADAVKY_NEW_ACCOUNT_SUBJECT: 'pozadavky.new.account.subject',
    POZADAVKY_NEW_ACCOUNT_ACCOUNT_DATA: 'pozadavky.new.account.account.data',
    POZADAVKY_NEW_ACCOUNT_OBCHODNIK: 'pozadavky.new.account.obchodnik',
    POZADAVKY_NEW_ACCOUNT_ZAKAZNIK_NAPRIMO: 'pozadavky.new.account.zakaznik.naprimo',
    POZADAVKY_NEW_ACCOUNT_KONCOVY_ZAKAZNIK: 'pozadavky.new.account.koncovy.zakaznik',
    POZADAVKY_NEW_ACCOUNT_MUNICIPALITA: 'pozadavky.new.account.municipalita',
    POZADAVKY_NEW_ACCOUNT_ZADATEL: 'pozadavky.new.account.zadatel',
    POZADAVKY_NEW_ACCOUNT_ADMINS: 'pozadavky.new.account.admins',
    POZADAVKY_NEW_ACCOUNT_CREATING_GROUP: 'pozadavky.new.account.creating.group',
    POZADAVKY_NEW_ACCOUNT_MOVING: 'pozadavky.new.account.moving',
    POZADAVKY_NEW_ACCOUNT_FINISH_TITLE: 'pozadavky.new.account.finish.title',
    POZADAVKY_NEW_ACCOUNT_FINISH_ERROR: 'pozadavky.new.account.finish.error',
    POZADAVKY_NEW_ACCOUNT_FINISH_SUBTITLE1: 'pozadavky.new.account.finish.subtitle1',
    POZADAVKY_NEW_ACCOUNT_FINISH_SUBTITLE2: 'pozadavky.new.account.finish.subtitle2',
    POZADAVKY_NEW_ACCOUNT_FINISH_SOUHLAS: 'pozadavky.new.account.finish.souhlas',
    POZADAVKY_NEW_ACCOUNT_FINISH_TYP_SUBJEKTU: 'pozadavky.new.account.finish.typ.subjektu',
    POZADAVKY_NEW_ACCOUNT_FINISH_DATUM_NAROZENI: 'pozadavky.new.account.finish.datum.narozeni',
    POZADAVKY_NEW_ACCOUNT_FINISH_IC: 'pozadavky.new.account.finish.ic',
    POZADAVKY_NEW_ACCOUNT_FINISH_CISLO_ELEKTROMERU: 'pozadavky.new.account.finish.cislo.elektromeru',
    POZADAVKY_NEW_ACCOUNT_FINISH_CISLO_PLYNOMERU: 'pozadavky.new.account.finish.cislo.plynomeru',
    POZADAVKY_NEW_ACCOUNT_FINISH_EAN: 'pozadavky.new.account.finish.ean',
    POZADAVKY_NEW_ACCOUNT_FINISH_EIC: 'pozadavky.new.account.finish.eic',
    POZADAVKY_NEW_ACCOUNT_FINISH_FINISHED: 'pozadavky.new.account.finish.finished',
    OBCHODNICI_SAP_ACTION: 'obchodnici.sap.action',
    OBCHODNICI_SAP_IMPORTING: 'obchodnici.sap.importing',
    OBCHODNICI_SAP_IMPORTED: 'obchodnici.sap.imported',
    ACTIVEINACTIVELEFTMENU_ACTIVE: 'ActiveInactiveLeftMenu.active',
    ACTIVEINACTIVELEFTMENU_INACTIVE: 'ActiveInactiveLeftMenu.inactive',
    ACTIVEINACTIVELEFTMENU_ALL: 'ActiveInactiveLeftMenu.all',
    KONFIGDATAPROFILU_NAMERENA: 'KonfigDataProfilu.NAMERENA',
    KONFIGDATAPROFILU_FAKTURACNI: 'KonfigDataProfilu.FAKTURACNI',
    HDO_LEFT_MENU: 'hdo.left.menu',
    HDO_IMPORT_DATUM: 'hdo.import.datum',
    HDO_IMPORT_VERZE: 'hdo.import.verze',
    HDO_IMPORT_STAV: 'hdo.import.stav',
    HDO_IMPORT_CHYBA: 'hdo.import.chyba',
    HDO_IMPORT_SPUSTIT: 'hdo.import.spustit',
    HDO_IMPORT_FILTR_DATUMOD: 'hdo.import.filtr.datumod',
    HDO_IMPORT_FILTR_DATUMDO: 'hdo.import.filtr.datumdo',
    HDO_IMPORT_FILTR_ERROR_ONLY: 'hdo.import.filtr.error.only',
    HDO_IMPORT_LOG: 'hdo.import.log',
    HDO_IMPORT_SPUSTEN_OK: 'hdo.import.spusten.ok',
    HDO_IMPORT_SPUSTEN_CHYBA: 'hdo.import.spusten.chyba',
    NAPOVEDA: 'napoveda',
    KOMODITA_PLYN: 'komodita.plyn',
    KOMODITA_ELEKTRINA: 'komodita.elektrina',
    EDITOR_LINK_VLOZIT_UPRAVIT: 'editor.link.vlozit.upravit',
    EDITOR_LINK_ODSTRANIT: 'editor.link.odstranit',
    EDITOR_NORMALTEXT: 'editor.normalText',
    EDITOR_NADPIS1: 'editor.nadpis1',
    EDITOR_NADPIS2: 'editor.nadpis2',
    EDITOR_NADPIS3: 'editor.nadpis3',
    EDITOR_NADPIS4: 'editor.nadpis4',
    EDITOR_NADPIS5: 'editor.nadpis5',
    EDITOR_NADPIS6: 'editor.nadpis6',
    OPEN_API_PRISTUPY_PAGE_TITLE: 'open.api.pristupy.page.title',
    OPEN_API_PRISTUPY_LEFT_MENU: 'open.api.pristupy.left.menu',
    OPEN_API_PRISTUPY_TYP_PRISTUPU: 'open.api.pristupy.typ.pristupu',
    OPEN_API_PRISTUPY_VLASTNIK: 'open.api.pristupy.vlastnik',
    OPEN_API_PRISTUPY_PLATNOST: 'open.api.pristupy.platnost',
    OPEN_API_PRISTUPY_DEAKTIVOVANO: 'open.api.pristupy.deaktivovano',
    OPEN_API_PRISTUPY_ORIGIN: 'open.api.pristupy.origin',
    OPEN_API_PRISTUPY_ORIGIN_PLACEHOLDER: 'open.api.pristupy.origin.placeholder',
    OPEN_API_PRISTUPY_PLATNY: 'open.api.pristupy.platny',
    OPEN_API_PRISTUPY_TOKEN: 'open.api.pristupy.token',
    OPEN_API_PRISTUPY_TOKEN_ZOBRAZIT: 'open.api.pristupy.token.zobrazit',
    OPEN_API_PRISTUPY_TOKEN_SKRYT: 'open.api.pristupy.token.skryt',
    OPEN_API_PRISTUPY_VYTVORENO: 'open.api.pristupy.vytvoreno',
    OPEN_API_PRISTUPY_VYTVORIL: 'open.api.pristupy.vytvoril',
    OPEN_API_PRISTUPY_ZMENENO: 'open.api.pristupy.zmeneno',
    OPEN_API_PRISTUPY_ZMENIL: 'open.api.pristupy.zmenil',
    OPEN_API_PRISTUPY_POZNAMKA: 'open.api.pristupy.poznamka',
    OPEN_API_PRISTUPY_TYP_PRISTUPU_WEB: 'open.api.pristupy.typ.pristupu.WEB',
    OPEN_API_PRISTUPY_TYP_PRISTUPU_SERVISNI: 'open.api.pristupy.typ.pristupu.SERVISNI',
    OPEN_API_PRISTUPY_PLATNOST_DOBA: 'open.api.pristupy.platnost.doba',
    OPEN_API_PRISTUPY_PLATNOST_DOBA_NEURCITO: 'open.api.pristupy.platnost.doba.NEURCITO',
    OPEN_API_PRISTUPY_PLATNOST_DOBA_ROCNI: 'open.api.pristupy.platnost.doba.ROCNI',
    OPEN_API_PRISTUPY_PLATNOST_DOBA_DVOULETA: 'open.api.pristupy.platnost.doba.DVOULETA',
    OPEN_API_PRISTUPY_PLATNOST_DOBA_PETILETA: 'open.api.pristupy.platnost.doba.PETILETA',
    OPEN_API_PRISTUPY_MENU_DEACTIVATE: 'open.api.pristupy.menu.deactivate',
    OPEN_API_PRISTUPY_MENU_CREATE: 'open.api.pristupy.menu.create',
    OPEN_API_PRISTUPY_MENU_EDIT: 'open.api.pristupy.menu.edit',
    OPEN_API_PRISTUPY_CREATE_TITLE: 'open.api.pristupy.create.title',
    OPEN_API_PRISTUPY_EDIT_TITLE: 'open.api.pristupy.edit.title',
    OPEN_API_PRISTUPY_CREATED: 'open.api.pristupy.created',
    OPEN_API_PRISTUPY_EDITED: 'open.api.pristupy.edited',
    OPEN_API_PRISTUPY_DEACTIVATED: 'open.api.pristupy.deactivated',
    OPEN_API_PRISTUPY_DEACTIVATE_CONFIRM: 'open.api.pristupy.deactivate.confirm',
    OPEN_API_PRISTUPY_DETAIL_TITLE: 'open.api.pristupy.detail.title',
    OPEN_API_PRISTUPY_DETAIL_DETAIL: 'open.api.pristupy.detail.detail',
    OPEN_API_PRISTUPY_DETAIL_LIDE: 'open.api.pristupy.detail.lide',
    OPEN_API_PRISTUPY_DETAIL_DATUMY: 'open.api.pristupy.detail.datumy',
    ROZCESTNIKY_PAGE_TITLE: 'rozcestniky.page.title',
    ROZCESTNIKY_PRIDAT_SEKCI: 'rozcestniky.pridat.sekci',
    ROZCESTNIKY_PRIDAT_BLOK: 'rozcestniky.pridat.blok',
    ROZCESTNIKY_ROZCESTNIK_NEW: 'rozcestniky.rozcestnik.new',
    ROZCESTNIKY_ROZCESTNIK_EDIT: 'rozcestniky.rozcestnik.edit',
    ROZCESTNIKY_SEKCE_NEW: 'rozcestniky.sekce.new',
    ROZCESTNIKY_SEKCE_EDIT: 'rozcestniky.sekce.edit',
    ROZCESTNIKY_BLOK_NEW: 'rozcestniky.blok.new',
    ROZCESTNIKY_BLOK_EDIT: 'rozcestniky.blok.edit',
    ROZCESTNIKY_ZOBRAZIT_SKUPINE: 'rozcestniky.zobrazit.skupine',
    ROZCESTNIKY_ZOBRAZIT_MOBILKA: 'rozcestniky.zobrazit.mobilka',
    ROZCESTNIKY_NAZEV: 'rozcestniky.nazev',
    ROZCESTNIKY_NAZEV_ODKAZ: 'rozcestniky.nazev.odkaz',
    ROZCESTNIKY_POPIS: 'rozcestniky.popis',
    ROZCESTNIKY_PORADI: 'rozcestniky.poradi',
    ROZCESTNIKY_NAZEV_SEKCE: 'rozcestniky.nazev.sekce',
    ROZCESTNIKY_NAZEV_BLOKU: 'rozcestniky.nazev.bloku',
    ROZCESTNIKY_KOMODITA: 'rozcestniky.komodita',
    ROZCESTNIKY_ROZCESTNIK_DETAIL: 'rozcestniky.rozcestnik.detail',
    ROZCESTNIKY_SEKCE_DETAIL: 'rozcestniky.sekce.detail',
    ROZCESTNIKY_SEZNAM: 'rozcestniky.seznam',
    ROZCESTNIKY_ROZCESTNIK_POVOLIT_VYBER_SKUPINY: 'rozcestniky.rozcestnik.povolit.vyber.skupiny',
    ROZCESTNIKY_ROZCESTNIK_CREATE_SUCCESS: 'rozcestniky.rozcestnik.create.success',
    ROZCESTNIKY_SEKCE_CREATE_SUCCESS: 'rozcestniky.sekce.create.success',
    ROZCESTNIKY_BLOK_CREATE_SUCCESS: 'rozcestniky.blok.create.success',
    ROZCESTNIKY_ROZCESTNIK_UPDATE_SUCCESS: 'rozcestniky.rozcestnik.update.success',
    ROZCESTNIKY_SEKCE_UPDATE_SUCCESS: 'rozcestniky.sekce.update.success',
    ROZCESTNIKY_BLOK_UPDATE_SUCCESS: 'rozcestniky.blok.update.success',
    ROZCESTNIKY_ERROR: 'rozcestniky.error',
    REPORTY_JOBY: 'reporty.joby',
    REPORTY_WEB_FORM: 'reporty.web.form',
    REPORTY_PORTAL: 'reporty.portal',
    REPORTY_VYJADROVACI_LINKA: 'reporty.vyjadrovaci.linka',
    REPORTY_VYJADROVACI_LINKA_DETAIL_DIALOG: 'reporty.vyjadrovaci.linka.detail.dialog',
    REPORTY_VYJADROVACI_LINKA_JSON: 'reporty.vyjadrovaci.linka.json',
    REPORTY_VYJADROVACI_LINKA_DGN: 'reporty.vyjadrovaci.linka.dgn',
    REPORTY_VYJADROVACI_LINKA_ID: 'reporty.vyjadrovaci.linka.id',
    REPORTY_VYJADROVACI_LINKA_DATUM_POSLEDNI_ZMENY_OD: 'reporty.vyjadrovaci.linka.datum.posledni.zmeny.od',
    REPORTY_VYJADROVACI_LINKA_DATUM_POSLEDNI_ZMENY_DO: 'reporty.vyjadrovaci.linka.datum.posledni.zmeny.do',
    REPORTY_VYJADROVACI_LINKA_OPENED: 'reporty.vyjadrovaci.linka.OPENED',
    REPORTY_VYJADROVACI_LINKA_CONFIRMED: 'reporty.vyjadrovaci.linka.CONFIRMED',
    REPORTY_VYJADROVACI_LINKA_SENDING_DATA_TO_GEOPORTAL: 'reporty.vyjadrovaci.linka.SENDING_DATA_TO_GEOPORTAL',
    REPORTY_VYJADROVACI_LINKA_WAITING_FOR_GEODATA: 'reporty.vyjadrovaci.linka.WAITING_FOR_GEODATA',
    REPORTY_VYJADROVACI_LINKA_GEODATA_RECEIVED: 'reporty.vyjadrovaci.linka.GEODATA_RECEIVED',
    REPORTY_VYJADROVACI_LINKA_GEODATA_COMPLETED: 'reporty.vyjadrovaci.linka.GEODATA_COMPLETED',
    REPORTY_VYJADROVACI_LINKA_SEND_TO_SAP: 'reporty.vyjadrovaci.linka.SEND_TO_SAP',
    REPORTY_VYJADROVACI_LINKA_REQUEST_COMPLETED: 'reporty.vyjadrovaci.linka.REQUEST_COMPLETED',
    REPORTY_VYJADROVACI_LINKA_SEND_TO_SAP_ERROR: 'reporty.vyjadrovaci.linka.SEND_TO_SAP_ERROR',
    REPORTY_VYJADROVACI_LINKA_STAV_VYJADRENI: 'reporty.vyjadrovaci.linka.stav.VYJADRENI',
    REPORTY_VYJADROVACI_LINKA_STAV_SOUHLAS: 'reporty.vyjadrovaci.linka.stav.SOUHLAS',
    REPORTY_SOP_WEB: 'reporty.sop.web',
    REPORTY_SOP_WEB_SOUHLAS: 'reporty.sop.web.souhlas',
    REPORTY_SOP_WEB_STATISTIKA: 'reporty.sop.web.statistika',
    REPORTY_SOP_WEB_VYHLEDAVANI: 'reporty.sop.web.vyhledavani',
    REPORTY_SOP_WEB_FILTRATION_ROZSIRENE_SKRYT: 'reporty.sop.web.filtration.rozsirene.skryt',
    REPORTY_SOP_WEB_FILTRATION_ROZSIRENE_ZOBRAZIT: 'reporty.sop.web.filtration.rozsirene.zobrazit',
    REPORTY_SOP_WEB_FILTRATION_ROZSIRENE: 'reporty.sop.web.filtration.rozsirene',
    REPORTY_SOP_WEB_TYPZADOSTI: 'reporty.sop.web.typZadosti',
    REPORTY_SOP_WEB_PARSEID: 'reporty.sop.web.parseId',
    REPORTY_SOP_WEB_CISLO_DAVKY: 'reporty.sop.web.cislo.davky',
    REPORTY_SOP_WEB_ZMENENO: 'reporty.sop.web.zmeneno',
    REPORTY_SOP_WEB_ZMENENO_OD: 'reporty.sop.web.zmeneno.od',
    REPORTY_SOP_WEB_ZMENENO_DO: 'reporty.sop.web.zmeneno.do',
    REPORTY_SOP_WEB_VYTVORENO: 'reporty.sop.web.vytvoreno',
    REPORTY_SOP_WEB_VYTVORENO_OD: 'reporty.sop.web.vytvoreno.od',
    REPORTY_SOP_WEB_VYTVORENO_DO: 'reporty.sop.web.vytvoreno.do',
    REPORTY_SOP_WEB_EAN: 'reporty.sop.web.ean',
    REPORTY_SOP_WEB_EAN_VYROBNI: 'reporty.sop.web.ean.vyrobni',
    REPORTY_SOP_WEB_FULTEXT: 'reporty.sop.web.fultext',
    REPORTY_SOP_WEB_ICO: 'reporty.sop.web.ico',
    REPORTY_SOP_WEB_JMENO_PRIJMENI_NAZEV: 'reporty.sop.web.jmeno.prijmeni.nazev',
    REPORTY_SOP_WEB_EMAIL: 'reporty.sop.web.email',
    REPORTY_SOP_WEB_TELEFON: 'reporty.sop.web.telefon',
    REPORTY_SOP_WEB_ZNOVUODESLAT_ZADOST: 'reporty.sop.web.znovuodeslat.zadost',
    REPORTY_SOP_WEB_ZNOVUODESLAT_ZADOST_SUCCESS: 'reporty.sop.web.znovuodeslat.zadost.success',
    REPORTY_SOP_WEB_ZNOVUODESLAT_ZADOST_ERROR: 'reporty.sop.web.znovuodeslat.zadost.error',
    REPORTY_SOP_WEB_STAVZADOSTI: 'reporty.sop.web.stavZadosti',
    REPORTY_SOP_WEB_STAVZADOSTI_VSE_NOT_APERAK_OK: 'reporty.sop.web.stavZadosti.VSE_NOT_APERAK_OK',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_OPENED: 'reporty.sop.web.stavZadosti.STATE_OPENED',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_CONFIRMED: 'reporty.sop.web.stavZadosti.STATE_CONFIRMED',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_SENDING_DATA_TO_GEOPORTAL: 'reporty.sop.web.stavZadosti.STATE_SENDING_DATA_TO_GEOPORTAL',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_WAITING_FOR_GEODATA: 'reporty.sop.web.stavZadosti.STATE_WAITING_FOR_GEODATA',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_GEODATA_RECEIVED: 'reporty.sop.web.stavZadosti.STATE_GEODATA_RECEIVED',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_SEND_TO_SAP: 'reporty.sop.web.stavZadosti.STATE_SEND_TO_SAP',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_WAITING_FOR_APERAK: 'reporty.sop.web.stavZadosti.STATE_WAITING_FOR_APERAK',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_APERAK_RECEIVED: 'reporty.sop.web.stavZadosti.STATE_APERAK_RECEIVED',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_REUEST_COMLETED: 'reporty.sop.web.stavZadosti.STATE_REUEST_COMLETED',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_APERAK_ERROR: 'reporty.sop.web.stavZadosti.STATE_APERAK_ERROR',
    REPORTY_SOP_WEB_STAVZADOSTI_SENDING_SAP: 'reporty.sop.web.stavZadosti.SENDING_SAP',
    REPORTY_SOP_WEB_STAVZADOSTI_STATE_SEND_TO_SAP_ERROR: 'reporty.sop.web.stavZadosti.STATE_SEND_TO_SAP_ERROR',
    REPORTY_SOP_WEB_COLUMN_NAZEV: 'reporty.sop.web.column.nazev',
    REPORTY_SOP_WEB_COLUMN_TYP_ZADOST_ELEKTRINA_SOP_NOVY_ODBER: 'reporty.sop.web.column.typ.ZADOST_ELEKTRINA_SOP_NOVY_ODBER',
    REPORTY_SOP_WEB_COLUMN_TYP_ZADOST_PLYN_SOP_NOVY_ODBER: 'reporty.sop.web.column.typ.ZADOST_PLYN_SOP_NOVY_ODBER',
    REPORTY_SOP_WEB_COLUMN_TYP_ZADOST_SOP_ZMENA_ODBERU: 'reporty.sop.web.column.typ.ZADOST_SOP_ZMENA_ODBERU',
    REPORTY_SOP_WEB_COLUMN_STATE_SEND_TO_SAP: 'reporty.sop.web.column.STATE_SEND_TO_SAP',
    REPORTY_SOP_WEB_COLUMN_STATE_REUEST_COMLETED: 'reporty.sop.web.column.STATE_REUEST_COMLETED',
    REPORTY_SOP_WEB_COLUMN_STATE_APERAK_ERROR: 'reporty.sop.web.column.STATE_APERAK_ERROR',
    REPORTY_SOP_WEB_COLUMN_STATE_CONFIRMED: 'reporty.sop.web.column.STATE_CONFIRMED',
    REPORTY_SOP_WEB_COLUMN_SENDING_SAP: 'reporty.sop.web.column.SENDING_SAP',
    REPORTY_ZASEKNUTE_ZADOSTI: 'reporty.zaseknute.zadosti',
    REPORTY_ZASEKNUTE_ZADOSTI_DAVKY: 'reporty.zaseknute.zadosti.davky',
    REPORTY_ZASEKNUTE_ZADOSTI_CISLO_REPORTU: 'reporty.zaseknute.zadosti.cislo.reportu',
    REPORTY_ZASEKNUTE_ZADOSTI_CISLO_DAVKY: 'reporty.zaseknute.zadosti.cislo.davky',
    REPORTY_ZASEKNUTE_ZADOSTI_VYTVORIL: 'reporty.zaseknute.zadosti.vytvoril',
    REPORTY_ZASEKNUTE_ZADOSTI_VYTVORENO: 'reporty.zaseknute.zadosti.vytvoreno',
    REPORTY_ZASEKNUTE_ZADOSTI_ODESLANO: 'reporty.zaseknute.zadosti.odeslano',
    REPORTY_ZASEKNUTE_ZADOSTI_ZADOSTI: 'reporty.zaseknute.zadosti.zadosti',
    REPORTY_ZASEKNUTE_ZADOSTI_KIND: 'reporty.zaseknute.zadosti.kind',
    REPORTY_BEZPROUDI: 'reporty.bezproudi',
    REPORTY_BEZPROUDI_NO_DATA: 'reporty.bezproudi.no.data',
    REPORTY_BEZPROUDI_STAV: 'reporty.bezproudi.stav',
    REPORTY_BEZPROUDI_PRIJATO: 'reporty.bezproudi.prijato',
    REPORTY_BEZPROUDI_ZPRACOVANO: 'reporty.bezproudi.zpracovano',
    REPORTY_BEZPROUDI_DETAIL_LOKALIT: 'reporty.bezproudi.detail.lokalit',
    REPORTY_BEZPROUDI_CHECKBOX: 'reporty.bezproudi.checkbox',
    REPORTY_BEZPROUDI_CAS_PRIJETI: 'reporty.bezproudi.cas.prijeti',
    REPORTY_BEZPROUDI_ID: 'reporty.bezproudi.id',
    REPORTY_BEZPROUDI_STATUS: 'reporty.bezproudi.status',
    REPORTY_BEZPROUDI_AKTUALNI_STAV: 'reporty.bezproudi.aktualni.stav',
    REPORTY_BEZPROUDI_MONITORING_KOMUNIKACE: 'reporty.bezproudi.monitoring.komunikace',
    REPORTY_BEZPROUDI_TOOLTIP_OK: 'reporty.bezproudi.tooltip.ok',
    REPORTY_BEZPROUDI_TOOLTIP_ERROR: 'reporty.bezproudi.tooltip.error',
    REPORTY_BEZPROUDI_TOOLTIP_DATA_NEPRISLA: 'reporty.bezproudi.tooltip.data.neprisla',
    REPORTY_BEZPROUDI_TOOLTIP_PROCESSING: 'reporty.bezproudi.tooltip.processing',
    REPORTY_BEZPROUDI_LOKALITA_DTS_PORUCHA: 'reporty.bezproudi.lokalita.dts.porucha',
    REPORTY_BEZPROUDI_LOKALITA_DTS_CELKEM: 'reporty.bezproudi.lokalita.dts.celkem',
    REPORTY_BEZPROUDI_LOKALITA_MISTNI_CAST: 'reporty.bezproudi.lokalita.mistni.cast',
    REPORTY_BEZPROUDI_LOKALITA_OBEC: 'reporty.bezproudi.lokalita.obec',
    REPORTY_ODESLANE: 'reporty.odeslane',
    REPORTY_ROZPRACOVANE: 'reporty.rozpracovane',
    REPORTY_KE_STAZENI: 'reporty.ke.stazeni',
    REPORTY_PROVOZNI: 'reporty.provozni',
    REPORTY_FILTER_DATUM_ODESLANI_OD: 'reporty.filter.datum.odeslani.od',
    REPORTY_FILTER_DATUM_ODESLANI_DO: 'reporty.filter.datum.odeslani.do',
    REPORTY_FILTER_DATUM_VYTVORENI_OD: 'reporty.filter.datum.vytvoreni.od',
    REPORTY_FILTER_DATUM_VYTVORENI_DO: 'reporty.filter.datum.vytvoreni.do',
    REPORTY_FILTER_CISLO: 'reporty.filter.cislo',
    REPORTY_COLUMN_CELKEM: 'reporty.column.CELKEM',
    REPORTY_COLUMN_V_RESENI: 'reporty.column.V_RESENI',
    REPORTY_COLUMN_VYRESENO: 'reporty.column.VYRESENO',
    REPORTY_COLUMN_ZAMITNUTO: 'reporty.column.ZAMITNUTO',
    REPORTY_COLUMN_STORNO: 'reporty.column.STORNO',
    REPORTY_COLUMN_CEKA_NA_DOPLNENI: 'reporty.column.CEKA_NA_DOPLNENI',
    REPORTY_COLUMN_CEKA_NA_OPRAVU: 'reporty.column.CEKA_NA_OPRAVU',
    REPORTY_COLUMN_CEKA_NA_PODPIS: 'reporty.column.CEKA_NA_PODPIS',
    REPORTY_COLUMN_NAZEV: 'reporty.column.NAZEV',
    REPORTY_COLUMN_KOMODITA: 'reporty.column.komodita',
    REPORTY_TYP_SAMOODECTY: 'reporty.typ.samoodecty',
    REPORTY_TYP_REKLAMACE: 'reporty.typ.reklamace',
    REPORTY_TYP_ZADOSTI: 'reporty.typ.zadosti',
    REPORTY_TYP_PRERUSENI: 'reporty.typ.preruseni',
    REPORTY_TYP_OBNOVENI: 'reporty.typ.obnoveni',
    REPORTY_TYP_RAMCOVASMLOUVA: 'reporty.typ.ramcovaSmlouva',
    REPORTY_TYP_REZERVACEDISTRIBUCNIKAPACITY: 'reporty.typ.rezervaceDistribucniKapacity',
    REPORTY_TYP_UKONCENI: 'reporty.typ.ukonceni',
    REPORTY_TYP_SOPPREPIS: 'reporty.typ.sopPrepis',
    REPORTY_TYP_ZMENAJISTICE: 'reporty.typ.zmenaJistice',
    ODS_IMPORT_LEFT_MENU: 'ods.import.left.menu',
    ODS_IMPORT_LOG: 'ods.import.log',
    ODS_IMPORT_DATUM_SPUSTENI: 'ods.import.datum.spusteni',
    ODS_IMPORT_DATUM_UKONCENI: 'ods.import.datum.ukonceni',
    ODS_IMPORT_STAV: 'ods.import.stav',
    ODS_IMPORT_STAV_OK: 'ods.import.stav.OK',
    ODS_IMPORT_STAV_RUNNING: 'ods.import.stav.RUNNING',
    ODS_IMPORT_STAV_ERROR: 'ods.import.stav.ERROR',
    ODS_IMPORT_CHYBY: 'ods.import.chyby',
    ODS_IMPORT_VAROVANI: 'ods.import.varovani',
    ODS_IMPORT_VAROVANI_SEZNAM: 'ods.import.varovani.seznam',
    LAYOUT_TITLE_ODS_IMPORT: 'layout.title.ods.import',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_DRUH: 'globalni.kontakty.distributora.druh',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_HODNOTA: 'globalni.kontakty.distributora.hodnota',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_ZOBRAZIT_SKUPINE: 'globalni.kontakty.distributora.zobrazit.skupine',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP: 'globalni.kontakty.distributora.typ',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_SKUPINY: 'globalni.kontakty.distributora.typ.skupiny',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_KONTAKTU: 'globalni.kontakty.distributora.typ.kontaktu',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_VSE: 'globalni.kontakty.distributora.typ.vse',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_SERVIS: 'globalni.kontakty.distributora.typ.servis',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_OSTATNI: 'globalni.kontakty.distributora.typ.ostatni',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_ELEKTRINA_NN: 'globalni.kontakty.distributora.typ.elektrina.nn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_ELEKTRINA_VN: 'globalni.kontakty.distributora.typ.elektrina.vn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_PLYN: 'globalni.kontakty.distributora.typ.plyn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_ELEKTRINA_PORUCHA: 'globalni.kontakty.distributora.typ.elektrina.porucha',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TYP_PLYN_PORUCHA: 'globalni.kontakty.distributora.typ.plyn.porucha',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_NAZEV: 'globalni.kontakty.distributora.nazev',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_VSE: 'globalni.kontakty.distributora.vse',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_VSECHNY_KONTAKTY: 'globalni.kontakty.distributora.vsechny.kontakty',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_DRUH_KOMUNIKACE_EMAIL: 'globalni.kontakty.distributora.druh.komunikace.email',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_DRUH_KOMUNIKACE_TELEFON: 'globalni.kontakty.distributora.druh.komunikace.telefon',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SMAZAN: 'globalni.kontakty.distributora.smazan',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SMAZANY: 'globalni.kontakty.distributora.smazany',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_UPRAVEN: 'globalni.kontakty.distributora.upraven',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_VYTVOREN: 'globalni.kontakty.distributora.vytvoren',
    GLOBALNI_KONTAKTY_DISTRIBUTORA: 'globalni.kontakty.distributora',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SMAZAT_VYBRANE: 'globalni.kontakty.distributora.smazat.vybrane',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SKUPINA: 'globalni.kontakty.distributora.skupina',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_KONTAKTY: 'globalni.kontakty.distributora.kontakty',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_KONTAKTY_DETAIL: 'globalni.kontakty.distributora.kontakty.detail',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_UPRAVIT: 'globalni.kontakty.distributora.upravit',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_VYTVORIT: 'globalni.kontakty.distributora.vytvorit',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_CONFIRM_DELETE_KONTAKT: 'globalni.kontakty.distributora.confirm.delete.kontakt',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_CONFIRM_DELETE_KONTAKTY: 'globalni.kontakty.distributora.confirm.delete.kontakty',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SKUPINA_TYP_OBCHODNIK: 'globalni.kontakty.distributora.skupina.typ.obchodnik',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SKUPINA_TYP_KONCOVY_ZAKAZNIK: 'globalni.kontakty.distributora.skupina.typ.koncovy.zakaznik',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SKUPINA_TYP_MUNICIPALITA: 'globalni.kontakty.distributora.skupina.typ.municipalita',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_SKUPINA_TYP_ZADATEL: 'globalni.kontakty.distributora.skupina.typ.zadatel',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_DETAIL: 'globalni.kontakty.distributora.detail',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_KONTAKTY_DISTRIBUTORA: 'globalni.kontakty.distributora.kontakty.distributora',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_ERROR: 'globalni.kontakty.distributora.error',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_ERROR_BACKEND: 'globalni.kontakty.distributora.error.backend',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_ERROR_UPDATE: 'globalni.kontakty.distributora.error.update',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_ERROR_CREATE: 'globalni.kontakty.distributora.error.create',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_POLE_POVINNE: 'globalni.kontakty.distributora.pole.povinne',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_CONFIRM_UPDATE_DIALOG: 'globalni.kontakty.distributora.confirm.update.dialog',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_SERVIS: 'globalni.kontakty.distributora.tooltip.servis',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_OSTATNI: 'globalni.kontakty.distributora.tooltip.ostatni',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_ELEKTRINA_NN: 'globalni.kontakty.distributora.tooltip.elektrina.nn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_ELEKTRINA_VN: 'globalni.kontakty.distributora.tooltip.elektrina.vn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_PLYN: 'globalni.kontakty.distributora.tooltip.plyn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_ELEKTRINA_PORUCHA: 'globalni.kontakty.distributora.tooltip.elektrina.porucha',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_PLYN_PORUCHA: 'globalni.kontakty.distributora.tooltip.plyn.porucha',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_SKUPINA: 'skupinove.kontakty.distributora.skupina',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_SKUPINA_FORM: 'skupinove.kontakty.distributora.skupina.form',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA: 'skupinove.kontakty.distributora',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP: 'skupinove.kontakty.distributora.typ',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_PRACOVNIK: 'skupinove.kontakty.distributora.pracovnik',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_PRACOVNIK_FORM: 'skupinove.kontakty.distributora.pracovnik.form',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TELEFON: 'skupinove.kontakty.distributora.telefon',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_EMAIL: 'skupinove.kontakty.distributora.email',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_SKUPINA_PLACEHOLDER: 'skupinove.kontakty.distributora.skupina.placeholder',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_KONTAKTU: 'skupinove.kontakty.distributora.typ.kontaktu',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_PRACOVNIK_DISTRIBUCE_PLACEHOLDER: 'skupinove.kontakty.distributora.pracovnik.distribuce.placeholder',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_VSE: 'skupinove.kontakty.distributora.typ.vse',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_OBLASTNI_MANAZER: 'skupinove.kontakty.distributora.typ.oblastni.manazer',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_VEDOUCI_SPRAVY_ELEKTRINA: 'skupinove.kontakty.distributora.typ.vedouci.spravy.elektrina',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_VEDOUCI_SPRAVY_PLYN: 'skupinove.kontakty.distributora.typ.vedouci.spravy.plyn',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_PRACOVNIK_DISTRIBUCE: 'skupinove.kontakty.distributora.pracovnik.distribuce',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_CONFIRM_DELETE_KONTAKT: 'skupinove.kontakty.distributora.confirm.delete.kontakt',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_CONFIRM_DELETE_KONTAKTY: 'skupinove.kontakty.distributora.confirm.delete.kontakty',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_SMAZAN: 'skupinove.kontakty.distributora.smazan',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_SMAZANY: 'skupinove.kontakty.distributora.smazany',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_SMAZAT_VYBRANE: 'skupinove.kontakty.distributora.smazat.vybrane',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_UPRAVIT: 'skupinove.kontakty.distributora.upravit',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_VYTVORIT: 'skupinove.kontakty.distributora.vytvorit',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_PRACOVNIK_LABEL: 'skupinove.kontakty.distributora.pracovnik.label',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_ERROR_BACKEND: 'skupinove.kontakty.distributora.error.backend',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_ERROR: 'skupinove.kontakty.distributora.error',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_DETAIL: 'skupinove.kontakty.distributora.detail',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_IC: 'skupinove.kontakty.distributora.ic',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_CISLO: 'skupinove.kontakty.distributora.cislo',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_VYTVOREN: 'skupinove.kontakty.distributora.vytvoren',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_ERROR_CREATE: 'skupinove.kontakty.distributora.error.create',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_UPRAVEN: 'skupinove.kontakty.distributora.upraven',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_ERROR_UPDATE: 'skupinove.kontakty.distributora.error.update',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TOOLTIP_MANAZER: 'skupinove.kontakty.distributora.tooltip.manazer',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TOOLTIP_VEDOUCI_SPRAVY_ELEKTRINA: 'skupinove.kontakty.distributora.tooltip.vedouci.spravy.elektrina',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TOOLTIP_VEDOUCI_SPRAVY_PLYN: 'skupinove.kontakty.distributora.tooltip.vedouci.spravy.plyn',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_NAZEV: 'skupinove.kontakty.distributora.nazev',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_IC_VALUE: 'skupinove.kontakty.distributora.ic.value',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_CISLO_VALUE: 'skupinove.kontakty.distributora.cislo.value',
    SPRAVA_KONTAKTU_DISTRIBUCE_GLOBALNI: 'sprava.kontaktu.distribuce.globalni',
    SPRAVA_KONTAKTU_DISTRIBUCE_SKUPINOVE: 'sprava.kontaktu.distribuce.skupinove',
    SPRAVA_KONTAKTU_DISTRIBUCE_PRACOVNICI_DISTRIBUCE: 'sprava.kontaktu.distribuce.pracovnici.distribuce',
    REPORTY_KE_STAZENI_TYP: 'reporty.ke.stazeni.typ',
    REPORTY_KE_STAZENI_TYP_VSE: 'reporty.ke.stazeni.typ.vse',
    REPORTY_KE_STAZENI_TYP_PLACEHOLDER: 'reporty.ke.stazeni.typ.placeholder',
    REPORTY_KE_STAZENI_TYP_ZADOST_RAMCOVA_SMLOUVA: 'reporty.ke.stazeni.typ.ZADOST_RAMCOVA_SMLOUVA',
    REPORTY_KE_STAZENI_TYP_ODECTY_ELEKTRINA: 'reporty.ke.stazeni.typ.ODECTY_ELEKTRINA',
    REPORTY_KE_STAZENI_TYP_ODECTY_PLYN: 'reporty.ke.stazeni.typ.ODECTY_PLYN',
    REPORTY_KE_STAZENI_TYP_ZADOST_REZERVACE_DISTRIBUCNI_KAPACITY: 'reporty.ke.stazeni.typ.ZADOST_REZERVACE_DISTRIBUCNI_KAPACITY',
    REPORTY_KE_STAZENI_TYP_ZADOST_SOP_PREPIS_ELEKTRINA: 'reporty.ke.stazeni.typ.ZADOST_SOP_PREPIS_ELEKTRINA',
    REPORTY_KE_STAZENI_TYP_ZADOST_SOP_PREPIS_PLYN: 'reporty.ke.stazeni.typ.ZADOST_SOP_PREPIS_PLYN',
    REPORTY_KE_STAZENI_TYP_ZADOST_PRERUSENI_OBNOVENI_ELEKTRINA: 'reporty.ke.stazeni.typ.ZADOST_PRERUSENI_OBNOVENI_ELEKTRINA',
    REPORTY_KE_STAZENI_TYP_ZADOST_UKONCENI_PRERUSENI_OBNOVENI_PLYN: 'reporty.ke.stazeni.typ.ZADOST_UKONCENI_PRERUSENI_OBNOVENI_PLYN',
    REPORTY_KE_STAZENI_TYP_ZADOST_SOP_ZMENA_JISTICE: 'reporty.ke.stazeni.typ.ZADOST_SOP_ZMENA_JISTICE',
    REPORTY_KE_STAZENI_STAV: 'reporty.ke.stazeni.stav',
    REPORTY_KE_STAZENI_STAV_VSE: 'reporty.ke.stazeni.stav.vse',
    REPORTY_KE_STAZENI_STAV_NAPLANOVAN: 'reporty.ke.stazeni.stav.NAPLANOVAN',
    REPORTY_KE_STAZENI_STAV_PROBIHA: 'reporty.ke.stazeni.stav.PROBIHA',
    REPORTY_KE_STAZENI_STAV_ZASTAVEN: 'reporty.ke.stazeni.stav.ZASTAVEN',
    REPORTY_KE_STAZENI_STAV_DOKONCEN: 'reporty.ke.stazeni.stav.DOKONCEN',
    REPORTY_KE_STAZENI_DATUM_VYTVORENI_OD: 'reporty.ke.stazeni.datum.vytvoreni.od',
    REPORTY_KE_STAZENI_DATUM_VYTVORENI_DO: 'reporty.ke.stazeni.datum.vytvoreni.do',
    REPORTY_KE_STAZENI_VYTVORENO: 'reporty.ke.stazeni.vytvoreno',
    REPORTY_KE_STAZENI_DOKONCENO: 'reporty.ke.stazeni.dokonceno',
    REPORTY_KE_STAZENI_CHYBA: 'reporty.ke.stazeni.chyba',
    REPORTY_KE_STAZENI_STAHNOUT: 'reporty.ke.stazeni.stahnout',
    REPORTY_KE_STAZENI_NOVY: 'reporty.ke.stazeni.novy',
    REPORTY_KE_STAZENI_NAPLANOVAT: 'reporty.ke.stazeni.naplanovat',
    REPORTY_KE_STAZENI_NOTIFICATION_SUCCESS: 'reporty.ke.stazeni.notification.success',
    REPORTY_KE_STAZENI_NOTIFICATION_ALREADY: 'reporty.ke.stazeni.notification.already',
    REPORTY_KE_STAZENI_NOTIFICATION_ERROR: 'reporty.ke.stazeni.notification.error',
    REPORTY_PROVOZNI_CISLO: 'reporty.provozni.cislo',
    REPORTY_PROVOZNI_VYTVORENO: 'reporty.provozni.vytvoreno',
    REPORTY_PROVOZNI_STAV: 'reporty.provozni.stav',
    REPORTY_PROVOZNI_CHYBA_TEXT: 'reporty.provozni.chyba.text',
    REPORTY_PROVOZNI_STAHNOUT: 'reporty.provozni.stahnout',
    REPORTY_AUDIT_LOG: 'reporty.audit.log',
    REPORTY_AUDIT_LOG_DETAIL: 'reporty.audit.log.detail',
    REPORTY_AUDIT_LOG_DETAIL_REQUEST: 'reporty.audit.log.detail.request',
    REPORTY_AUDIT_LOG_DETAIL_RESPONSE: 'reporty.audit.log.detail.response',
    REPORTY_AUDIT_LOG_APLIKACE: 'reporty.audit.log.aplikace',
    REPORTY_AUDIT_LOG_AKCE: 'reporty.audit.log.akce',
    REPORTY_AUDIT_LOG_VYTVORENO: 'reporty.audit.log.vytvoreno',
    REPORTY_AUDIT_LOG_V_ZASTOUPENI: 'reporty.audit.log.v.zastoupeni',
    REPORTY_AUDIT_LOG_ENTITA: 'reporty.audit.log.entita',
    REPORTY_AUDIT_LOG_SELFCARE: 'reporty.audit.log.selfcare',
    REPORTY_AUDIT_LOG_ADMIN: 'reporty.audit.log.admin',
    REPORTY_AUDIT_LOG_PORTAL: 'reporty.audit.log.portal',
    REPORTY_AUDIT_LOG_VYTVORENO_OD: 'reporty.audit.log.vytvoreno.od',
    REPORTY_AUDIT_LOG_VYTVORENO_DO: 'reporty.audit.log.vytvoreno.do',
    REPORTY_AUDIT_LOG_OPERACE: 'reporty.audit.log.operace',
    REPORTY_AUDIT_LOG_UZIVATEL: 'reporty.audit.log.uzivatel',
    REPORTY_AUDIT_LOG_UZIVATEL_JMENO: 'reporty.audit.log.uzivatel.jmeno',
    REPORTY_AUDIT_LOG_UZIVATEL_PRIJMENI: 'reporty.audit.log.uzivatel.prijmeni',
    REPORTY_AUDIT_LOG_UZIVATEL_EMAIL: 'reporty.audit.log.uzivatel.email',
    COPIED_TO_CLIPBOARD: 'copied.to.clipboard',
    BUTTON_DETAIL: 'button.detail',
    BUTTON_OVERIT: 'button.overit',
    LOGIN_ZPET_NA_PRIHLASENI: 'login.zpet.na.prihlaseni',
    ERROR_EMAIL_POVINNY: 'error.email.povinny',
    REGISTRACE: 'registrace',
    REGISTRACE_PAGE_TITLE: 'registrace.page.title',
    RESET_HESLA_POZADAVEK_TITLE: 'reset.hesla.pozadavek.title',
    RESET_HESLA_TITLE: 'reset.hesla.title',
    RESET_HESLA_SUBTITLE: 'reset.hesla.subtitle',
    RESET_HESLA_ERROR_TITLE: 'reset.hesla.error.title',
    RESET_HESLA_ERROR_TEXT: 'reset.hesla.error.text',
    RESET_HESLA_ERROR_NAPOVEDA: 'reset.hesla.error.napoveda',
    RESET_HESLA_ODESLAT: 'reset.hesla.odeslat',
    RESET_HESLA_PROFIL_RESETOVAT: 'reset.hesla.profil.resetovat',
    RESET_HESLA_POZADAVEK_ODESLANO: 'reset.hesla.pozadavek.odeslano',
    RESET_HESLA_POZADAVEK_ODESLANO_TEXT: 'reset.hesla.pozadavek.odeslano.text',
    RESET_HESLA_POZADAVEK_ODESLANO_UPOZORNENI: 'reset.hesla.pozadavek.odeslano.upozorneni',
    RESET_HESLA_POZADAVEK_NAPOVEDA: 'reset.hesla.pozadavek.napoveda',
    RESET_HESLA_PLATNOST_VYPRSELA_TITLE: 'reset.hesla.platnost.vyprsela.title',
    RESET_HESLA_PLATNOST_VYPRSELA_TEXT: 'reset.hesla.platnost.vyprsela.text',
    RESET_HESLA_PLATNOST_VYPRSELA_NAPOVEDA: 'reset.hesla.platnost.vyprsela.napoveda',
    RESET_HESLA_PLATNOST_NOVY_POZADAVEK: 'reset.hesla.platnost.novy.pozadavek',
    RESET_HESLA_SUCCESS_TEXT1: 'reset.hesla.success.text1',
    RESET_HESLA_SUCCESS_TEXT2: 'reset.hesla.success.text2',
    RESET_HESLA_ZPET: 'reset.hesla.zpet',
    RESET_HESLA_PRIHLASENI: 'reset.hesla.prihlaseni',
    RESET_HESLA_LOADING_DESCRIPTION: 'reset.hesla.loading.description',
    ADMIN_PRIHLASENY_UZIVATEL: 'admin.prihlaseny.uzivatel',
    ADMIN_PRIHLASENY_VYTVOREN: 'admin.prihlaseny.vytvoren',
    ADMIN_PRIHLASENY_AKTUALIZOVAN: 'admin.prihlaseny.aktualizovan',
    HAS_NO_ACCESS: 'has.no.access',
    NASTENKA_WIDGET_COMIC: 'nastenka.widget.comic',
    NASTENKA_WIDGET_CERVENE_TLACITKO: 'nastenka.widget.cervene.tlacitko',
    NASTENKA_WIDGET_CERVENE_TLACITKO_PPP: 'nastenka.widget.cervene.tlacitko.ppp',
    NASTENKA_WIDGET_CERVENE_TLACITKO_VL: 'nastenka.widget.cervene.tlacitko.vl',
    NASTENKA_WIDGET_CERVENE_TLACITKO_SCHL: 'nastenka.widget.cervene.tlacitko.schl',
    NASTENKA_WIDGET_COMIC_REGISTRACE_OK: 'nastenka.widget.comic.registrace.ok',
    NASTENKA_WIDGET_COMIC_REGISTRACE_ERROR: 'nastenka.widget.comic.registrace.error',
    PRACOVNICI_DISTRIBUCE_KID: 'pracovnici.distribuce.kid',
    PRACOVNICI_DISTRIBUCE_SKUPINA_UCTU: 'pracovnici.distribuce.skupina.uctu',
    PRACOVNICI_DISTRIBUCE_TYP_KONTAKTU_DISTRIBUTORA: 'pracovnici.distribuce.typ.kontaktu.distributora',
    PRACOVNICI_DISTRIBUCE_VYTVORIT: 'pracovnici.distribuce.vytvorit',
    PRACOVNICI_DISTRIBUCE_ULOZIT: 'pracovnici.distribuce.ulozit',
    PRACOVNICI_DISTRIBUCE_TELEFON: 'pracovnici.distribuce.telefon',
    PRACOVNICI_DISTRIBUCE_EMAIL: 'pracovnici.distribuce.email',
    PRACOVNICI_DISTRIBUCE_TITLE: 'pracovnici.distribuce.title',
    PRACOVNICI_DISTRIBUCE_JMENO: 'pracovnici.distribuce.jmeno',
    PRACOVNICI_DISTRIBUCE_PRIJMENI: 'pracovnici.distribuce.prijmeni',
    PRACOVNICI_DISTRIBUCE_VYTVORENO: 'pracovnici.distribuce.vytvoreno',
    PRACOVNICI_DISTRIBUCE_UPRAVENO: 'pracovnici.distribuce.upraveno',
    PRACOVNICI_DISTRIBUCE_DETAIL_TITLE: 'pracovnici.distribuce.detail.title',
    PRACOVNICI_DISTRIBUCE_EDIT_TITLE: 'pracovnici.distribuce.edit.title',
    PRACOVNICI_DISTRIBUCE_CREATE_TITLE: 'pracovnici.distribuce.create.title',
    PRACOVNICI_DISTRIBUCE_DELETE_TITLE: 'pracovnici.distribuce.delete.title',
    PRACOVNICI_DISTRIBUCE_MOVE_TITLE: 'pracovnici.distribuce.move.title',
    PRACOVNICI_DISTRIBUCE_PRIRAZENE_SKUPINY_TITLE: 'pracovnici.distribuce.prirazene.skupiny.title',
    PRACOVNICI_DISTRIBUCE_KONTAKTY_PRESUNUTY_TITLE: 'pracovnici.distribuce.kontakty.presunuty.title',
    PRACOVNICI_DISTRIBUCE_DETAIL: 'pracovnici.distribuce.detail',
    PRACOVNICI_DISTRIBUCE_FULLTEXT_LABEL: 'pracovnici.distribuce.fulltext.label',
    PRACOVNICI_DISTRIBUCE_ERROR_BACKEND: 'pracovnici.distribuce.error.backend',
    PRACOVNICI_DISTRIBUCE_ERROR_UPDATE: 'pracovnici.distribuce.error.update',
    PRACOVNICI_DISTRIBUCE_ERROR_CREATE: 'pracovnici.distribuce.error.create',
    PRACOVNICI_DISTRIBUCE_ERROR: 'pracovnici.distribuce.error',
    PRACOVNICI_DISTRIBUCE_ERROR_MOVE: 'pracovnici.distribuce.error.move',
    PRACOVNICI_DISTRIBUCE_CONFIRM_DELETE_KONTAKT: 'pracovnici.distribuce.confirm.delete.kontakt',
    PRACOVNICI_DISTRIBUCE_PREDAT_KONTAKTY: 'pracovnici.distribuce.predat.kontakty',
    PRACOVNICI_DISTRIBUCE_PREDAT_KONTAKTY_TEXTACE: 'pracovnici.distribuce.predat.kontakty.textace',
    PRACOVNICI_DISTRIBUCE_PREDAT_KONTAKTY_PRACOVNIK_DISTRIBUCE: 'pracovnici.distribuce.predat.kontakty.pracovnik.distribuce',
    PRACOVNICI_DISTRIBUCE_CONFIRM_UPDATE_DIALOG_JEDEN: 'pracovnici.distribuce.confirm.update.dialog.jeden',
    PRACOVNICI_DISTRIBUCE_CONFIRM_UPDATE_DIALOG_VICE: 'pracovnici.distribuce.confirm.update.dialog.vice',
    YUP_EAN: 'yup.ean',
    YUP_EIC: 'yup.eic',
    CEKEJTE_PROSIM: 'cekejte.prosim',
    ANO: 'ano',
    NE: 'ne',
    NN: 'nn',
    VN: 'vn',
    VYKRICNIK: 'vykricnik',
    POTVRZENI: 'potvrzeni',
    GRAPHQL_ERROR: 'graphql.error',
    SAP_ERROR: 'sap.error',
    ERROR_SHOWSOURCE: 'error.showSource',
    AUTOCOMPLETE_NO_RECORDS: 'autocomplete.no.records',
    BUTTON_PRIDAT: 'button.pridat',
    BUTTON_ZPET: 'button.zpet',
    BUTTON_ZPET_NA_PREHLED: 'button.zpet.na.prehled',
    BUTTON_VYBRAT: 'button.vybrat',
    BUTTON_ZAVRIT: 'button.zavrit',
    BUTTON_VYTVORIT: 'button.vytvorit',
    BUTTON_SMAZAT: 'button.smazat',
    BUTTON_AKTIVOVAT: 'button.aktivovat',
    BUTTON_DEAKTIVOVAT: 'button.deaktivovat',
    BUTTON_UPRAVIT: 'button.upravit',
    BUTTON_DOWNLOAD: 'button.download',
    BUTTON_EXPORT: 'button.export',
    BUTTON_VICE_INFORMACI: 'button.vice.informaci',
    BUTTON_AKTUALIZOVAT: 'button.aktualizovat',
    BUTTON_ULOZIT: 'button.ulozit',
    BUTTON_ULOZIT_KONCEPT: 'button.ulozit.koncept',
    BUTTON_VYHLEDAT: 'button.vyhledat',
    BUTTON_ODESLAT: 'button.odeslat',
    BUTTON_ROOT: 'button.root',
    BUTTON_STORNO: 'button.storno',
    BUTTON_DALSI: 'button.dalsi',
    BUTTON_ZRUSIT_FILTR: 'button.zrusit.filtr',
    BUTTON_SOUHLAS: 'button.souhlas',
    BUTTON_ZRUSIT: 'button.zrusit',
    POZADAVEK_TYP_OSTATNI: 'pozadavek.typ.OSTATNI',
    POZADAVEK_TYP_CHYBA_SYSTEMU: 'pozadavek.typ.CHYBA_SYSTEMU',
    POZADAVEK_TYP_NAVRH_NA_VYLEPSENI: 'pozadavek.typ.NAVRH_NA_VYLEPSENI',
    POZADAVEK_TYP_ZADOST_O_REGISTRACI: 'pozadavek.typ.ZADOST_O_REGISTRACI',
    POZADAVEK_STAV_CEKA_NA_DOPLNENI: 'pozadavek.stav.CEKA_NA_DOPLNENI',
    POZADAVEK_STAV_V_RESENI: 'pozadavek.stav.V_RESENI',
    POZADAVEK_STAV_SCHVALENO: 'pozadavek.stav.SCHVALENO',
    POZADAVEK_STAV_ZAMITNUTO: 'pozadavek.stav.ZAMITNUTO',
    POZADAVEK_STAV_STORNO: 'pozadavek.stav.STORNO',
    UCET_SKUPINA_TYP_OBCHODNIK: 'ucet.skupina.typ.OBCHODNIK',
    UCET_SKUPINA_TYP_MUNICIPALITA: 'ucet.skupina.typ.MUNICIPALITA',
    UCET_SKUPINA_TYP_ZAKAZNIK_SE_SMLOUVOU_NAPRIMO: 'ucet.skupina.typ.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO',
    UCET_SKUPINA_TYP_KONCOVY_ZAKAZNIK: 'ucet.skupina.typ.KONCOVY_ZAKAZNIK',
    UCET_SKUPINA_TYP_ZADATEL: 'ucet.skupina.typ.ZADATEL',
    UCET_SKUPINA_TYP_VYROBCE_PROVOZOVATEL: 'ucet.skupina.typ.VYROBCE_PROVOZOVATEL',
    UCET_SKUPINA_TYP_DODAVATEL: 'ucet.skupina.typ.DODAVATEL',
    PRILOHY_PRILIS_MNOHO_SOUBORU: 'prilohy.prilis.mnoho.souboru',
    PRILOHY_VELKY_SOUBOR: 'prilohy.velky.soubor',
    PRILOHY_MAX_FILE_SIZE_ALL: 'prilohy.max.file.size.all',
    PRILOHY_MUZETE_VLOZIT_0: 'prilohy.muzete.vlozit.0',
    PRILOHA_NAZEV_ERROR: 'priloha.nazev.error',
    PRILOHY_MUZETE_VLOZIT_1: 'prilohy.muzete.vlozit.1',
    PRILOHY_MUZETE_VLOZIT_2: 'prilohy.muzete.vlozit.2',
    PRILOHY_MUZETE_VLOZIT_5: 'prilohy.muzete.vlozit.5',
    PRILOHY_ARCHIVOVANE: 'prilohy.archivovane',
    PRILOHY_K_NAHRANI: 'prilohy.k.nahrani',
    PRILOHY_POTVRZENI_SMAZANI: 'prilohy.potvrzeni.smazani',
    PRILOHY_VYBERTE_TYP: 'prilohy.vyberte.typ',
    PRILOHY_VYBERTE_TYP_HINT: 'prilohy.vyberte.typ.hint',
    PRILOHY_VYBERTE_DIAKRITIKA_HINT: 'prilohy.vyberte.diakritika.hint',
    PRILOHY_ZADNE_INFO: 'prilohy.zadne.info',
    PRILOHA_TYP_ZRQ01: 'priloha.typ.ZRQ01',
    PRILOHA_TYP_ZRQ02: 'priloha.typ.ZRQ02',
    PRILOHA_TYP_ZRQ03: 'priloha.typ.ZRQ03',
    PRILOHA_TYP_ZRQ04: 'priloha.typ.ZRQ04',
    PRILOHA_TYP_ZRQ05: 'priloha.typ.ZRQ05',
    PRILOHA_TYP_ZRQ06: 'priloha.typ.ZRQ06',
    PRILOHA_TYP_ZRQ07: 'priloha.typ.ZRQ07',
    PRILOHA_TYP_ZRQ08: 'priloha.typ.ZRQ08',
    PRILOHA_TYP_ZRQ09: 'priloha.typ.ZRQ09',
    PRILOHA_TYP_ZRQ10: 'priloha.typ.ZRQ10',
    PRILOHA_TYP_ZRQ11: 'priloha.typ.ZRQ11',
    PRILOHA_TYP_ZRQ12: 'priloha.typ.ZRQ12',
    PRILOHA_TYP_ZRQ13: 'priloha.typ.ZRQ13',
    PRILOHA_TYP_ZRQ99: 'priloha.typ.ZRQ99',
    PRILOHA_TYP_ZRAS0001: 'priloha.typ.ZRAS0001',
    PRILOHA_TYP_ZRAS0003: 'priloha.typ.ZRAS0003',
    PRILOHA_TYP_ZRAS0004: 'priloha.typ.ZRAS0004',
    PRILOHA_TYP_ZRAS0005: 'priloha.typ.ZRAS0005',
    PRILOHA_TYP_ZADOST: 'priloha.typ.ZADOST',
    PRILOHA_TYP_ZDOCOST: 'priloha.typ.ZDOCOST',
    PRILOHA_TYP_ZDOD: 'priloha.typ.ZDOD',
    PRILOHA_TYP_ZPM: 'priloha.typ.ZPM',
    PRILOHA_TYP_ZRZ: 'priloha.typ.ZRZ',
    PRILOHA_TYP_ZSMLIN: 'priloha.typ.ZSMLIN',
    PRILOHA_TYP_ZSMLNAVOUT: 'priloha.typ.ZSMLNAVOUT',
    PRILOHA_TYP_ZSMLUK: 'priloha.typ.ZSMLUK',
    PRILOHA_TYP_ZELEMOBIL: 'priloha.typ.ZELEMOBIL',
    PRILOHA_TYP_ZRQ22: 'priloha.typ.ZRQ22',
    PRILOHA_TYP_ZRQ24: 'priloha.typ.ZRQ24',
    PRILOHA_TYP_OSTATNI: 'priloha.typ.OSTATNI',
    PRILOHA_TYP_VYLEPSENI: 'priloha.typ.VYLEPSENI',
    PRILOHA_TYP_CHYBA: 'priloha.typ.CHYBA',
    PRILOHA_TYP_REGISTRACE: 'priloha.typ.REGISTRACE',
    PRILOHA_TYP_PRILOHY_ERROR: 'priloha.typ.prilohy.error',
    PRILOHY_NEPOVOLENY_FORMAT_EXTENSION: 'prilohy.nepovoleny.format.extension',
    PRILOHY_BEZ_PRILOHY: 'prilohy.bez.prilohy',
    PRILOHY_MAX: 'prilohy.max',
    PRILOHY_NAZEV_EXIST: 'prilohy.nazev.exist',
    PRILOHY_NAZEV_DUPLICITA: 'prilohy.nazev.duplicita',
    PRILOHY_NEPOVOLENY_POCET: 'prilohy.nepovoleny.pocet',
    PRILOHA_TYP_OBJEKTU_REKLAMACE_ELEKTRINA: 'priloha.typ.objektu.REKLAMACE_ELEKTRINA',
    PRILOHA_TYP_OBJEKTU_REKLAMACE_PLYN: 'priloha.typ.objektu.REKLAMACE_PLYN',
    PRILOHA_TYP_OBJEKTU_REKLAMACE_KOMENTAR: 'priloha.typ.objektu.REKLAMACE_KOMENTAR',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_RAMCOVA_SMLOUVA: 'priloha.typ.objektu.ZADOST_ELEKTRINA_RAMCOVA_SMLOUVA',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_SOP_PREPIS: 'priloha.typ.objektu.ZADOST_ELEKTRINA_SOP_PREPIS',
    PRILOHA_TYP_OBJEKTU_ZADOST_PLYN_SOP_PREPIS: 'priloha.typ.objektu.ZADOST_PLYN_SOP_PREPIS',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_SOP_ZMENA_PRIKONU_JISTICE: 'priloha.typ.objektu.ZADOST_ELEKTRINA_SOP_ZMENA_PRIKONU_JISTICE',
    PRILOHA_TYP_OBJEKTU_ZADOST_SOP_NOVE_PRIPOJENI: 'priloha.typ.objektu.ZADOST_SOP_NOVE_PRIPOJENI',
    PRILOHA_TYP_OBJEKTU_DODATEK_PLYN: 'priloha.typ.objektu.DODATEK_PLYN',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_ZADOST_O_REGISTRACI: 'priloha.typ.objektu.POZADAVEK_ZADOST_O_REGISTRACI',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_OSTATNI: 'priloha.typ.objektu.POZADAVEK_OSTATNI',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_CHYBA_SYSTEMU: 'priloha.typ.objektu.POZADAVEK_CHYBA_SYSTEMU',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_NAVRH_NA_VYLEPSENI: 'priloha.typ.objektu.POZADAVEK_NAVRH_NA_VYLEPSENI',
    PRILOHA_TYP_OBJEKTU_REVIZNI_ZPRAVA_NN: 'priloha.typ.objektu.REVIZNI_ZPRAVA_NN',
    PRILOHA_TYP_OBJEKTU_REVIZNI_ZPRAVA_VN_VVN: 'priloha.typ.objektu.REVIZNI_ZPRAVA_VN_VVN',
    FORM_FILEINPUT_ARIA: 'form.fileInput.aria',
    FORM_FILEINPUT_MESSAGE1: 'form.fileInput.message1',
    FORM_FILEINPUT_MESSAGE2: 'form.fileInput.message2',
    FORM_DATEPICKER_BUTTON_DNES: 'form.datepicker.button.dnes',
    FORM_DATEPICKER_BUTTON_ZRUSIT: 'form.datepicker.button.zrusit',
    FORM_DATEPICKER_BUTTON_OK: 'form.datepicker.button.ok',
    FORM_DATEPICKER_TOOLTIP_SMAZAT: 'form.datepicker.tooltip.smazat',
    FORM_DATEPICKER_TOOLTIP_KALENDAR: 'form.datepicker.tooltip.kalendar',
    FORM_DATEPICKER_TOOLTIP_INFINITY: 'form.datepicker.tooltip.infinity',
    ERROR_HTTP_NOT_FOUND_TITLE: 'error.http.not.found.title',
    ERROR_HTTP_NOT_FOUND_CONTENT: 'error.http.not.found.content',
    ERROR_HTTP_INTERNAL_SERVER_ERROR_TITLE: 'error.http.internal.server.error.title',
    ERROR_HTTP_INTERNAL_SERVER_ERROR_CONTENT1: 'error.http.internal.server.error.content1',
    ERROR_HTTP_INTERNAL_SERVER_ERROR_CONTENT2: 'error.http.internal.server.error.content2',
    ERROR_HTTP_UNEXPECTED_TITLE: 'error.http.unexpected.title',
    ERROR_HTTP_UNEXPECTED_CONTENT1: 'error.http.unexpected.content1',
    ERROR_HTTP_UNEXPECTED_CONTENT2: 'error.http.unexpected.content2',
    ERROR_HTTP_HINT: 'error.http.hint',
    SUPPORT_EMAIL: 'support.email',
    ENVBADGE_TEXT: 'EnvBadge.text',
    DETAIL_PRILOHY: 'detail.prilohy',
};
