import {NewAccountsFilterModel, RequestsFilterModel} from '../model';

export enum RequestSidePanelSelected {
    UZIVATELE_V_PROCESU = 'UZIVATELE_V_PROCESU',
    POZADAVKY_V_RESENI = 'POZADAVKY_V_RESENI',
    POZADAVKY_CEKA_NA_DOPLNENI = 'POZADAVKY_CEKA_NA_DOPLNENI',
    POZADAVKY_SCHVALENE = 'POZADAVKY_SCHVALENE',
    POZADAVKY_ZAMITNUTE = 'POZADAVKY_ZAMITNUTE',
    POZADAVKY_STORNOVANE = 'POZADAVKY_STORNOVANE',
    POZADAVKY_VSE = 'POZADAVKY_VSE',
}

export interface RequestsStore {
    /**
     * Currently active filter
     */
    readonly filter: RequestsFilterModel;

    /**
     * Filter for new accounts
     */
    readonly newAccountsFilter: NewAccountsFilterModel;

    /**
     * Selected left panel
     */
    readonly selectedPanel: RequestSidePanelSelected;
}
