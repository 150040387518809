import Cookie from 'js-cookie';

const name = 'x-access-token-admin';

export const AuthService = {
    LOGIN_FORM_NAME: 'authLoginForm',

    /**
     * Set auth token to cookie
     *
     * @param token Token
     * @param expiresIn Expiration time in seconds, undefined for session cookie
     */
    setCookie(token: string, expiresIn?: number): void {
        // NOTE: We set cookie to be secure only when we are on https - this is for devs computers
        Cookie.set(name, token, {expires: expiresIn ? expiresIn / 86400 : undefined, secure: window.location.protocol === 'https:'});
    },

    /**
     * Clear auth cookie (= logout)
     */
    removeCookie() {
        Cookie.remove(name, {secure: window.location.protocol === 'https:'});
    },

    isLogged(): boolean {
        return !!Cookie.get(name);
    },
};
