/**
 * Model for account group filter
 */
import {UcetSkupinaTyp} from '@eon.cz/apollo13-graphql-admin';

export const UCET_SKUPINA_TYP_VSE = 'vse';

export interface AccountGroupFilterModel {
    readonly fulltext?: string;
    readonly ic?: string;
    readonly cislo?: string;
    readonly eanEic?: string;
    readonly eanEicOm?: string;
    readonly predchoziSmlouvy?: boolean;
    readonly typ: UcetSkupinaTyp[] | 'vse';
}
