import {Action, handleActions} from 'redux-actions';
import {AdminsActionNames} from '../actions';
import {AdminsFilterModel} from '../model';
import {AdminsStore} from '../store';

const initialData = {
    filter: {
        onlyActive: true,
    },
} as AdminsStore;

export const AdminsReducer = handleActions<AdminsStore, any>(
    {
        [AdminsActionNames.SET_FILTER]: (store: AdminsStore, {payload}: Action<AdminsFilterModel>) => ({...store, filter: payload}),
        [AdminsActionNames.RESET_FILTER]: (store: AdminsStore) => ({...store, filter: {...store.filter, fulltext: ''}}),
    },
    initialData,
);
