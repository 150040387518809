import {ApolloClient} from '@apollo/client';
import {NotificationsActionNames} from '@eon.cz/apollo13-frontend-common/lib/notification/reducer';
import axios from 'axios';
import {get} from 'lodash';
import Router from 'next/router';
import {createElement} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {AnyAction, bindActionCreators, Dispatch} from 'redux';
import {Action} from 'redux-actions';
import {Lang} from '../../../Lang';
import {RootActionType} from '../../../RootActionType';
import {AuthService} from '../service';

const PREFIX = 'AUTH_';

export const AuthActionType = {
    SET_LOGIN_IN_PROGESS: `${PREFIX}SET_LOGIN_IN_PROGESS`,
};

export interface AuthAction {
    readonly login: (code: string) => void;
    readonly logout: (client: ApolloClient<any>) => void;
    readonly closeNotification: () => void;
}

export const AuthActionImpl = {
    logout: (client: ApolloClient<any>) => (dispatch: Dispatch<any>) => {
        AuthService.removeCookie();
        Router.push({pathname: '/login'}).then(() => {
            // Reset cache after we transitioned to the login page to not cause any components to rerender
            client.cache.reset();

            // Reset redux store
            dispatch({type: RootActionType.LOGOUT, payload: true});
        });
    },
};

export const useAuthActionCreator = (): AuthAction => {
    const dispatch: Dispatch<Action<AuthAction> | AnyAction> = useDispatch();
    return {
        login: (code) => {
            dispatch({type: AuthActionType.SET_LOGIN_IN_PROGESS, payload: true});

            const uri = '/authenticate';

            axios
                .post(uri, {code})
                .then((res) => {
                    AuthService.setCookie(res.data.token, res.data.expiresIn);
                    Router.push({pathname: '/'}).then(() => {
                        dispatch({type: AuthActionType.SET_LOGIN_IN_PROGESS, payload: false});
                    });
                })
                .catch((err) => {
                    const error = get(err, 'response.data');

                    // Určíme hlášku pro uživatele podle kódu
                    let messageId = Lang.LOGIN_ERROR;
                    if (!!error && error.code === 'DEACTIVATED_ACCOUNT') {
                        messageId = Lang.LOGIN_ERROR_DEACTIVATED;
                    }

                    if (!!error && error.code === 'ACCOUNT_TEMPORARILY_BLOCKED') {
                        messageId = Lang.LOGIN_ERROR_LIMIT;
                    }

                    if (!!error && error.code === 'INVALID_TOKEN') {
                        messageId = Lang.LOGIN_ERROR_INVALID_TOKEN;
                    }

                    if (!!error && error.code === 'NOT_EXISTING_ACCOUNT') {
                        messageId = Lang.LOGIN_ERROR_NOT_EXISTING_ACCOUNT;
                    }

                    if (!!error && error.code === 'ECONNREFUSED') {
                        messageId = Lang.LOGIN_ERROR;
                    }

                    dispatch({
                        type: NotificationsActionNames.ADD_NOTICE,
                        payload: {type: 'ERROR', text: createElement(FormattedMessage, {id: messageId})},
                    });
                    Router.push({pathname: '/login'}).then(() => {
                        dispatch({type: AuthActionType.SET_LOGIN_IN_PROGESS, payload: false});
                    });
                });
        },

        logout: (client: ApolloClient<any>) => {
            AuthService.removeCookie();
            Router.push({pathname: '/login'}).then(() => {
                // Reset cache after we transitioned to the login page to not cause any components to rerender
                client.cache.reset();

                // Reset redux store
                dispatch({type: RootActionType.LOGOUT, payload: true});
            });
        },

        closeNotification: () => dispatch({type: NotificationsActionNames.CLOSE_NOTICE}),
    };
};

export const AuthActionCreator = (dispatch: Dispatch<any>) => bindActionCreators({...AuthActionImpl}, dispatch);
