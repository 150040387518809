import {Action, handleActions} from 'redux-actions';
import {AccountGroupFilterModel, UCET_SKUPINA_TYP_VSE} from '../model';
import {AccountGroupsStore} from '../store';

export const PREFIX = 'ACCOUNT_GROUPS_';

export const AccountGroupsActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
    SET_AKTIVNI: `${PREFIX}SET_AKTIVNI`,
    SET_ONLY_ADMINS: `${PREFIX}SET_ONLY_ADMINS`,
    SET_TAB: `${PREFIX}SET_TAB`,
};

export const ACCOUNT_GROUP_INITIAL_FILTER: AccountGroupFilterModel = {
    typ: UCET_SKUPINA_TYP_VSE,
};

const initialData = {
    filter: ACCOUNT_GROUP_INITIAL_FILTER,
    aktivni: true,
    accountsFilter: {
        onlyAdmins: false,
    },
    activeTab: 0,
} as AccountGroupsStore;

export const AccountGroupsReducer = handleActions<AccountGroupsStore, any>(
    {
        [AccountGroupsActionNames.SET_FILTER]: (store: AccountGroupsStore, {payload}: Action<AccountGroupFilterModel>) => ({...store, filter: payload}),
        [AccountGroupsActionNames.RESET_FILTER]: (store: AccountGroupsStore) => ({...store, filter: ACCOUNT_GROUP_INITIAL_FILTER}),
        [AccountGroupsActionNames.SET_AKTIVNI]: (store: AccountGroupsStore, {payload}: Action<boolean>) => ({...store, aktivni: payload}),
        [AccountGroupsActionNames.SET_ONLY_ADMINS]: (store: AccountGroupsStore, {payload}: Action<boolean>) => ({
            ...store,
            accountsFilter: {...store.accountsFilter, onlyAdmins: payload},
        }),
        [AccountGroupsActionNames.SET_TAB]: (store: AccountGroupsStore, {payload}: Action<number>): AccountGroupsStore => ({
            ...store,
            activeTab: payload,
        }),
    },
    initialData,
);
