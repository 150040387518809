import {Action, handleActions} from 'redux-actions';
import {UCET_SKUPINA_TYP_VSE} from '../../AccountGroups/model';
import {AccountFilterModel, AccountPristupyFilterModel, AccountSpravceZmocnenecFilterModel} from '../model';
import {AccountsStore} from '../store';

declare const process: any;

export const PREFIX = 'ACCOUNTS_';

export const AccountsActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
    SET_SPRAVCE_ZMOCNENEC_FILTER: `${PREFIX}SET_SPRAVCE_ZMOCNENEC_FILTER`,
    RESET_SPRAVCE_ZMOCNENEC_FILTER: `${PREFIX}RESET_SPRAVCE_ZMOCNENEC_FILTER`,
    SET_AKTIVNI: `${PREFIX}SET_AKTIVNI`,
    SET_PRISTUPY_FILTER: `${PREFIX}SET_PRISTUPY_FILTER`,
    RESET_PRISTUPY_FILTER: `${PREFIX}RESET_PRISTUPY_FILTER`,
    SET_PRISTUPY_SPRAVCE_ZMOCNENEC_FILTER: `${PREFIX}SET_PRISTUPY_SPRAVCE_ZMOCNENEC_FILTER`,
    RESET_PRISTUPY_SPRAVCE_ZMOCNENEC_FILTER: `${PREFIX}RESET_PRISTUPY_SPRAVCE_ZMOCNENEC_FILTER`,
    SET_TAB: `${PREFIX}SET_TAB`,
};

export const UCET_INITIAL_FILTER: AccountFilterModel = {
    skupina: null,
    typSkupiny: UCET_SKUPINA_TYP_VSE,
};

export const SPRAVCE_ZMOCNENEC_INITIAL_FILTER: AccountSpravceZmocnenecFilterModel = {
    fulltext: undefined,
    skupina: null,
    typSkupiny: UCET_SKUPINA_TYP_VSE,
};

const initialData = {
    activeTab: 0,
    filter: UCET_INITIAL_FILTER,
    filterSpravceZmocnenec: SPRAVCE_ZMOCNENEC_INITIAL_FILTER,
    aktivni: true,
    pristupyFilter: {},
    pristupySpravceZmocnenecFilter: {},
    selfcareFrontendUrl: process.env.SELFCARE_FRONTEND_URL,
} as AccountsStore;

export const AccountsReducer = handleActions<AccountsStore, any>(
    {
        [AccountsActionNames.SET_FILTER]: (store: AccountsStore, {payload}: Action<AccountFilterModel>) => ({...store, filter: payload}),
        [AccountsActionNames.RESET_FILTER]: (store: AccountsStore) => ({...store, filter: UCET_INITIAL_FILTER}),
        [AccountsActionNames.SET_SPRAVCE_ZMOCNENEC_FILTER]: (store: AccountsStore, {payload}: Action<AccountSpravceZmocnenecFilterModel>) => ({
            ...store,
            filterSpravceZmocnenec: payload,
        }),
        [AccountsActionNames.RESET_SPRAVCE_ZMOCNENEC_FILTER]: (store: AccountsStore) => ({...store, filterSpravceZmocnenec: SPRAVCE_ZMOCNENEC_INITIAL_FILTER}),
        [AccountsActionNames.SET_AKTIVNI]: (store: AccountsStore, {payload}: Action<boolean | undefined>) => ({...store, aktivni: payload}),
        [AccountsActionNames.SET_PRISTUPY_FILTER]: (store: AccountsStore, {payload}: Action<AccountPristupyFilterModel>) => ({
            ...store,
            pristupyFilter: payload,
        }),
        [AccountsActionNames.RESET_PRISTUPY_FILTER]: (store: AccountsStore) => ({...store, pristupyFilter: {}}),
        [AccountsActionNames.SET_PRISTUPY_SPRAVCE_ZMOCNENEC_FILTER]: (store: AccountsStore, {payload}: Action<AccountPristupyFilterModel>) => ({
            ...store,
            pristupySpravceZmocnenecFilter: payload,
        }),
        [AccountsActionNames.RESET_PRISTUPY_SPRAVCE_ZMOCNENEC_FILTER]: (store: AccountsStore) => ({...store, pristupySpravceZmocnenecFilter: {}}),
        [AccountsActionNames.SET_TAB]: (store: AccountsStore, {payload}: Action<number>): AccountsStore => ({
            ...store,
            activeTab: payload,
        }),
    },
    initialData,
);
