import {Dispatch, AnyAction, bindActionCreators} from 'redux';

const PREFIX = 'LAYOUT_';

export const LayoutActionType = {
    TOGGLE_MENU: `${PREFIX}TOGGLE_MENU`,
};

export interface LayoutAction {
    toggleMenuExpanded: () => (dispatch) => void;
}

const LayoutActionImpl: LayoutAction = {
    toggleMenuExpanded: () => (dispatch) => {
        dispatch({type: LayoutActionType.TOGGLE_MENU});
    },
};

export const LayoutActionCreator = (dispatch: Dispatch<AnyAction>): LayoutAction => bindActionCreators({...LayoutActionImpl}, dispatch);
