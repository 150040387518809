import {Action, handleActions} from 'redux-actions';
import {OdstavkyDownloadActionNames} from '../actions';
import {OdstavkyDownloadLogFilterModel} from '../model';
import {OdstavkyDownloadStore} from '../store';

export const INITIAL_FILTER = {
    errorOnly: false,
    datumOd: undefined,
    datumDo: undefined,
} as OdstavkyDownloadLogFilterModel;

const initialData = {
    logFilter: INITIAL_FILTER,
} as OdstavkyDownloadStore;

export const OdstavkyDownloadReducer = handleActions<OdstavkyDownloadStore, any>(
    {
        [OdstavkyDownloadActionNames.SET_FILTER]: (store: OdstavkyDownloadStore, {payload}: Action<OdstavkyDownloadLogFilterModel>) => ({
            ...store,
            logFilter: payload,
        }),
        [OdstavkyDownloadActionNames.RESET_FILTER]: (store: OdstavkyDownloadStore) => ({...store, logFilter: INITIAL_FILTER}),
    },
    initialData,
);
