import {useDispatch} from 'react-redux';
import {AnyAction, Dispatch} from 'redux';
import {Action} from 'redux-actions';
import {RoleOpravneniFilterModel} from '../model';

const PREFIX = 'ROLE_OPRAVNENI_';

export const RoleOpravneniActionType = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    CLEAR_FILTER: `${PREFIX}CLEAR_FILTER`,
    SET_TAB: `${PREFIX}SET_TAB`,
};

type RoleOpravneniActionType = {
    clearFilter: (aktivni: boolean | undefined) => {
        type: string;
        payload: boolean | undefined;
    };
    setFilter: (
        filter: RoleOpravneniFilterModel,
        aktivni: boolean,
    ) => {
        payload: {filter: RoleOpravneniFilterModel; aktivni: boolean};
        type: string;
    };
    setTab: (tabValue: number) => {
        payload: number;
    };
};

/**
 * Akce pro reducer rolí a oprávnění
 */
export const useRoleOpravneniDispatch = (): RoleOpravneniActionType => {
    const dispatch: Dispatch<Action<RoleOpravneniFilterModel> | AnyAction> = useDispatch();
    return {
        clearFilter: (aktivni) => dispatch({type: RoleOpravneniActionType.CLEAR_FILTER, payload: aktivni}),
        setFilter: (filter: RoleOpravneniFilterModel, aktivni) => dispatch({type: RoleOpravneniActionType.SET_FILTER, payload: {filter, aktivni}}),
        setTab: (tabValue: number) => dispatch({type: RoleOpravneniActionType.SET_TAB, payload: tabValue}),
    };
};
