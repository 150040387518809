import {Action, handleActions} from 'redux-actions';
import {RequestsStore} from '../store';
import {NewAccountsFilterModel, RequestsFilterModel} from '../model';
import {RequestSidePanelSelected} from '../store/RequestsStore';

export const PREFIX = 'REQUESTS_';

export const RequestsActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
    SET_NEW_ACCOUNTS_FILTER: `${PREFIX}SET_NEW_ACCOUNTS_FILTER`,
    RESET_NEW_ACCOUNTS_FILTER: `${PREFIX}RESET_NEW_ACCOUNTS_FILTER`,
    SET_SELECTED_PANEL: `${PREFIX}SET_SELECTED_PANEL`,
};

export const REQUESTS_INITIAL_FILTER: RequestsFilterModel = {
    fulltext: '',
    type: 'ALL',
    states: {
        V_RESENI: true,
        CEKA_NA_DOPLNENI: true,
        SCHVALENO: true,
        ZAMITNUTO: true,
        STORNO: true,
    },
};

export const NEW_ACCOUNTS_INITIAL_FILTER: NewAccountsFilterModel = {
    fulltext: undefined,
};

const initialData = {
    filter: REQUESTS_INITIAL_FILTER,
    newAccountsFilter: NEW_ACCOUNTS_INITIAL_FILTER,
    selectedPanel: RequestSidePanelSelected.UZIVATELE_V_PROCESU,
} as RequestsStore;

export const RequestsReducer = handleActions<RequestsStore, any>(
    {
        [RequestsActionNames.SET_FILTER]: (store: RequestsStore, {payload}: Action<RequestsFilterModel>) => ({...store, filter: payload}),
        [RequestsActionNames.RESET_FILTER]: (store: RequestsStore) => ({...store, filter: REQUESTS_INITIAL_FILTER}),
        [RequestsActionNames.SET_NEW_ACCOUNTS_FILTER]: (store: RequestsStore, {payload}: Action<NewAccountsFilterModel>) => ({
            ...store,
            newAccountsFilter: payload,
        }),
        [RequestsActionNames.RESET_NEW_ACCOUNTS_FILTER]: (store: RequestsStore) => ({...store, newAccountsFilter: NEW_ACCOUNTS_INITIAL_FILTER}),
        [RequestsActionNames.SET_SELECTED_PANEL]: (store: RequestsStore, {payload}: Action<RequestSidePanelSelected>) => ({
            ...store,
            selectedPanel: payload,
            filter: REQUESTS_INITIAL_FILTER,
        }),
    },
    initialData,
);
