import {format} from 'date-fns';
import {Action, handleActions} from 'redux-actions';
import {
    ReportyAuditLogFilterModel,
    ReportyKeStazeniFilterModel,
    ReportyOdeslaneFilterModel,
    ReportyProvozniFilterModel,
    ReportyRozpracovaneFilterModel,
    ReportySopWebFilterModel,
} from '../model';
import {ReportyVyjadrovaciLinkaFilterModel} from '../model/ReportyVyjadrovaciLinkaFilterModel';
import {ReportyStore} from '../store';

export const PREFIX = 'REPORTY_';

export const REPORTY_KE_STAZENI_STAV_VSE = 'vse';
export const REPORTY_KE_STAZENI_TYP_VSE = 'vse';

export const ReportyActionNames = {
    SET_ODESLANE_FILTER: `${PREFIX}SET_ODESLANE_FILTER`,
    RESET_ODESLANE_FILTER: `${PREFIX}RESET_ODESLANE_FILTER`,
    SET_ROZPRACOVANE_FILTER: `${PREFIX}SET_ROZPRACOVANE_FILTER`,
    RESET_ROZPRACOVANE_FILTER: `${PREFIX}RESET_ROZPRACOVANE_FILTER`,
    SET_KE_STAZENI_FILTER: `${PREFIX}SET_KE_STAZENI_FILTER`,
    RESET_KE_STAZENI_FILTER: `${PREFIX}RESET_KE_STAZENI_FILTER`,
    SET_PROVOZNI_FILTER: `${PREFIX}SET_PROVOZNI_FILTER`,
    RESET_PROVOZNI_FILTER: `${PREFIX}RESET_PROVOZNI_FILTER`,
    SET_AUDIT_LOG_FILTER: `${PREFIX}SET_AUDIT_LOG_FILTER`,
    RESET_AUDIT_LOG_FILTER: `${PREFIX}RESET_AUDIT_LOG_FILTER`,
    SET_SOP_WEB_FILTER: `${PREFIX}SET_SOP_WEB_FILTER`,
    RESET_SOP_WEB_FILTER: `${PREFIX}RESET_SOP_WEB_FILTER`,
    SET_VYJADROVACI_LINKA_FILTER: `${PREFIX}SET_VYJADROVACI_LINKA_FILTER`,
    RESET_VYJADROVACI_LINKA_FILTER: `${PREFIX}RESET_VYJADROVACI_LINKA_FILTER`,
    SET_REPORTY_TAB: `${PREFIX}SET_REPORTY_TAB`,
    SET_REPORTY_TAB_MAIN: `${PREFIX}SET_REPORTY_TAB_MAIN`,
};

export const initialData = {
    activeTab: undefined,
    activeTabMain: 0,
    odeslane: {
        filter: {
            odeslanoOd: null,
            odeslanoDo: null,
        } as ReportyOdeslaneFilterModel,
    },
    rozpracovane: {
        filter: {
            vytvorenoOd: null,
            vytvorenoDo: null,
        } as ReportyRozpracovaneFilterModel,
    },
    keStazeni: {
        filter: {
            typ: REPORTY_KE_STAZENI_STAV_VSE,
            stav: REPORTY_KE_STAZENI_TYP_VSE,
        } as ReportyKeStazeniFilterModel,
    },
    provozni: {
        filter: {
            cisloReportu: undefined,
            vytvorenoOd: null,
            vytvorenoDo: null,
        } as ReportyProvozniFilterModel,
    },
    auditLog: {
        filter: {
            portal: 'SELFCARE',
            vytvorenoOd: undefined,
            vytvorenoDo: format(new Date(), 'yyyy-MM-dd'),
            uzivatel: null,
            fulltextOperace: undefined,
        } as ReportyAuditLogFilterModel,
    },
    sopWeb: {
        filter: {}, // TODO
    },
    vyjadrovaciLinka: {
        filter: {}, // TODO
    },
} as ReportyStore;

export const ReportyReducer = handleActions<ReportyStore, any>(
    {
        [ReportyActionNames.SET_ODESLANE_FILTER]: (state: ReportyStore, {payload}: Action<ReportyOdeslaneFilterModel>) => ({
            ...state,
            odeslane: {filter: payload},
        }),
        [ReportyActionNames.RESET_ODESLANE_FILTER]: (state: ReportyStore) => ({...state, odeslane: {filter: initialData.odeslane.filter}}),
        [ReportyActionNames.SET_ROZPRACOVANE_FILTER]: (state: ReportyStore, {payload}: Action<ReportyRozpracovaneFilterModel>) => ({
            ...state,
            rozpracovane: {filter: payload},
        }),
        [ReportyActionNames.RESET_ROZPRACOVANE_FILTER]: (store: ReportyStore) => ({...store, rozpracovane: {filter: initialData.rozpracovane.filter}}),
        [ReportyActionNames.SET_KE_STAZENI_FILTER]: (state: ReportyStore, {payload}: Action<ReportyKeStazeniFilterModel>) => ({
            ...state,
            keStazeni: {filter: payload},
        }),
        [ReportyActionNames.RESET_KE_STAZENI_FILTER]: (store: ReportyStore) => ({...store, keStazeni: {filter: initialData.keStazeni.filter}}),
        [ReportyActionNames.SET_PROVOZNI_FILTER]: (state: ReportyStore, {payload}: Action<ReportyProvozniFilterModel>) => ({
            ...state,
            provozni: {filter: payload},
        }),
        [ReportyActionNames.RESET_PROVOZNI_FILTER]: (store: ReportyStore) => ({...store, provozni: {filter: initialData.provozni.filter}}),
        [ReportyActionNames.RESET_AUDIT_LOG_FILTER]: (store: ReportyStore) => ({...store, auditLog: {filter: initialData.auditLog.filter}}),
        [ReportyActionNames.SET_AUDIT_LOG_FILTER]: (state: ReportyStore, {payload}: Action<ReportyAuditLogFilterModel>) => ({
            ...state,
            auditLog: {filter: payload},
        }),
        [ReportyActionNames.RESET_SOP_WEB_FILTER]: (store: ReportyStore) => ({...store, sopWeb: {filter: initialData.sopWeb.filter}}),
        [ReportyActionNames.SET_SOP_WEB_FILTER]: (state: ReportyStore, {payload}: Action<ReportySopWebFilterModel>) => ({
            ...state,
            sopWeb: {filter: payload},
        }),
        [ReportyActionNames.RESET_VYJADROVACI_LINKA_FILTER]: (store: ReportyStore) => ({
            ...store,
            vyjadrovaciLinka: {filter: initialData.vyjadrovaciLinka.filter},
        }),
        [ReportyActionNames.SET_VYJADROVACI_LINKA_FILTER]: (state: ReportyStore, {payload}: Action<ReportyVyjadrovaciLinkaFilterModel>) => ({
            ...state,
            vyjadrovaciLinka: {filter: payload},
        }),
        [ReportyActionNames.SET_REPORTY_TAB]: (store: ReportyStore, {payload}: Action<string>): ReportyStore => ({
            ...store,
            activeTab: payload,
        }),
        [ReportyActionNames.SET_REPORTY_TAB_MAIN]: (store: ReportyStore, {payload}: Action<number>): ReportyStore => ({
            ...store,
            activeTabMain: payload,
        }),
    },
    initialData,
);
