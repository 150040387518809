import {ApolloProvider} from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import {CacheProvider} from '@emotion/react';
import {CssBaseline} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {ThemeProvider} from '@material-ui/styles';
import cs from 'date-fns/locale/cs';
import App, {AppProps} from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import {useEffect, useMemo} from 'react';
import {createIntl, createIntlCache, CustomFormats, RawIntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import {cache, useTheme} from '../client/common/util/theme';
import {useApollo} from '../client/with/withApolloProvider';
import {useStore} from '../client/with/withReduxStore';
import Czech from '../lang/cs.json';

/* Root stylesheet */
import '../public/static/fonts/BrixSans/stylesheet.css';
import '../public/static/nprogress/nprogress.css';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

type Props = AppProps & {
    readonly locale: string;
    readonly formats: CustomFormats | undefined;
};

const MyApp = (props: Props) => {
    const {Component, pageProps, locale, formats} = props;

    const store = useStore();

    const {theme} = useTheme();

    const apolloClient = useApollo(store.dispatch);

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale) {
            case 'cs':
                return Czech;
                break;
            default:
                return Czech;
        }
    }, [shortLocale]);

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }, []);

    return (
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                    <CacheProvider value={cache}>
                        <ThemeProvider theme={theme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
                                <CssBaseline />
                                <Head>
                                    <title>Admin portál</title>
                                </Head>
                                <Component {...pageProps} />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </CacheProvider>
                </RawIntlProvider>
            </ApolloProvider>
        </Provider>
    );
};

const getInitialProps: typeof App.getInitialProps = async (appContext) => {
    const {ctx} = appContext;
    const [appProps] = await Promise.all([App.getInitialProps(appContext)]);
    const formats = getFormats();
    return {
        ...appProps,
        locale: getLocales(ctx?.req?.headers['accept-language']),
        formats,
    };
};

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

MyApp.getInitialProps = getInitialProps;

export default MyApp;
