import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {AnyAction, bindActionCreators} from 'redux';
import {HdoImportLogFilterModel} from '../model';

export const PREFIX = 'HDO_';

export const HdoActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
};

export interface HdoActions {
    setFilter: (filter: HdoImportLogFilterModel) => AnyAction;
    resetFilter: () => AnyAction;
}

const HdoActionsImpl: HdoActions = {
    setFilter: (filter: HdoImportLogFilterModel) => ({type: HdoActionNames.SET_FILTER, payload: filter}),
    resetFilter: () => ({type: HdoActionNames.RESET_FILTER}),
};

export const useHdoActions = () => {
    const dispatch = useDispatch();
    return useMemo(() => bindActionCreators({...HdoActionsImpl}, dispatch), [dispatch]);
};
