import {Action, handleActions} from 'redux-actions';
import {OdstavkyUctyActionNames} from '../actions';
import {OdstavkyUctyFilterModel} from '../model';
import {OdstavkyUctyStore} from '../store';

const initialData = {
    filter: {
        onlyActive: true,
    },
} as OdstavkyUctyStore;

export const OdstavkyUctyReducer = handleActions<OdstavkyUctyStore, any>(
    {
        [OdstavkyUctyActionNames.SET_FILTER]: (store: OdstavkyUctyStore, {payload}: Action<OdstavkyUctyFilterModel>) => ({...store, filter: payload}),
        [OdstavkyUctyActionNames.RESET_FILTER]: (store: OdstavkyUctyStore) => ({...store, filter: {...store.filter, fulltext: ''}}),
    },
    initialData,
);
