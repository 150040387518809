import {Action, handleActions} from 'redux-actions';

import {OpenApiPristupyTokenyFilterModel} from '../model';
import {OpenApiStore} from '../store';

export const PREFIX = 'OPEN_API_';

export const OpenApiActionNames = {
    PRISTUPY_TOKENY_SET_FILTER: `${PREFIX}PRISTUPY_TOKENY_SET_FILTER`,
    PRISTUPY_TOKENY_RESET_FILTER: `${PREFIX}PRISTUPY_TOKENY_RESET_FILTER`,
};

const initialData = {
    pristupyTokeny: {
        filter: {
            aktivni: true,
        } as OpenApiPristupyTokenyFilterModel,
    },
};

export const OpenApiReducer = handleActions<OpenApiStore, any>(
    {
        [OpenApiActionNames.PRISTUPY_TOKENY_SET_FILTER]: (state: OpenApiStore, {payload}: Action<OpenApiPristupyTokenyFilterModel>) => ({
            ...state,
            pristupyTokeny: {filter: payload},
        }),
        [OpenApiActionNames.PRISTUPY_TOKENY_RESET_FILTER]: (state: OpenApiStore) => ({
            ...state,
            pristupyTokeny: {...state.pristupyTokeny, filter: initialData.pristupyTokeny.filter},
        }),
    },
    initialData,
);
