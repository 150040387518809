import {AnyAction, bindActionCreators, Dispatch} from 'redux';
import {AdminsFilterModel} from '../model';

export const PREFIX = 'ADMINS_';

export const AdminsActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
};

export interface AdminsActions {
    setFilter: (filter: AdminsFilterModel) => AnyAction;
    resetFilter: () => AnyAction;
}

const AdminsActionsImpl: AdminsActions = {
    setFilter: (filter: AdminsFilterModel) => ({type: AdminsActionNames.SET_FILTER, payload: filter}),
    resetFilter: () => ({type: AdminsActionNames.RESET_FILTER}),
};

export const AdminsActionCreator = (dispatch: Dispatch<AnyAction>) => bindActionCreators({...AdminsActionsImpl}, dispatch);
