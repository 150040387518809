import {handleActions} from 'redux-actions';
import {LayoutActionType as ActionType} from '../actions';
import {LayoutStore as Store} from '../store';

const initialState = {
    menuExpanded: false,
} as Store;

export const LayoutReducer = handleActions<Store, any>(
    {
        [ActionType.TOGGLE_MENU]: (state: Store): Store => ({...state, menuExpanded: !state.menuExpanded}),
    },
    initialState,
);
