import {Action, handleActions} from 'redux-actions';
import {HdoActionNames} from '../actions';
import {HdoImportLogFilterModel} from '../model';
import {HdoStore} from '../store';

export const INITIAL_FILTER = {
    errorOnly: false,
    datumOd: undefined,
    datumDo: undefined,
} as HdoImportLogFilterModel;

const initialData = {
    importLogFilter: INITIAL_FILTER,
} as HdoStore;

export const HdoReducer = handleActions<HdoStore, any>(
    {
        [HdoActionNames.SET_FILTER]: (store: HdoStore, {payload}: Action<HdoImportLogFilterModel>) => ({
            ...store,
            importLogFilter: payload,
        }),
        [HdoActionNames.RESET_FILTER]: (store: HdoStore) => ({...store, importLogFilter: INITIAL_FILTER}),
    },
    initialData,
);
