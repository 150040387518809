import {Action, handleActions} from 'redux-actions';
import {IdmAdminStore} from '../store';
import {IdmAdminAplikaceFilterModel, IdmAdminTokenFilterModel} from '../model';

export const PREFIX = 'IDM_ADMIN_';

export const IdmAdminActionNames = {
    SET_TAB: `${PREFIX}SET_TAB`,
    RESET_APLICATION_FILTER: `${PREFIX}RESET_APLICATION_FILTER`,
    SET_APLICATION_FILTER: `${PREFIX}SET_APLICATION_FILTER`,
    RESET_TOKEN_FILTER: `${PREFIX}RESET_TOKEN_FILTER`,
    SET_TOKEN_FILTER: `${PREFIX}SET_TOKEN_FILTER`,
};

export const initialData = {
    aplikace: {
        filter: {
            pouzeAktivni: false,
        },
        isCreating: false,
    },
    tokeny: {
        filter: {
            pouzeAktivni: false,
        },
    },
    activeTab: 0,
};

export const IdmAdminReducer = handleActions<IdmAdminStore, any>(
    {
        [IdmAdminActionNames.SET_TAB]: (store: IdmAdminStore, {payload}: Action<number>): IdmAdminStore => ({
            ...store,
            activeTab: payload,
        }),
        [IdmAdminActionNames.RESET_APLICATION_FILTER]: (store: IdmAdminStore): IdmAdminStore => ({
            ...store,
            aplikace: {
                ...store.aplikace,
                filter: initialData.aplikace.filter,
            },
        }),
        [IdmAdminActionNames.SET_APLICATION_FILTER]: (store: IdmAdminStore, {payload}: Action<IdmAdminAplikaceFilterModel>): IdmAdminStore => ({
            ...store,
            aplikace: {
                ...store.aplikace,
                filter: payload,
            },
        }),
        [IdmAdminActionNames.RESET_TOKEN_FILTER]: (store: IdmAdminStore): IdmAdminStore => ({
            ...store,
            tokeny: {
                filter: initialData.tokeny.filter,
            },
        }),
        [IdmAdminActionNames.SET_TOKEN_FILTER]: (store: IdmAdminStore, {payload}: Action<IdmAdminTokenFilterModel>): IdmAdminStore => ({
            ...store,
            tokeny: {
                filter: payload,
            },
        }),
    },
    initialData,
);
