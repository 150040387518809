import {Action, handleActions} from 'redux-actions';
import {AuthActionType as ActionType} from '../actions';
import {AuthStore as Store} from '../store';

const initialState = {
    loginInProgress: false,
} as Store;

export const AuthReducer = handleActions<Store, any>(
    {
        [ActionType.SET_LOGIN_IN_PROGESS]: (state: Store, {payload}: Action<boolean>): Store => ({
            ...state,
            loginInProgress: payload === undefined ? false : payload,
        }),
    },
    initialState,
);
