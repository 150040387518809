import createCache from '@emotion/cache';
import {createTheme} from '@material-ui/core';
import {PaletteOptions} from '@material-ui/core/styles/createPalette';
import {useEffect, useMemo, useState} from 'react';

export const cache = createCache({key: 'css', prepend: true});

export const AppTheme = {
    DARK: 'dark',
    LIGHT: 'light',
} as const;

export type AppThemeType = keyof typeof AppTheme;

export const palette: PaletteOptions = {
    type: AppTheme.LIGHT,
    primary: {
        main: '#e13019',
        light: '#ff5f3a',
        dark: '#b60000',
        contrastText: '#fff',
    },
    secondary: {
        main: '#1200E0',
        light: '#78e0ed',
        dark: '#261B62',
        contrastText: '#fff',
    },
    error: {
        main: '#e13019',
    },
    grey: {
        '500': '#8A8A8E',
    },
    custom: {
        green: {
            main: '#15C851',
            light: '#80e27e',
            dark: '#087f23',
            contrastText: '#fff',
        },
        orange: {
            main: '#ff9800',
            light: '#ffc947',
            dark: '#c66900',
            contrastText: '#000',
        },
        indigo: {
            main: '#3f51b5',
            light: '#757de8',
            dark: '#002984',
            contrastText: '#fff',
        },
        brown: {
            main: '#795548',
            light: '#a98274',
            dark: '#4b2c20',
            contrastText: '#fff',
        },
    },
    background: {
        default: '#f1f1f1',
    },
};

export const useTheme = () => {
    const [preference, setPreference] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState<string | null>(AppTheme.LIGHT);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            setPreference(prefersDarkMode);
            const isModeInLocalStorage = localStorage.getItem('theme');
            !!isModeInLocalStorage && setDarkMode(isModeInLocalStorage);
        }
    }, []);

    const changeTheme = (theme: AppThemeType) => {
        localStorage.setItem('theme', theme);
        setDarkMode(theme);
    };

    const theme = useMemo(
        () =>
            createTheme({
                typography: {
                    fontFamily: '"EONBrixSans", arial, sans-serif',
                },
                overrides: {
                    MuiButton: {
                        root: {
                            borderRadius: 20,
                        },
                        text: {
                            padding: '8px 16px',
                        },
                    },
                    MuiCheckbox: {
                        colorSecondary: {
                            '&$checked': {
                                color: (palette?.primary as any)?.main,
                            },
                        },
                    },
                    MuiIconButton: {
                        root: {
                            padding: 0,
                            width: 48,
                            height: 48,
                        },
                    },
                },
                palette: {
                    ...palette,
                    type: preference && darkMode === AppTheme.DARK ? AppTheme.DARK : AppTheme.LIGHT,
                },
            }),
        [darkMode, preference],
    );
    return {theme, changeTheme};
};
