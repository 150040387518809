import {useDispatch} from 'react-redux';
import {AnyAction, Dispatch} from 'redux';
import {Action} from 'redux-actions';
import {OdstavkyUctyFilterModel} from '../model';

export const PREFIX = 'ODSTAVKY_UCTY_';

export const OdstavkyUctyActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
};

type OdstavkyUctyActionsType = {
    setFilter: (filter: OdstavkyUctyFilterModel) => AnyAction;
    resetFilter: () => AnyAction;
};

export const useOdstavkyUctyActions = (): OdstavkyUctyActionsType => {
    const dispatch: Dispatch<Action<OdstavkyUctyFilterModel> | AnyAction> = useDispatch();
    return {
        setFilter: (filter: OdstavkyUctyFilterModel) => dispatch({type: OdstavkyUctyActionNames.SET_FILTER, payload: filter}),
        resetFilter: () => dispatch({type: OdstavkyUctyActionNames.RESET_FILTER}),
    };
};
