import {PagingSortingGridReducer} from '@eon.cz/apollo13-frontend-common/lib/components/grid';
import {NotificationsReducer} from '@eon.cz/apollo13-frontend-common/lib/notification';
import {AnyAction, combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import {AccountGroupsReducer} from './modules/AccountGroups';
import {AccountsReducer} from './modules/Accounts';
import {AdminsReducer} from './modules/Admins';
import {AuthReducer} from './modules/Auth';
import {ConfigurationReducer} from './modules/Configuration';
import {HdoReducer} from './modules/Hdo';
import {LayoutReducer} from './modules/Layout';
import {OdstavkyDownloadReducer} from './modules/OdstavkyDownload';
import {OdstavkyUctyReducer} from './modules/OdstavkyUcty';
import {OpenApiReducer} from './modules/OpenApi';
import {ReportyReducer} from './modules/Reporty';
import {RequestsReducer} from './modules/Requests';
import {RoleOpravneniReducer} from './modules/RoleOpravneni';
import {RegistrAdresImportReducer} from './modules/Ruian';
import {SpravaKontaktuDistributoraReducer} from './modules/SpravaKontaktuDistributora';
import {RootActionType} from './RootActionType';
import {Store} from './Store';
import {IdmAdminReducer} from './modules/idm/reducers';

/**
 * Root reducer je kompozice ostatnich reduceru z modules.
 * Pokud pridavate novy reducer, nezapomente pridat i jeji typovou definici do souboru ./Store.ts
 *
 * @type {Reducer<any>} root reducer
 */
export const rootReducer = (store: Store, action: AnyAction) => {
    // combine all reducers
    const combined = combineReducers({
        auth: AuthReducer,
        layout: LayoutReducer,
        form: formReducer,
        notifications: NotificationsReducer,
        requests: RequestsReducer,

        admins: AdminsReducer,
        accounts: AccountsReducer,
        accountGroups: AccountGroupsReducer,
        configuration: ConfigurationReducer,
        pagingSortingGrid: PagingSortingGridReducer,
        odstavkyUcty: OdstavkyUctyReducer,
        odstavkyDownload: OdstavkyDownloadReducer,
        hdo: HdoReducer,
        openApi: OpenApiReducer,
        reporty: ReportyReducer,
        spravaKontaktuDistributora: SpravaKontaktuDistributoraReducer,
        ruian: RegistrAdresImportReducer,
        role: RoleOpravneniReducer,
        idmAdmin: IdmAdminReducer,
    });

    if (action.type === RootActionType.LOGOUT) {
        // Reset store with passing state of undefined
        return combined(undefined, action);
    }

    return combined(store, action);
};
