import {AnyAction, bindActionCreators, Dispatch} from 'redux';
import {OdstavkyDownloadLogFilterModel} from '../model';

export const PREFIX = 'ODSTAVKY_DOWNLOAD_';

export const OdstavkyDownloadActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    RESET_FILTER: `${PREFIX}RESET_FILTER`,
};

export interface OdstavkyDownloadActions {
    setFilter: (filter: OdstavkyDownloadLogFilterModel) => AnyAction;
    resetFilter: () => AnyAction;
}

const OdstavkyDownloadActionsImpl: OdstavkyDownloadActions = {
    setFilter: (filter: OdstavkyDownloadLogFilterModel) => ({type: OdstavkyDownloadActionNames.SET_FILTER, payload: filter}),
    resetFilter: () => ({type: OdstavkyDownloadActionNames.RESET_FILTER}),
};

export const OdstavkyDownloadActionCreator = (dispatch: Dispatch<AnyAction>) => bindActionCreators({...OdstavkyDownloadActionsImpl}, dispatch);
