/* eslint-disable no-console */
import {ApolloClient, ApolloLink, split} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {getMainDefinition} from '@apollo/client/utilities';
import {NotificationsActionCreator} from '@eon.cz/apollo13-frontend-common/lib/notification/actions';
import {NotificationType} from '@eon.cz/apollo13-frontend-common/lib/notification/model';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import merge from 'deepmerge';
import {useMemo} from 'react';
import {Dispatch} from 'redux';
import {BackendEndpoints} from '../../server/BackendEndpoints';
import {GraphQLErrorResolver} from '../common/graphql/GraphQLErrorResolver';
import {AuthActionCreator} from '../modules/Auth';

declare const process: any;

// tslint:disable-next-line
const introspectionQueryResultData = require('../../node_modules/@eon.cz/apollo13-graphql-admin/lib/fragmentTypesV2.json');
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const errorLink = (dispatch: Dispatch) =>
    onError(({graphQLErrors, networkError}) => {
        if (typeof graphQLErrors === 'object' && graphQLErrors.length > 0) {
            GraphQLErrorResolver.resolveErrors(dispatch, graphQLErrors);
            // If there are GraphQL errors, ignore network
        } else {
            if (networkError) {
                const result: any = (networkError as any).result;
                if (result && result.error && result.error.code === 'DEACTIVATED_ACCOUNT') {
                    NotificationsActionCreator(dispatch).addNotification({
                        type: NotificationType.ERROR,
                        text: 'Administrátorský účet byl deaktivován',
                    });
                    AuthActionCreator(dispatch).logout(apolloClient);
                } else if (networkError && 'statusCode' in networkError && (networkError.statusCode === 403 || networkError.statusCode === 503)) {
                    AuthActionCreator(dispatch).logout(apolloClient);
                } else {
                    // Other error
                    NotificationsActionCreator(dispatch).addNotification({
                        type: NotificationType.ERROR,
                        text: 'Chyba sítě při volání api',
                    });
                }
            }
        }
    });

// declare const process: any;

// Polyfill fetch() on the server (used by @apollo/client)
if (!process.browser) {
    global.fetch = fetch;
}

export let apolloClient: ApolloClient<any>;

const createClient = (dispatch: Dispatch) => {
    const httpLink: any = createUploadLink({
        uri: `/api/${BackendEndpoints.GRAPHQL}`,
        fetchOptions: {credentials: 'same-origin', fetch},
    });

    const link = process.browser
        ? split(({query}) => {
              const definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && (definition.operation === 'query' || definition.operation === 'mutation');
          }, httpLink)
        : httpLink;

    return new ApolloClient({
        connectToDevTools: process.browser,
        ssrMode: !process.browser,
        assumeImmutableResults: false,
        link: ApolloLink.from([errorLink(dispatch), link]),
        cache: new InMemoryCache({fragmentMatcher}) as any,
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
            },
            watchQuery: {
                nextFetchPolicy(lastFetchPolicy) {
                    if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
                        return 'cache-first';
                    }
                    return lastFetchPolicy;
                },
            },
        },
    });
};

const initApollo = (initialState, dispatch: Dispatch) => {
    const _apolloClient = apolloClient ?? createClient(dispatch);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache);

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
};

export function useApollo(dispatch) {
    return useMemo(() => initApollo(null, dispatch), [dispatch]);
}
