import {useDispatch} from 'react-redux';
import {AnyAction, Dispatch} from 'redux';
import {Action} from 'redux-actions';
import {KontaktDistributoraSkupinaFilterModel, PracovniciDistribuceFilterModel, SearchKontaktuDistributoraFilterModel} from '../model';

const PREFIX = 'GLOBALNI_KONTAKTY_';
const PREFIX_SKUPINOVE = 'SKUPINOVE_KONTAKTY_';
const PREFIX_SPRAVA_DISTRIBUTORA = 'SPRAVA_DISTRIBUTORA_';
const PREFIX_PRACOVNICI_DISTRIBUCE = 'PRACOVNICI_DISTRIBUCE_';

export const GlobalniKontaktyActionType = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    CLEAR_FILTER: `${PREFIX}CLEAR_FILTER`,
};

export const SkupinoveKontaktyActionType = {
    SET_FILTER: `${PREFIX_SKUPINOVE}SET_FILTER`,
    CLEAR_FILTER: `${PREFIX_SKUPINOVE}CLEAR_FILTER`,
};

export const PracovniciDistribuceActionType = {
    SET_FILTER: `${PREFIX_PRACOVNICI_DISTRIBUCE}SET_FILTER`,
    CLEAR_FILTER: `${PREFIX_PRACOVNICI_DISTRIBUCE}CLEAR_FILTER`,
};

export const SpravakontaktuDistributoraActionType = {
    SET_TAB: `${PREFIX_SPRAVA_DISTRIBUTORA}SET_TAB`,
};

type GlobalniKontaktyActionType = {
    clearFilter: () => {
        type: string;
    };
    setFilter: (filter: SearchKontaktuDistributoraFilterModel) => {
        payload: SearchKontaktuDistributoraFilterModel;
        type: string;
    };
};

type SkupinoveKontaktyActionType = {
    clearFilter: () => {
        type: string;
    };
    setFilter: (filter: KontaktDistributoraSkupinaFilterModel) => {
        payload: KontaktDistributoraSkupinaFilterModel;
        type: string;
    };
};

type PracovniciDistribuceActionType = {
    clearFilter: () => {
        type: string;
    };
    setFilter: (filter: PracovniciDistribuceFilterModel) => {
        payload: PracovniciDistribuceFilterModel;
        type: string;
    };
};

type SpravaKontaktuDistributoraActionType = {
    setTab: (tabValue: number) => {
        payload: number;
    };
};

/**
 * Akce pro reducer globálních kontaktů distributora
 */
export const useGlobalniKontaktyDispatch = (): GlobalniKontaktyActionType => {
    const dispatch: Dispatch<Action<SearchKontaktuDistributoraFilterModel> | AnyAction> = useDispatch();
    return {
        clearFilter: () => dispatch({type: GlobalniKontaktyActionType.CLEAR_FILTER}),
        setFilter: (filter: SearchKontaktuDistributoraFilterModel) => dispatch({type: GlobalniKontaktyActionType.SET_FILTER, payload: filter}),
    };
};

/**
 * Akce pro reducer skupinových kontaktů distributora
 */
export const useSkupinoveKontaktyDispatch = (): SkupinoveKontaktyActionType => {
    const dispatch: Dispatch<Action<KontaktDistributoraSkupinaFilterModel> | AnyAction> = useDispatch();
    return {
        clearFilter: () => dispatch({type: SkupinoveKontaktyActionType.CLEAR_FILTER}),
        setFilter: (filter: KontaktDistributoraSkupinaFilterModel) => dispatch({type: SkupinoveKontaktyActionType.SET_FILTER, payload: filter}),
    };
};

/**
 * Akce pro reducer pracovníků distributora
 */
export const usePracovniciDistribuceDispatch = (): PracovniciDistribuceActionType => {
    const dispatch: Dispatch<Action<PracovniciDistribuceFilterModel> | AnyAction> = useDispatch();
    return {
        clearFilter: () => dispatch({type: PracovniciDistribuceActionType.CLEAR_FILTER}),
        setFilter: (filter: PracovniciDistribuceFilterModel) => dispatch({type: PracovniciDistribuceActionType.SET_FILTER, payload: filter}),
    };
};

/**
 * Akce pro reducer správy kontaktů distributora
 */
export const useSpravaKontaktuDistributoraDispatch = (): SpravaKontaktuDistributoraActionType => {
    const dispatch: Dispatch<Action<number> | AnyAction> = useDispatch();
    return {
        setTab: (tabValue: number) => dispatch({type: SpravakontaktuDistributoraActionType.SET_TAB, payload: tabValue}),
    };
};
