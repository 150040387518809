import {Action, handleActions, ReduxCompatibleReducer} from 'redux-actions';
import {RegistrAdresImportFilterModel} from '../model';
import {RegistrAdresImportStore} from '../store';
import {RegistrAdresImportActionType} from '../actions';

/**
 * Výchozí stav reduceru
 */
export const initialData: RegistrAdresImportStore = {
    filter: {
        datumOd: null,
        datumDo: null,
        chybne: false,
    },
};

/**
 * Globální reducer pro registr adres import
 */
export const RegistrAdresImportReducer: ReduxCompatibleReducer<RegistrAdresImportStore, any> = handleActions<RegistrAdresImportStore, any>(
    {
        [RegistrAdresImportActionType.SET_FILTER]: (
            store: RegistrAdresImportStore,
            {payload}: Action<RegistrAdresImportFilterModel>,
        ): RegistrAdresImportStore => ({
            ...store,
            filter: payload,
        }),
        [RegistrAdresImportActionType.CLEAR_FILTER]: (store: RegistrAdresImportStore): RegistrAdresImportStore => ({
            ...store,
            filter: initialData.filter,
        }),
    },
    initialData,
);
