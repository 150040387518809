import {AnyAction, Dispatch} from 'redux';
import {useDispatch} from 'react-redux';
import {Action} from 'redux-actions';
import {RegistrAdresImportFilterModel} from '../model';

const PREFIX = 'REGISTR_ADRES_IMPORT_';

export const RegistrAdresImportActionType = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
    CLEAR_FILTER: `${PREFIX}CLEAR_FILTER`,
};

type RegistrAdresImportActionType = {
    clearFilter: () => {
        type: string;
    };
    setFilter: (filter: RegistrAdresImportFilterModel) => {
        payload: RegistrAdresImportFilterModel;
        type: string;
    };
};

/**
 * Akce pro reducer globálních kontaktů distributora
 */
export const useRegistrAdresImportDispatch = (): RegistrAdresImportActionType => {
    const dispatch: Dispatch<Action<RegistrAdresImportFilterModel> | AnyAction> = useDispatch();
    return {
        clearFilter: () => dispatch({type: RegistrAdresImportActionType.CLEAR_FILTER}),
        setFilter: (filter: RegistrAdresImportFilterModel) => dispatch({type: RegistrAdresImportActionType.SET_FILTER, payload: filter}),
    };
};
