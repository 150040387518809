import {HelpFilterModel} from '../model/HelpFilterModel';
import {Action, handleActions} from 'redux-actions';
import {ConfigurationStore} from '../store';

export const PREFIX = 'CONFIGURATION_';

export const HelpActionNames = {
    SET_FILTER: `${PREFIX}SET_FILTER`,
};

const initialData = {
    helpFilter: {
        fulltext: undefined,
    },
} as ConfigurationStore;

export const ConfigurationReducer = handleActions<ConfigurationStore, any>(
    {
        [HelpActionNames.SET_FILTER]: (store: ConfigurationStore, {payload}: Action<HelpFilterModel>) => ({...store, helpFilter: payload}),
    },
    initialData,
);
