import sanitizeHtml from 'sanitize-html';
/**
 * Pokud je hodnota typu string, odstraní mezery před a za hodnotou, jinak vrátí původní hodnotu
 * @param {any} value
 */
export const trimSpaces = (value: any) => (typeof value === 'string' ? (value as string).trim() : value);

/**
 * When string value is empty, return undefined
 *
 * @param value Input value
 */
export const emptyToUndefined = (value: string | undefined) => (value === '' ? undefined : value);

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string) => sanitizeHtml(html);
